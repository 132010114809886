@import "../style.css";

/*---------------------------Search filtering and loading--------------------------*/
#timeline span {
  font-size: 11pt;
}

#contentTimeLine {
  margin-top: 7%;
  width: 40%;
}

/*-------------------------------Size bar and image grid-----------------------------*/
#parent {
  width: 100%;
  height: 100%;
  display: table;
}

/*-------------------------------Categories-----------------------------*/
.initiatives {
  text-align: center;
  margin-top: 50px;
}

.initiatives * {
  display: block;
}

#contentLoading {
  color: var(--yellow);
  margin-top: 10%;
  margin-bottom: 10%;
}

#contentLoadingContainer {
  display: flex;
  justify-content: center;
}

/*-------------------------------Image grid gallery-----------------------------*/
ol {
  padding: 0px;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
  justify-content: center;
  width: 100%;
  padding: 30px 10px 0px 10px;
}

.grid li {
  margin: 10px;
}

a.darken {
  width: 21.9vw;
  height: 21.9vw;
  display: inline-block;
  position: relative;
  text-align: center;
  transition: opacity 0.5s ease;
}

a.darken:hover:after {
  opacity: 1;
}

a.darken:after {
  content: "\A";
  width: 100%;
  height: 100%;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  z-index: 1;
}

a.darken img {
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

a.darken div {
  font-family: "Montserrat";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgba(255, 255, 255, 0);
  z-index: 2;
  transition: opacity 0.5s ease;
}

a.darken:hover div {
  color: rgba(255, 255, 255, 1);
}

@media only screen and (max-width: 520px) {
  .side {
    font-size: 16pt;
  }

  a.darken {
    width: 24.8vw;
    height: 24.8vw;
  }

  a.darken img {
    width: 25vw;
  }
}

#emptyMessage {
  padding-top: 40px;
}

@media only screen and (max-width: 450px) {
  .initiatives label {
    width: 80%;
  }
}
@media only screen and (max-width: 300px) {
  a.darken {
    width: 36.9vw;
    height: 36.9vw;
  }

  a.darken img {
    width: 37vw;
  }

  .initiatives label {
    width: 100%;
  }
}
