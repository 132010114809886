@import "../style.css";

.componentStyles {
  text-align: center;
}

.link {
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
  letter-spacing: 0.1vw;
  font-size: 9pt;
  text-decoration: none;
  display: block;
  text-align: centre;
  padding: 6px;
  border-bottom: 1px #ffffff solid;
}

.link:last-child {
  border-bottom: none;
}

.button {
  background-color: #ffffff;
  border: 2px solid var(--yellow);
  color: var(--yellow);
  padding: 6px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.05em;
  transition: 0.3s;
}

.button:hover {
  background: #fff7eb;
}

.dropItems {
  padding: 6px;
  border-top: none;
  width: 143.5px;
  background-color: var(--yellow);
  margin: 0 auto;
  z-index: 2;
  position: absolute;
}

/*--- Dark mode ---*/
@media (prefers-color-scheme:dark) {
  .button {
    background-color: var(--dark-slate-grey);
  }
  .button:hover {
    background-color:#61605b;
  }
}