@import "../style.css";

#teams {
  width: 60%;
}

#events {
  width: 85%;
}

#marketingArchive {
  width: 60%;
}

#teams,
#events,
#marketingArchive {
  margin: 0 auto;
  margin-bottom: 2%;
}

@media screen and (max-width: 650px) {
  #events {
    width: 85%;
  }
}
