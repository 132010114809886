/*------------------------------Upcoming Events--------------------------------*/
.eventsBody {
  max-width: 80%;
  margin: 0 auto;
  margin-bottom: 10%;
}

.eventsBody h2 {
  margin-top: 100px;
}

.upcomingEventsContainer {
  display: -ms-grid;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 20px;
}

.previewContainer {
  position: relative;  
}

.darkOverlay {
  width: 100%;
  background-color: black;
  opacity: 0;
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 1;
  transition: opacity 0.5s ease;
}

.previewContainer .darkOverlay:hover {
  opacity: 0.04;
}

.previewContainer .upcomingEventCover {
  transition: transform 0.5s ease;
}

.previewContainer:hover .upcomingEventCover {
  -ms-transform: scale(1.05); /* IE 9 */
  -webkit-transform: scale(1.05); /* Safari 3-8 */
  transform: scale(1.05);
}

.previewContainer:hover {
  background-color: none;
}

a:hover {
  color: #313638;
}

.regButton {
  padding: 8px 10px;
  border: none;
  background-color: #e85f5c;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11pt;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #ffffff;
  transition: 0.3s;
}

.regButton:hover {
  background-color: #bd3f3d;
}

.lookout {
  margin-top: 30px;
  text-align: center;
}

/*------------------------------Past Events--------------------------------*/
.pastEventsContainer {
  margin: 0% 2.5%;
}

.pastEventsContainer h3 {
  margin-top: 30px;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 20px;
}

.pastEvent {
  position: relative;
  width: 100%;
  height: 100%;
}

.darkOverlay {
  width: 100%;
  height: 100%;
  background-color: black;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.pastEvent:hover .darkOverlay {
  opacity: 0.15;
}

.eventImages {
  max-width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

/*--- Dark mode ---*/
@media (prefers-color-scheme:dark) {
  h3 {
    color: white;
  }
  .darkOverlay {
    background-color: #ffffff;
    color: #ffffff;
  }

  .lookout {
    color: #ffffff;
  }
}

/*---------------------------Loading Sign--------------------------*/
#eventsLoading {
  color: var(--yellow);
  margin-top: 5%;
  margin-bottom: 3%;
}

.eventsLoadingContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

/*------------------------------Responsive Mobile Design--------------------------------*/
@media only screen and (max-width: 1200px) {
  .upcomingEventsContainer {
    grid-template-columns: 50vw 50vw;
    justify-content: center;
  }
}

@media only screen and (max-width: 750px) {
  .upcomingEventsContainer {
    grid-template-columns: 100vw;
  }

  .regButton {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .eventsBody {
    max-width: 90%;
  }
}

@media only screen and (max-width: 350px) {
  .eventsBody {
    max-width: 100%;
  }
}
