@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&family=Playfair+Display&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&family=Playfair+Display&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* 
  Web fonts used:
  font-family: "Playfair Display", serif;
  font-family: "Montserrat", sans-serif;
*/

/*Web colours*/
:root {
  --white: #fff;
  --yellow: #feb14b; /* Headings and Highlighted text */
  --light-yellow: #fff7ef; /* Navigation */
  --sand:  #fff0e1c1; /* Selected navigation */
  --black: #313638; /* General text */
  --grey: #676767; /* Subheadings */
  --light-grey: #c4c4c4; /* disabled arrows */
  --section: #F4F4F4;
  --red: #e85f5c; /* Buttons and unvisited links */
  --dark-red: #bd3f3d; /* visited links */
  --dark-grey: #202124; /* dark mode card */
  --dark-slate-grey: #3b3b41; /* dark mode lighter grey card */
}

/*----------------------------------Dark mode----------------------------------*/
body {
  color: #222;
}

/*Changes background*/
@media (prefers-color-scheme: dark) {
  body {
    background:#202124;
    background:var(--dark-grey);
  }
}

/*----------------------------------General------------------------------------*/
h1 {
  color: #feb14b;
  color: var(--yellow);
  font-family: "Playfair Display", serif;
  font-size: 48pt;
}

h2 {
  color:#feb14b;
  color:var(--yellow);
  font-family: "Montserrat", sans-serif;
  font-size: 30pt;
  text-transform: uppercase;
  padding-bottom: 20px;
  text-align: center;
}

.header {
  padding-top: 50px;
  padding-bottom: 20px;
  text-align: center;
}

.subheader {
  font-family: "Montserrat", sans-serif;
  font-size: 13pt;
  color: #676767;
  color: var(--grey);
}

p {
  font-family: "Montserrat", sans-serif;
  font-size: 10pt;
  margin-top: 0;
}

.body_bold {
  font-family: "Montserrat", sans-serif;
  font-size: 10pt;
  font-weight: bold;
}

.centre {
  text-align: center;
}

.button {
  font-family: "Montserrat", sans-serif;
  background-color: #e85f5c;
  border-radius: 60px;
  border-style: none;
  color: white;
  padding: 10px 45px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  transition: 0.3s;
}

.button:hover {
  background-color: #bd3f3d;
  background-color: var(--dark-red);
  color: #ffffff;
}

/*----------------------------------Header------------------------------------*/
/*Heading title*/
.headerTitle h1 {
  font-family: "Playfair Display", serif;
  font-size: 5vw;
  letter-spacing: 8px;
  text-transform: uppercase;
  text-align: center;
}

.coverPhoto {
  height: 100vh;
  overflow: hidden;
}

.cover_image {
  height: 100%;
  overflow: hidden;
  width: 100%;
  object-fit: cover;
  position: absolute;
}

/*website overlay*/
.coverPhoto .dark {
  width: 100%;
  background-color: #313638;
  opacity: 0.6;
  position: absolute;
  top: 0;
  height: 100vh;
  z-index: 1;
}

.coverPhoto .headerTitle {
  position: absolute;
  top: 0;
  text-align: center;
  width: 100%;
  z-index: 2;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.coverPhoto .headerTitle h1 {
  margin: 0px;
  font-weight: 600;
}

@media only screen and (max-width: 700px) {
  .coverPhoto,
  .coverPhoto .dark,
  .coverPhoto .headerTitle,
  .cover_image {
    height: 40vh;
  }

  .coverPhoto .headerTitle h1 {
    font-size: 3.5vh;
  }

  .coverPhoto .headerTitle {
    top: 25px;
  }
}

@media only screen and (max-width: 400px) {
  .coverPhoto .headerTitle h1 {
    font-size: 3vh;
  }
}

@media only screen and (max-width: 340px) {
  .coverPhoto .headerTitle h1 {
    font-size: 2.5vh;
  }
}

@media only screen and (max-width: 318px) {
  .coverPhoto {
    position: relative;
  }
}

/*-----------------------------------Navbar-----------------------------------*/
.bg-custom {
  background: transparent;
  font-size: 10pt;
  padding: 0px;
  z-index: 99;
}

.nav {
  height: 70px;
  z-index: 99;
}

.navbar-nav {
  color: #313638;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  text-decoration: none;
  z-index: 99;
}

.nav-item {
  float: right;
  color: #313638;
  padding: 15px 5px;
  text-decoration: none;
  letter-spacing: 1.5px;
  z-index: 99;
  position: relative;
}

.nav-item-btn {
  color: #313638;
  padding-top: 22px;
  padding-right: 10px;
  display: none;
  z-index: 99;
  position: relative;
}

.nav-item a,
.dropdown-title {
  color: white;
  padding: 15px;
  text-decoration: none;
  z-index: 99;
}

.dropdown-menu {
  border-color: #fff7ef;
  letter-spacing: 1.5px;
  font-size: 14px;
  background-color: #fff7ef;
  margin-top: 15px;
  border-radius: 0px 0px 10px 10px;
  z-index: 99;
}

.transparent {
  margin-top: 25px;
  border-radius: 10px;
  z-index: 99;
}

.transparent::after {
  content: " ";
  border-style: solid;
  border-width: 0 1.8em 2em 1.8em;
  border-color: transparent;
  position: absolute;
  right: 48px;
  top: -1em;
  border-bottom-color: #fff7ef;
  z-index: 99;
}

.mediaDD::after {
  content: " ";
  border-style: solid;
  border-width: 0 1.8em 2em 1.8em;
  border-color: transparent;
  position: absolute;
  right: 81px;
  top: -1em;
  border-bottom-color: #fff7ef;
}

.dropdown-item {
  padding: 7px 10px 5px 10px;
}

.dropdown-media {
  padding: 7px 5px;
}

.dropdown-item a {
  color: #313638;
  transition: 0.2s;
}

.dropdown-item:hover {
  background-color: #fff7ef;
}

.affiliations-remove-gap {
  margin-left: 35px;
  padding-bottom: 0;
  margin-bottom: 0;
  max-height: 0;
}

.menuTitle {
  color: var(--general-text);
  padding: 10px 15px;
}

.menuTitle:hover {
  color: #e85f5c;
  color: var(--red);
  cursor: pointer;
}

.nav-item a:hover {
  color:  #e85f5c;
  color:  var(--red);
  padding: 15px;
  text-decoration: none;
}

.nav-item::after {
  content: '';
  position: absolute;
  display: block;
  height: 2px;
  width: 0%;
  bottom: 0;
  left: 5%;
  opacity: 0;
  background-color: #e85f5c;
  background-color: var(--red);
  transition: opacity 300ms, width 0.35s ease 0s;
}
  
.nav-item:hover:not(.highlight-nav):after  {
  width: 90%;
  opacity: 1;
}

.nav-item:hover:not(.highlight-nav-no-drop):after  {
  width: 90%;
  opacity: 1;
}

.nav-item:hover:not(.highlight-nav-white):after  {
  width: 90%;
  opacity: 1;
}

/*Making the navigation change colour on scroll*/
.activeNav {
  background-color: #fff7ef;
  background-color: var(--light-yellow);
  transition: background-color 0.2s ease-in;
}

.dropdown-toggle:after {
  color: #fff;
  color: var(--white);
}

.active-nav-item a,
.active-nav-item span,
.active-nav-item:after {
  color: #313638;
  color: var(--black);
}

.logo-black {
  display: none;
}

/* logo on hamburger menu */
.logo {
  width: 35px;
  margin: 5px 0px 5px 15px;
}

.icon {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  margin-right: 10px;
  margin-left: -10px;
}

.icon-media {
  margin-left: -5px;
}

.highlight-nav, 
.highlight-nav-white,
.highlight-nav-no-drop,
.highlight-dropdown,
.highlight-no-nav {
  font-weight: 500;
}

/* clear background - dropdown */
.highlight-no-nav::after {
  content: '';
  position: absolute;
  display: block;
  height: 2px;
  width: 100%;
  bottom: 0;
  left: 0%;
  background-color: #fff;
  background-color: var(--white);
  top: 32px;
  z-index: 100;
}

/* yellow background - dropdown */
.highlight-nav::after {
  content: '';
  position: absolute;
  display: block;
  height: 2px;
  width: 100%;
  bottom: 0;
  left: 0%;
  background-color: #e85f5c;
  background-color: var(--red);
  top: 32px;
  z-index: 100;
}

/* yellow background - no dropdown*/
.highlight-nav-no-drop::after {
  content: '';
  position: absolute;
  display: block;
  height: 2px;
  width: 90%;
  bottom: 0;
  left: 5%;
  background-color: #e85f5c;
  background-color: var(--red);
  z-index: 100;
}

/* clear background - no dropdown */
.highlight-nav-white::after {
  content: '';
  position: absolute;
  display: block;
  height: 2px;
  width: 90%;
  bottom: 0;
  left: 5%;
  background-color: #fff;
  background-color: var(--white);
  z-index: 99;
}

.hiddenNav {
  display: none;
}

/*Dark mode version of nav bar*/
@media (prefers-color-scheme: dark) {
  .activeNav {
    background-color: #000000;
  }

  .active-nav-item a,
  .active-nav-item span,
  .active-nav-item:after {
    color: #ffffff;
  }

  .dropdown-item:hover {
    background-color: #000000;
  }

  .dropdown-menu {
    border-color: #000000;
    background-color: #000000;
  }

  .dropdown-item a {
    color: #fff;
  }

  .transparent::after {
    border-bottom-color: #000000;
  }

  .mediaDD::after {
    border-bottom-color: #000000;
  }
}

/* Mobile responsiveness of nav bar */
@media screen and (max-width: 950px) {
  .nav-item {
    display: none;
  }

  .bg-custom {
    background-color: #fff7ef;
    background-color: var(--light-yellow);
  }

  .logo-black {
    display: block;
  }
    
  .nav-item-btn {
    color: white;
    display: block;
    z-index: 99;
  }
}

/* Mobile responsiveness of nav bar and dark mode */
@media screen and (max-width: 950px) and (prefers-color-scheme: dark) {
  .activeNav {
    background-color: #000;
  }

  .active-nav-item a,
  .active-nav-item span,
  .active-nav-item:after {
    color: #ffffff;
  }

  .bg-custom {
    background-color: #000;
  }

  .dropdown-item:hover {
    background-color: #000;
  }

  .dropdown-menu {
    border-color: #000000;
    background-color: #000000;
  }

  .dropdown-item a {
    color: #fff;
  }

  .transparent::after {
    border-bottom-color: #000000;
  }

  .mediaDD::after {
    border-bottom-color: #000000;
  }

  .nav-item-btn {
    color: white;
    display: block;
    z-index: 99;
  }

  .photo {
    display: block;
  }
}

/*----------------------------------Loading Screen------------------------------------*/
#loadingBackground {
  background: #fff7ef;
  background: var(--light-yellow);
  height: 100vh;
  z-index: 100;
  position: absolute;
  width: 100vw;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#loadingWillow {
  height: 300px;
  width: 300px;
}

#loadingBackground p {
  overflow: hidden;
  font-size: 18pt;
  -webkit-animation: typing 2.5s steps(34, end);
          animation: typing 2.5s steps(34, end);
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
}

@-webkit-keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 10em;
  }
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 10em;
  }
}

@media (prefers-color-scheme: dark) {
  #loadingBackground {
    background: #323235;
  }

  #loadingBackground p {
    color: #fff; 
  }
}

@media screen and (max-width: 320px) {
  #loadingBackground {
    height: 100%;
    position: fixed;
    width: 100%;
  }
}

@media screen and (max-width: 300px) {
  #loadingWillow {
    height: 280px;
    width: 280px;
  }
}

/*-----------------------------------Menu-----------------------------------*/

.menu-container {
  position: fixed;
  width: 100%;
  background: #fff7ef;
  background: var(--light-yellow);
  opacity: 1;
  color: #313638;
  z-index: 3;
  box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.2);
}

.menu-link {
  text-decoration: none;
}

.menu-item {
  color: #313638;
  padding: 5px 35px;
  text-decoration: none;
  letter-spacing: 1.5px;
  text-transform: capitalize;
  cursor: pointer;
  font-size: 16px;
}

.menu-box:hover {
  width: 92%;
  background-color: #fff0e1c1;
  background-color: var(--sand);
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
}

.menu-box:hover .menu-item {
  color: #e85f5c;
  color: var(--red);
}

.menu-bar {
  float: none;
}

.menu-list {
  padding-top: 4rem;
}

.menu-dropdown {
  padding: 5px 0px;
}

/* Dark mode and responsive menu dropdown*/
@media screen and (max-width: 950px) and (prefers-color-scheme: dark) {
  .menu-container {
    background: #000000;
    color: #fff;
  }

  .menu-item {
    color: #fff;
  }
  
  .menu-box:hover {
    background-color: #202124;
    background-color: var(--dark-grey);
  }
  
  .menu-box:hover .menu-item {
    color: #e85f5c;
    color: var(--red);
  }
}

/*----------------------------------Footer------------------------------------*/
/* WIT logo */
.photo {
  width: 40px;
}

footer {
  margin: 0px;
  padding: 0px;
  background-color: #fff7ef;
  background-color: var(--light-yellow);
  min-width: 100%;
}

.footer-all {
  list-style-type: none;
  margin: 0 auto 1.4vw auto;
  padding: 4vw 0vw 2vw 0vw;
}

.footer-item {
  font-family: "Montserrat", sans-serif;
  padding: 0.5vw;
  color: #313638;
  color: var(--black);
  font-size: 1.05vw;
}

.footer-header {
  font-weight: 750;
  padding-bottom: 1vw;
}

.bottom-footer-item {
  font-family: "Montserrat", sans-serif;
  padding: 0.3vw;
  color: #313638;
  color: var(--black);
  text-transform: uppercase;
}

.footer-logo {
  margin-top: 1%;
  padding-left: 5%;
}

.socials .footer-icons {
  padding: 0px 0.5vw;
}

.square-link {
  width: 80%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 1.75rem;
  padding: 0px;
}

.footer-icons-img {
  width: 100%;
  height: 100%;
}

/*Social Tooltip Styles*/
.tooltipTitle {
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
  margin: 10px 0;
}

.col-width {
  flex-basis: 40%;
}

.socials .footer-item {
  text-align: center;
  display: flex;
  justify-content: center;
}

.footer-copyright {
  font-family: "Montserrat", sans-serif;
  text-transform: none;
  padding-bottom: 1vw;
  font-size: 1.05vw;
}

.footer-copyright,
a {
  color: #313638;
  transition: 0.2s;
}

.footer-item a {
  transition: 0.2s;
}

.footer-item a:hover {
  color: #e85f5c;
  color: var(--red);
  text-decoration: none;
}

.bottom-footer-item a:hover {
  color: #e85f5c;
  color: var(--red);
  text-decoration: none;
}

.grid-footer-container {
  display: flex;
  width: 90%;
  margin: 0px auto;
}

/*---Dark mode version of footer---*/
.footer-logo-black {
  display:block;
  width: 50px;
}

.footer-logo-white {
  display:none;
}

@media (prefers-color-scheme: dark) {
  .footer-logo-black {
    display: none;
  }

  .footer-logo-white {
    display: block;
    width: 50px;
  }

  footer {
    color: #fff;
    color: var(--white);
    background: black;
  }

  .bottom-footer-item {
    color: #fff;
    color: var(--white);
  }

  .footer-item {
    color: #fff;
    color: var(--white);
  }

  .footer-copyright,
  a {
    color: #fff;
    color: var(--white);
  }
}

@media screen and (max-width: 900px) {
  .square-link {
    width: 100%;
    grid-gap: 1.25rem;
  }
}

@media screen and (max-width: 600px) {
  .grid-footer-container {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .footer-item {
    text-align: center;
    font-size: 16px;
  }

  .footer-copyright {
    font-size: 16px;
    padding-bottom: 5vw;
  }

  .footer-white-logo {
    margin-bottom: 2%; 
  }
  
  .footer-black-logo {
    margin-bottom: 2%;
  }

  .footer-icons {
    display: center;
    margin-left: 2.5vw;
    margin-right: 2.5vw;
  }

  .socials {
    margin-left: 0%;
    margin-top: 0%;
  }

  .footer-all {
    margin: 0vw auto 1vw auto;
  }

  .footer-item {
    padding-bottom: 2vw;
  }
  
  .bottom-footer-item {
    padding-bottom: 5vw;
  }

  .footer-header {
    margin-top: 7.5%;
  }

  .square-link {
    display: inline-block;
  }

  .footer-icons-img {
    height: 25px;
    width: 25px;
  }
}

@media screen and (max-width: 500px) {
  .footer-icons {
    margin-left: 1.5vw;
    margin-right: 1.5vw;
  }  
}

@media screen and (max-width: 355px) {
  .footer-icons {
    margin-left: 1vw;
    margin-right: 1vw;
  }  
}
/* 
  Web fonts used:
  font-family: "Playfair Display", serif;
  font-family: "Montserrat", sans-serif;
*/

/*Web colours*/
:root {
  --white: #fff;
  --yellow: #feb14b; /* Headings and Highlighted text */
  --light-yellow: #fff7ef; /* Navigation */
  --sand:  #fff0e1c1; /* Selected navigation */
  --black: #313638; /* General text */
  --grey: #676767; /* Subheadings */
  --light-grey: #c4c4c4; /* disabled arrows */
  --section: #F4F4F4;
  --red: #e85f5c; /* Buttons and unvisited links */
  --dark-red: #bd3f3d; /* visited links */
  --dark-grey: #202124; /* dark mode card */
  --dark-slate-grey: #3b3b41; /* dark mode lighter grey card */
}

/*----------------------------------Dark mode----------------------------------*/
body {
  color: #222;
}

/*Changes background*/
@media (prefers-color-scheme: dark) {
  body {
    background:#202124;
    background:var(--dark-grey);
  }
}

/*----------------------------------General------------------------------------*/
h1 {
  color: #feb14b;
  color: var(--yellow);
  font-family: "Playfair Display", serif;
  font-size: 48pt;
}

h2 {
  color:#feb14b;
  color:var(--yellow);
  font-family: "Montserrat", sans-serif;
  font-size: 30pt;
  text-transform: uppercase;
  padding-bottom: 20px;
  text-align: center;
}

.style_header__lnkau {
  padding-top: 50px;
  padding-bottom: 20px;
  text-align: center;
}

.style_subheader__3UD-H {
  font-family: "Montserrat", sans-serif;
  font-size: 13pt;
  color: #676767;
  color: var(--grey);
}

p {
  font-family: "Montserrat", sans-serif;
  font-size: 10pt;
  margin-top: 0;
}

.style_body_bold__VIolN {
  font-family: "Montserrat", sans-serif;
  font-size: 10pt;
  font-weight: bold;
}

.style_centre__3mqtw {
  text-align: center;
}

.style_button__lKqC2 {
  font-family: "Montserrat", sans-serif;
  background-color: #e85f5c;
  border-radius: 60px;
  border-style: none;
  color: white;
  padding: 10px 45px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  transition: 0.3s;
}

.style_button__lKqC2:hover {
  background-color: #bd3f3d;
  background-color: var(--dark-red);
  color: #ffffff;
}

/*----------------------------------Header------------------------------------*/
/*Heading title*/
.style_headerTitle__hANa2 h1 {
  font-family: "Playfair Display", serif;
  font-size: 5vw;
  letter-spacing: 8px;
  text-transform: uppercase;
  text-align: center;
}

.style_coverPhoto__1YEcv {
  height: 100vh;
  overflow: hidden;
}

.style_cover_image__11dnO {
  height: 100%;
  overflow: hidden;
  width: 100%;
  object-fit: cover;
  position: absolute;
}

/*website overlay*/
.style_coverPhoto__1YEcv .style_dark__1cJll {
  width: 100%;
  background-color: #313638;
  opacity: 0.6;
  position: absolute;
  top: 0;
  height: 100vh;
  z-index: 1;
}

.style_coverPhoto__1YEcv .style_headerTitle__hANa2 {
  position: absolute;
  top: 0;
  text-align: center;
  width: 100%;
  z-index: 2;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.style_coverPhoto__1YEcv .style_headerTitle__hANa2 h1 {
  margin: 0px;
  font-weight: 600;
}

@media only screen and (max-width: 700px) {
  .style_coverPhoto__1YEcv,
  .style_coverPhoto__1YEcv .style_dark__1cJll,
  .style_coverPhoto__1YEcv .style_headerTitle__hANa2,
  .style_cover_image__11dnO {
    height: 40vh;
  }

  .style_coverPhoto__1YEcv .style_headerTitle__hANa2 h1 {
    font-size: 3.5vh;
  }

  .style_coverPhoto__1YEcv .style_headerTitle__hANa2 {
    top: 25px;
  }
}

@media only screen and (max-width: 400px) {
  .style_coverPhoto__1YEcv .style_headerTitle__hANa2 h1 {
    font-size: 3vh;
  }
}

@media only screen and (max-width: 340px) {
  .style_coverPhoto__1YEcv .style_headerTitle__hANa2 h1 {
    font-size: 2.5vh;
  }
}

@media only screen and (max-width: 318px) {
  .style_coverPhoto__1YEcv {
    position: relative;
  }
}

/*-----------------------------------Navbar-----------------------------------*/
.style_bg-custom__2L_As {
  background: transparent;
  font-size: 10pt;
  padding: 0px;
  z-index: 99;
}

.style_nav__38Dk_ {
  height: 70px;
  z-index: 99;
}

.style_navbar-nav__1szhk {
  color: #313638;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  text-decoration: none;
  z-index: 99;
}

.style_nav-item__3zwXW {
  float: right;
  color: #313638;
  padding: 15px 5px;
  text-decoration: none;
  letter-spacing: 1.5px;
  z-index: 99;
  position: relative;
}

.style_nav-item-btn__3n1w_ {
  color: #313638;
  padding-top: 22px;
  padding-right: 10px;
  display: none;
  z-index: 99;
  position: relative;
}

.style_nav-item__3zwXW a,
.style_dropdown-title__jyGSe {
  color: white;
  padding: 15px;
  text-decoration: none;
  z-index: 99;
}

.style_dropdown-menu__1lwov {
  border-color: #fff7ef;
  letter-spacing: 1.5px;
  font-size: 14px;
  background-color: #fff7ef;
  margin-top: 15px;
  border-radius: 0px 0px 10px 10px;
  z-index: 99;
}

.style_transparent__XoLye {
  margin-top: 25px;
  border-radius: 10px;
  z-index: 99;
}

.style_transparent__XoLye::after {
  content: " ";
  border-style: solid;
  border-width: 0 1.8em 2em 1.8em;
  border-color: transparent;
  position: absolute;
  right: 48px;
  top: -1em;
  border-bottom-color: #fff7ef;
  z-index: 99;
}

.style_mediaDD__ss-Bl::after {
  content: " ";
  border-style: solid;
  border-width: 0 1.8em 2em 1.8em;
  border-color: transparent;
  position: absolute;
  right: 81px;
  top: -1em;
  border-bottom-color: #fff7ef;
}

.style_dropdown-item__2kaNe {
  padding: 7px 10px 5px 10px;
}

.style_dropdown-media__R9yu9 {
  padding: 7px 5px;
}

.style_dropdown-item__2kaNe a {
  color: #313638;
  transition: 0.2s;
}

.style_dropdown-item__2kaNe:hover {
  background-color: #fff7ef;
}

.style_affiliations-remove-gap__jkXDe {
  margin-left: 35px;
  padding-bottom: 0;
  margin-bottom: 0;
  max-height: 0;
}

.style_menuTitle__2R1ZD {
  color: var(--general-text);
  padding: 10px 15px;
}

.style_menuTitle__2R1ZD:hover {
  color: #e85f5c;
  color: var(--red);
  cursor: pointer;
}

.style_nav-item__3zwXW a:hover {
  color:  #e85f5c;
  color:  var(--red);
  padding: 15px;
  text-decoration: none;
}

.style_nav-item__3zwXW::after {
  content: '';
  position: absolute;
  display: block;
  height: 2px;
  width: 0%;
  bottom: 0;
  left: 5%;
  opacity: 0;
  background-color: #e85f5c;
  background-color: var(--red);
  transition: opacity 300ms, width 0.35s ease 0s;
}
  
.style_nav-item__3zwXW:hover:not(.style_highlight-nav__3Iqfe):after  {
  width: 90%;
  opacity: 1;
}

.style_nav-item__3zwXW:hover:not(.style_highlight-nav-no-drop__1UgKu):after  {
  width: 90%;
  opacity: 1;
}

.style_nav-item__3zwXW:hover:not(.style_highlight-nav-white__2YFHs):after  {
  width: 90%;
  opacity: 1;
}

/*Making the navigation change colour on scroll*/
.style_activeNav__3Nscp {
  background-color: #fff7ef;
  background-color: var(--light-yellow);
  transition: background-color 0.2s ease-in;
}

.style_dropdown-toggle__11Pl0:after {
  color: #fff;
  color: var(--white);
}

.style_active-nav-item__1Wk56 a,
.style_active-nav-item__1Wk56 span,
.style_active-nav-item__1Wk56:after {
  color: #313638;
  color: var(--black);
}

.style_logo-black__1incE {
  display: none;
}

/* logo on hamburger menu */
.style_logo__KRHK1 {
  width: 35px;
  margin: 5px 0px 5px 15px;
}

.style_icon__1sObw {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  margin-right: 10px;
  margin-left: -10px;
}

.style_icon-media__1WT1p {
  margin-left: -5px;
}

.style_highlight-nav__3Iqfe, 
.style_highlight-nav-white__2YFHs,
.style_highlight-nav-no-drop__1UgKu,
.style_highlight-dropdown__UyTIl,
.style_highlight-no-nav__1VkoC {
  font-weight: 500;
}

/* clear background - dropdown */
.style_highlight-no-nav__1VkoC::after {
  content: '';
  position: absolute;
  display: block;
  height: 2px;
  width: 100%;
  bottom: 0;
  left: 0%;
  background-color: #fff;
  background-color: var(--white);
  top: 32px;
  z-index: 100;
}

/* yellow background - dropdown */
.style_highlight-nav__3Iqfe::after {
  content: '';
  position: absolute;
  display: block;
  height: 2px;
  width: 100%;
  bottom: 0;
  left: 0%;
  background-color: #e85f5c;
  background-color: var(--red);
  top: 32px;
  z-index: 100;
}

/* yellow background - no dropdown*/
.style_highlight-nav-no-drop__1UgKu::after {
  content: '';
  position: absolute;
  display: block;
  height: 2px;
  width: 90%;
  bottom: 0;
  left: 5%;
  background-color: #e85f5c;
  background-color: var(--red);
  z-index: 100;
}

/* clear background - no dropdown */
.style_highlight-nav-white__2YFHs::after {
  content: '';
  position: absolute;
  display: block;
  height: 2px;
  width: 90%;
  bottom: 0;
  left: 5%;
  background-color: #fff;
  background-color: var(--white);
  z-index: 99;
}

.style_hiddenNav__wC3er {
  display: none;
}

/*Dark mode version of nav bar*/
@media (prefers-color-scheme: dark) {
  .style_activeNav__3Nscp {
    background-color: #000000;
  }

  .style_active-nav-item__1Wk56 a,
  .style_active-nav-item__1Wk56 span,
  .style_active-nav-item__1Wk56:after {
    color: #ffffff;
  }

  .style_dropdown-item__2kaNe:hover {
    background-color: #000000;
  }

  .style_dropdown-menu__1lwov {
    border-color: #000000;
    background-color: #000000;
  }

  .style_dropdown-item__2kaNe a {
    color: #fff;
  }

  .style_transparent__XoLye::after {
    border-bottom-color: #000000;
  }

  .style_mediaDD__ss-Bl::after {
    border-bottom-color: #000000;
  }
}

/* Mobile responsiveness of nav bar */
@media screen and (max-width: 950px) {
  .style_nav-item__3zwXW {
    display: none;
  }

  .style_bg-custom__2L_As {
    background-color: #fff7ef;
    background-color: var(--light-yellow);
  }

  .style_logo-black__1incE {
    display: block;
  }
    
  .style_nav-item-btn__3n1w_ {
    color: white;
    display: block;
    z-index: 99;
  }
}

/* Mobile responsiveness of nav bar and dark mode */
@media screen and (max-width: 950px) and (prefers-color-scheme: dark) {
  .style_activeNav__3Nscp {
    background-color: #000;
  }

  .style_active-nav-item__1Wk56 a,
  .style_active-nav-item__1Wk56 span,
  .style_active-nav-item__1Wk56:after {
    color: #ffffff;
  }

  .style_bg-custom__2L_As {
    background-color: #000;
  }

  .style_dropdown-item__2kaNe:hover {
    background-color: #000;
  }

  .style_dropdown-menu__1lwov {
    border-color: #000000;
    background-color: #000000;
  }

  .style_dropdown-item__2kaNe a {
    color: #fff;
  }

  .style_transparent__XoLye::after {
    border-bottom-color: #000000;
  }

  .style_mediaDD__ss-Bl::after {
    border-bottom-color: #000000;
  }

  .style_nav-item-btn__3n1w_ {
    color: white;
    display: block;
    z-index: 99;
  }

  .style_photo__2G9Bk {
    display: block;
  }
}

/*----------------------------------Loading Screen------------------------------------*/
#style_loadingBackground__MxxSl {
  background: #fff7ef;
  background: var(--light-yellow);
  height: 100vh;
  z-index: 100;
  position: absolute;
  width: 100vw;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#style_loadingWillow__1VrMD {
  height: 300px;
  width: 300px;
}

#style_loadingBackground__MxxSl p {
  overflow: hidden;
  font-size: 18pt;
  -webkit-animation: style_typing__1MaQO 2.5s steps(34, end);
          animation: style_typing__1MaQO 2.5s steps(34, end);
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
}

@-webkit-keyframes style_typing__1MaQO {
  from {
    width: 0;
  }
  to {
    width: 10em;
  }
}

@keyframes style_typing__1MaQO {
  from {
    width: 0;
  }
  to {
    width: 10em;
  }
}

@media (prefers-color-scheme: dark) {
  #style_loadingBackground__MxxSl {
    background: #323235;
  }

  #style_loadingBackground__MxxSl p {
    color: #fff; 
  }
}

@media screen and (max-width: 320px) {
  #style_loadingBackground__MxxSl {
    height: 100%;
    position: fixed;
    width: 100%;
  }
}

@media screen and (max-width: 300px) {
  #style_loadingWillow__1VrMD {
    height: 280px;
    width: 280px;
  }
}

/*-----------------------------------Menu-----------------------------------*/

.style_menu-container__2dK7y {
  position: fixed;
  width: 100%;
  background: #fff7ef;
  background: var(--light-yellow);
  opacity: 1;
  color: #313638;
  z-index: 3;
  box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.2);
}

.style_menu-link__2nkeB {
  text-decoration: none;
}

.style_menu-item__1xVbj {
  color: #313638;
  padding: 5px 35px;
  text-decoration: none;
  letter-spacing: 1.5px;
  text-transform: capitalize;
  cursor: pointer;
  font-size: 16px;
}

.style_menu-box__3h74a:hover {
  width: 92%;
  background-color: #fff0e1c1;
  background-color: var(--sand);
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
}

.style_menu-box__3h74a:hover .style_menu-item__1xVbj {
  color: #e85f5c;
  color: var(--red);
}

.style_menu-bar__2dIuC {
  float: none;
}

.style_menu-list__1Fi3F {
  padding-top: 4rem;
}

.style_menu-dropdown__23h-i {
  padding: 5px 0px;
}

/* Dark mode and responsive menu dropdown*/
@media screen and (max-width: 950px) and (prefers-color-scheme: dark) {
  .style_menu-container__2dK7y {
    background: #000000;
    color: #fff;
  }

  .style_menu-item__1xVbj {
    color: #fff;
  }
  
  .style_menu-box__3h74a:hover {
    background-color: #202124;
    background-color: var(--dark-grey);
  }
  
  .style_menu-box__3h74a:hover .style_menu-item__1xVbj {
    color: #e85f5c;
    color: var(--red);
  }
}

/*----------------------------------Footer------------------------------------*/
/* WIT logo */
.style_photo__2G9Bk {
  width: 40px;
}

footer {
  margin: 0px;
  padding: 0px;
  background-color: #fff7ef;
  background-color: var(--light-yellow);
  min-width: 100%;
}

.style_footer-all__3P6Fj {
  list-style-type: none;
  margin: 0 auto 1.4vw auto;
  padding: 4vw 0vw 2vw 0vw;
}

.style_footer-item__1rs_d {
  font-family: "Montserrat", sans-serif;
  padding: 0.5vw;
  color: #313638;
  color: var(--black);
  font-size: 1.05vw;
}

.style_footer-header__31lni {
  font-weight: 750;
  padding-bottom: 1vw;
}

.style_bottom-footer-item__3FzQq {
  font-family: "Montserrat", sans-serif;
  padding: 0.3vw;
  color: #313638;
  color: var(--black);
  text-transform: uppercase;
}

.style_footer-logo__3PnXz {
  margin-top: 1%;
  padding-left: 5%;
}

.style_socials__3F4Cp .style_footer-icons__1Jz6V {
  padding: 0px 0.5vw;
}

.style_square-link__25Rqq {
  width: 80%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 1.75rem;
  padding: 0px;
}

.style_footer-icons-img__3N-Ah {
  width: 100%;
  height: 100%;
}

/*Social Tooltip Styles*/
.style_tooltipTitle__2otH4 {
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
  margin: 10px 0;
}

.style_col-width__1g71w {
  flex-basis: 40%;
}

.style_socials__3F4Cp .style_footer-item__1rs_d {
  text-align: center;
  display: flex;
  justify-content: center;
}

.style_footer-copyright__2R-PU {
  font-family: "Montserrat", sans-serif;
  text-transform: none;
  padding-bottom: 1vw;
  font-size: 1.05vw;
}

.style_footer-copyright__2R-PU,
a {
  color: #313638;
  transition: 0.2s;
}

.style_footer-item__1rs_d a {
  transition: 0.2s;
}

.style_footer-item__1rs_d a:hover {
  color: #e85f5c;
  color: var(--red);
  text-decoration: none;
}

.style_bottom-footer-item__3FzQq a:hover {
  color: #e85f5c;
  color: var(--red);
  text-decoration: none;
}

.style_grid-footer-container__2tNBn {
  display: flex;
  width: 90%;
  margin: 0px auto;
}

/*---Dark mode version of footer---*/
.style_footer-logo-black__1_3aD {
  display:block;
  width: 50px;
}

.style_footer-logo-white__GZbwv {
  display:none;
}

@media (prefers-color-scheme: dark) {
  .style_footer-logo-black__1_3aD {
    display: none;
  }

  .style_footer-logo-white__GZbwv {
    display: block;
    width: 50px;
  }

  footer {
    color: #fff;
    color: var(--white);
    background: black;
  }

  .style_bottom-footer-item__3FzQq {
    color: #fff;
    color: var(--white);
  }

  .style_footer-item__1rs_d {
    color: #fff;
    color: var(--white);
  }

  .style_footer-copyright__2R-PU,
  a {
    color: #fff;
    color: var(--white);
  }
}

@media screen and (max-width: 900px) {
  .style_square-link__25Rqq {
    width: 100%;
    grid-gap: 1.25rem;
  }
}

@media screen and (max-width: 600px) {
  .style_grid-footer-container__2tNBn {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .style_footer-item__1rs_d {
    text-align: center;
    font-size: 16px;
  }

  .style_footer-copyright__2R-PU {
    font-size: 16px;
    padding-bottom: 5vw;
  }

  .style_footer-white-logo__3-07j {
    margin-bottom: 2%; 
  }
  
  .style_footer-black-logo__36qY9 {
    margin-bottom: 2%;
  }

  .style_footer-icons__1Jz6V {
    display: center;
    margin-left: 2.5vw;
    margin-right: 2.5vw;
  }

  .style_socials__3F4Cp {
    margin-left: 0%;
    margin-top: 0%;
  }

  .style_footer-all__3P6Fj {
    margin: 0vw auto 1vw auto;
  }

  .style_footer-item__1rs_d {
    padding-bottom: 2vw;
  }
  
  .style_bottom-footer-item__3FzQq {
    padding-bottom: 5vw;
  }

  .style_footer-header__31lni {
    margin-top: 7.5%;
  }

  .style_square-link__25Rqq {
    display: inline-block;
  }

  .style_footer-icons-img__3N-Ah {
    height: 25px;
    width: 25px;
  }
}

@media screen and (max-width: 500px) {
  .style_footer-icons__1Jz6V {
    margin-left: 1.5vw;
    margin-right: 1.5vw;
  }  
}

@media screen and (max-width: 355px) {
  .style_footer-icons__1Jz6V {
    margin-left: 1vw;
    margin-right: 1vw;
  }  
}
/*-------------------------------Home page header-----------------------------*/
/*Header image*/
.home_contain__XyOnG {
  background-image: url("/headers/header-1.jpg");
  background-position: center bottom 40%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  max-width: 100%;
  background-color: var(--general-text);
}

/*Header title*/
div .home_headline__vlslr {
  text-align: left;
  position: absolute;
  top: 20%;
  left: 54vw;
}

.home_headline__vlslr h1 {
  color: var(--yellow);
  font-family: "Playfair Display", serif;
  font-size: 5.5vw;
  text-transform: uppercase;
  letter-spacing: 3px;
}

/*Header subtitle*/
.home_headline__vlslr p {
  color: var(--white);
  font-family: "Montserrat", sans-serif;
  font-size: 1.17vw;
  letter-spacing: 2.5px;
}

.home_headline__vlslr button {
  border: none;
  background-color: var(--red);
  transition: 0.3s;

  text-align: center;
  display: inline-block;
  position: relative;
  padding: 13px 0px;
  width: 120px;
  overflow: hidden;
}

.home_headline__vlslr button:before {
  content: "➜";
  font-size: 11pt;
  color: var(--white);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  top: 0;
  opacity: 0;
  height: 100%;
  width: 40px;
  transition: all 0.2s linear 0s;
}

.home_headline__vlslr button:hover:before {
  opacity: 1;
  text-indent: 0px;
}

.home_headline__vlslr button:hover {
  background-color: var(--dark-red);
  text-indent: -20px;
}

.home_headline__vlslr a {
  color: var(--white);
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1em;
}

.home_link__2kfWl {
  text-decoration: none;
}

.home_headline__vlslr a:hover {
  color: var(--white);
}

/*-----------------------------Home page description--------------------------*/
/*Description wrapper*/
.home_description__3gsqJ {
  margin: auto;
  max-width: 85%;
  display: grid;
  padding: 100px 0px;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
}

/*Description left block*/
.home_descriptionLeft__3fXqn {
  grid-area: auto / span 1;
}

/*Left block text*/
.home_descriptionLeft__3fXqn h1 {
  color: var(--yellow);
  font-family: "Montserrat", sans-serif;
  font-size: 4.5vw;

  font-weight: 600;
  letter-spacing: 4px;
  line-height: 100px;
  text-align: right;
}

/*Description right block*/
.home_descriptionRight__1aDBF {
  line-height: 22px;
  padding-top: 20px;
  grid-area: auto / span 1;
}

/*Right block text*/
.home_descriptionRight__1aDBF p {
  color: var(--general-text);
  font-family: "Montserrat", sans-serif;
  font-size: 11pt;
  text-align: justify;
}

/*Description center block*/
.home_descriptionMiddle__3_uAN {
  grid-area: auto / span 1;
}

/*Dark mode*/
@media (prefers-color-scheme:dark) {
  .home_descriptionRight__1aDBF p {
    color:white;
  }
}

/*---------------------------Home page statistics-----------------------------*/

/*Background image*/
.home_statsBackground__2MB15 {
  height: 300px;
  width: 100%;
  object-fit: cover;
}

.home_stats__2MUyQ {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home_number__2HO-I {
  display: inline-grid;
  grid-template-columns: 30% 30% 45%;
  left: 0;
  margin: 0 auto;
  place-items: center center;
  position: absolute;
  right: 0;
  text-align: center;
  width: 60%;
  bottom: 30%;
}

.home_number__2HO-I span {
  color: var(--yellow);
  font-family: "Montserrat", sans-serif;
  font-size: 75pt;
  font-weight: 500;
  letter-spacing: 15px;
}

.home_number__2HO-I p {
  color: var(--white);
  font-family: "Montserrat", sans-serif;
  font-size: 14pt;
}

/*-------------------------------Home page events-----------------------------*/
/*Events block*/
.home_events__2tK_6 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 100px auto;
  grid-column-gap: 50px;
  padding: 100px 0px;
  max-width: 85%;
  margin: 0 auto;
}

/*Events description grid area*/
.home_eventsDescription__1o2jv {
  width: 100%;
  height: 100%;
  padding-bottom: 80px;
}

/*Events description text*/
.home_eventsDescription__1o2jv p {
  color: var(--general-text);
  font-family: "Montserrat", sans-serif;
  font-size: 11pt;
  text-align: right;
}

.home_eventsDescription__1o2jv p#home_about__33oN6 {
  padding: 20px;
  padding-bottom: 0;
}

.home_eventsDescription__1o2jv a {
  font-family: "Montserrat", sans-serif;
  text-align: right;
  color: var(--red);
  float: left;
}

/*Events title grid area*/
.home_eventsTitle__1C5R6 {
  text-align: left;
  grid-area: 1 / 2;
}

.home_eventsTitle__1C5R6 h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 55px;
  text-align: left;
}

/*Big event image grid area*/
.home_event1__18XNz {
  height: 100;
}

/*Big event image size*/
.home_event1__18XNz img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  overflow: hidden;
}

.home_event1__18XNz img:hover { /* IE 9 */
  -webkit-transform: scale(1.5); /* Safari 3-8 */
  transform: scale(1.03);
}

.home_eventsDescription__1o2jv button {
  border: 1.5px solid var(--red);
  box-sizing: border-box;
  background-color: var(--white);
  padding: 6px 6px;
  transition: 0.3s;
}

.home_eventsDescription__1o2jv button:hover {
  border: 1.5px solid var(--red);
  background-color: #ffeeee;
}

.home_eventsDescription__1o2jv a {
  font-weight: 500;
}

.home_eventsDescription__1o2jv a:hover {
  color: var(--red);
}

/*Dark mode*/
@media (prefers-color-scheme:dark) {
  .home_eventsDescription__1o2jv p {
    color: #fff;
  }

  .home_eventsDescription__1o2jv button {
    background-color:#202124;
  }

  .home_eventsDescription__1o2jv button:hover {
    background-color: var(--light-grey);
  }
}

/*---------------Slideshow-------------------*/

/*quotes section background*/
.home_quotes__3YqK_ {
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
  background: var(--section);
}
  
/*Center block text*/
.home_quotes__3YqK_ h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 55px;
  text-align: center;
  line-height: normal;
  margin: 0px;
}

.home_quoteCarousel__SQFfA {
  margin-top: 20px;
}

.home_carousel__tXJtR {
  text-align: center;
  padding: 100px 50px;
  position: relative;
}

.home_slideComponent__1HBwy {
  height: 100%;
}

.home_slideContainer__3xzeJ {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home_quotes__3YqK_ .home_carousel__tXJtR {
  padding-top: 0px;
  padding-bottom: 0px;
}

.home_carousel__tXJtR h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 55px;
  text-align: left;
}

.home_prev__3IQag,
.home_next__23OCq {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: var(--white);
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

/* Position the "next button" to the right */
.home_next__23OCq {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* Fading animation */
.home_fade__2z-cz {
  -webkit-animation-name: home_fade__2z-cz;
  -webkit-animation-duration: 1.5s;
  animation-name: home_fade__2z-cz;
  animation-duration: 1.5s;
}

.home_eventsDescription__1o2jv .home_left__1FzE0 {
  width: 50%;
  height: 100%;
  float: left;
  padding: 21px 15px 10px 80px;
}

.home_eventsDescription__1o2jv .home_left__1FzE0 p#home_about__33oN6 {
  padding: 20px;
  padding-bottom: 0;
  max-width: 100%;
  max-height: 100%;
}

.home_eventsDescription__1o2jv .home_right__2OV7q {
  width: 50%;
  height: 100%;
  float: right;
  padding: 10px 55px 10px 15px;
}

.home_right__2OV7q img {
  object-fit: contain;
  max-height: 100%;
  max-width: 100%;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  overflow: hidden;
}

.home_right__2OV7q a {
  font-family: "Montserrat", sans-serif;
  text-align: right;
  color: var(--red);
  float: left;
  height: 100%;
}

.home_right__2OV7q img:hover { /* IE 9 */
  -webkit-transform: scale(1.5); /* Safari 3-8 */
  transform: scale(1.03);
}

.home_carousel__tXJtR .home_buttonBack__1uRdU {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 0;
  width: 0;
  height: 0;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  border-right: 30px solid #313638;
  border-left: 0;
  padding: 0;
  background-color: transparent;
  outline: 0;
}

.home_carousel__tXJtR .home_buttonNext__1A-Kb {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 0;
  width: 0;
  height: 0;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  border-left: 30px solid #313638;
  border-right: 0;
  padding: 0;
  background-color: transparent;
  outline: 0;
}

.home_buttonBack__1uRdU:disabled {
  border-right: 30px solid var(--light-grey);
}

.home_buttonNext__1A-Kb:disabled {
  border-left: 30px solid var(--light-grey);
}

.home_titleMobile__2Bxzo h1 {
  display: none;
  text-align: center;
  grid-area: 1 / 2;
  font-family: "Montserrat", sans-serif;
  font-size: 55px;
}

/* .home_publications__ncQSH img { */
/* margin-top: 50px; */
/* width: 50%; */
/* max-width: 500px; */
/* text-align: right; */
/* float: right; */

/*---Dark mode---*/
@media (prefers-color-scheme:dark) {
  .home_carousel__tXJtR .home_buttonBack__1uRdU {
    border-right: 30px solid var(--light-grey);
  }
  
  .home_carousel__tXJtR .home_buttonNext__1A-Kb {
    border-left: 30px solid var(--light-grey);
  }

  .home_buttonBack__1uRdU:disabled {
    border-right: 30px solid var(--grey);
  }
  
  .home_buttonNext__1A-Kb:disabled {
    border-left: 30px solid var(--grey);
  }

  .home_quotes__3YqK_ {
    background: #323235;
  }
}

/*------------------------------Home page sponsors----------------------------*/
/*Sponsors image position*/
.home_sponsors__4LLP6 {
  text-align: center;
  margin: auto;
  padding-top: 100px;
  padding-bottom: 100px;
  width: 85%;
}

.home_sponsors__4LLP6 h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 55px;
  letter-spacing: 3px;
  text-align: center;
  margin: 0px;
  line-height: normal;
}

.home_sponsors__4LLP6 img {
  margin-top: 50px;
  width: 100%;
  max-width: 900px;
}

.home_darkmodeBanner__18pPp {
  display:none;
}

.home_lightmodeBanner__1Xl59 {
  display:unset;
}

@media (prefers-color-scheme:dark) {
  .home_lightmodeBanner__1Xl59 {
    display:none;
  }

  .home_darkmodeBanner__18pPp {
    display: unset;
  }
}

/*--------------Publications-------------------*/
#home_publicationsLoading__CRcjq {
  color: var(--yellow);
  margin-top: 7%;
  margin-bottom: 8%;
}

.home_publications__ncQSH {
  text-align: center;
  margin: auto;
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: var(--section);
}

.home_publications__ncQSH h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 55px;
  letter-spacing: 3px;
  line-height: 100px;
  text-align: center;
  margin: 0px;
  line-height: normal;
}

.home_publications__ncQSH img {
  width: 100%;
  max-width: 900px;
}

.home_publications__ncQSH .home_articlesDiv__1qHfs {
  margin: 50px auto 40px  auto;
  width: 85%;
}

.home_pubBtn__1591D {
  border: 1.5px solid var(--red);
  box-sizing: border-box;
  background-color: var(--section);
  padding: 6px 6px;
  transition: 0.3s;
}

.home_pubBtn__1591D a {
  color: var(--red);
  font-family: "Montserrat", sans-serif;
  font-size: 11pt;
  font-weight: 500;
}

.home_pubBtn__1591D:hover a {
  color: var(--red);
}

.home_pubBtn__1591D:hover {
  border: 1.5px solid var(--red);
  background-color: #ffeeee;
}

.home_articles__3RXF6 {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  width: 85%;
  margin: 0 auto;
}

.home_homeArticles__2cn16 div {
  margin: 0;
}

/*---Dark mode---*/
@media (prefers-color-scheme:dark){
  .home_publications__ncQSH {
    background-color: #323235;
  }

  .home_pubBtn__1591D {
    border: 1.5px solid var(--red);
    background-color: #323235;
  }
  
  .home_pubBtn__1591D:hover {
    background-color:var(--sand);
  }
}

/*-----------------------------Responsive Mobile Design--------------------------*/
@media only screen and (max-width: 1200px) {
  .home_number__2HO-I {
    width: 70%;
  }
}

@media screen and (max-width: 1065px) {
  .home_quoteCarousel__SQFfA {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 1065px) {
  .home_publications__ncQSH .home_homeArticles__2cn16 {
    padding: 15px 15px 15px 15px;
    display: inline-block;
  }
}

@media only screen and (max-width: 1000px) {
  .home_number__2HO-I {
    grid-template-columns: 25% 30% 45%;
    position: absolute;
    width: 85%;
  }

  .home_number__2HO-I span {
    letter-spacing: 15px;
  }
  .home_number__2HO-I p {
    font-size: 2vw;
  }
}

@media only screen and (max-width: 990px) {
  .home_sponsors__4LLP6 {
    padding: 100px 0;
  }

  .home_carousel__tXJtR {
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
  }

  .home_eventsDescription__1o2jv {
    order: 2;
    padding: 0px;
    height: auto;
    max-width: 90%;
    margin: auto;
    padding: 5px;
  }

  .home_eventsDescription__1o2jv .home_left__1FzE0 {
    padding: 0px 5px 0px 5px;
    width: 85%;
    height: auto;
    float: none;
    margin: auto;
  }

  .home_left__1FzE0 p {
    margin: 20px 0;
    text-align: justify;
  }

  .home_eventsDescription__1o2jv .home_right__2OV7q {
    padding: 0px 5px 0px 5px;
    width: 85%;
    height: auto;
    float: none;
    margin: auto;
  }

  .home_eventsDescription__1o2jv .home_right__2OV7q h1 {
    font-size: 5vw;
    text-align: center;
    display: none;
  }

  .home_right__2OV7q img {
    object-fit: contain;
  }

  .home_right__2OV7q a {
    height: auto;
    text-align: center;
  }

  .home_titleMobile__2Bxzo h1 {
    display: contents;
    font-size: 55px;
    padding-top: 50px;
  }

  .home_dot__3ulK7 {
    display: contents;
    height: 25px;
    width: 25px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
  }

  .home_slide_component__2y6Ci {
    height: 840px;
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: 946px) {
  .home_eventsDescription__1o2jv .home_left__1FzE0 p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1065px) {
  .home_publications__ncQSH .home_homeArticles__2cn16 {
    padding: 15px 15px 15px 15px;
    display: inline-block;
  }
}

@media only screen and (max-width: 850px) {
  .home_events__2tK_6 {
    padding: 50px 0px;
    max-width: 85%;
    margin: 0 auto;
    display: flex;
    flex-flow: column;
  }

  /* Use a Flexbox to switch positions of Title and Description. */
  /*Events title - flexbox*/
  .home_eventsTitle__1C5R6 {
    margin: 0 auto;
    order: 1;
    padding-left: 0px;
  }

  .home_eventsTitle__1C5R6 h1 {
    text-align: center;
    font-size: 9vw;
  }

  .home_quotes__3YqK_ h1 {
    font-size: 9vw;
    text-align: center;
  }

  .home_eventsDescription__1o2jv a {
    text-align: justify;
  }

  /*Big event image area - flexbox*/
  .home_event1__18XNz {
    order: 3;
  }

  .home_contain__XyOnG {
    background-position: center bottom 40%;
    height: 66vh;
  }

  /*Header title*/
  div .home_headline__vlslr {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto 15%;
    height: 70vh;
  }

  .home_headline__vlslr button {
    padding: 8px 8px;
  }

  .home_headline__vlslr h1 {
    font-size: 6vw;
    text-transform: uppercase;
    letter-spacing: 3px;
  }

  /*Header subtitle*/
  .home_headline__vlslr p {
    font-size: 10pt;
    letter-spacing: 2.5px;
  }

  .home_headline__vlslr a {
    font-size: 12px;
  }

  .home_description__3gsqJ {
    max-width: 85%;
    display: block;
    padding: 0px;
  }

  .home_descriptionLeft__3fXqn {
    width: 100%;
    padding: 50px 0px 0px 0px;
    height: auto;
  }

  .home_descriptionLeft__3fXqn h1 {
    padding: 0px;
    font-size: 9vw;
    line-height: 90px;
  }

  .home_descriptionRight__1aDBF {
    width: 100%;
    height: auto;
    padding: 20px 0px 50px 0px;
  }

  .home_sponsors__4LLP6 {
    padding: 50px 0;
  }

  .home_sponsors__4LLP6 h1 {
    font-size: 9vw;
  }

  .home_sponsors__4LLP6 img {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 990px) {
  .home_slideComponent__1HBwy {
    height: 840px;
    display: flex;
    flex-direction: column;
  }

  .home_sponsors__4LLP6 {
    padding: 100px 0;
  }

  .home_carousel__tXJtR {
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
  }

  .home_eventsDescription__1o2jv {
    order: 2;
    padding: 0px;
    height: auto;
    max-width: 90%;
    margin: auto;
    padding: 5px;
  }

  .home_eventsDescription__1o2jv .home_left__1FzE0 {
    padding: 0px 5px 0px 5px;
    width: 85%;
    height: auto;
    float: none;
    margin: auto;
  }

  .home_left__1FzE0 p {
    margin: 20px 0;
    text-align: justify;
  }

  .home_eventsDescription__1o2jv .home_right__2OV7q {
    padding: 0px 5px 0px 5px;
    width: 85%;
    height: auto;
    float: none;
    margin: auto;
  }

  .home_eventsDescription__1o2jv .home_right__2OV7q h1 {
    font-size: 5vw;
    text-align: center;
    display: none;
  }

  .home_right__2OV7q img {
    object-fit: contain;
  }

  .home_right__2OV7q a {
    height: auto;
    text-align: center;
  }

  .home_titleMobile__2Bxzo h1 {
    display: contents;
    font-size: 55px;
    padding-top: 50px;
  }

  .home_dot__3ulK7 {
    display: contents;
    height: 25px;
    width: 25px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
  }
}

/* Adjusting font size of carousel description  */
@media only screen and (max-width: 946px) {
  .home_eventsDescription__1o2jv .home_left__1FzE0 p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 850px) {
  .home_publications__ncQSH h1 {
    font-size: 9vw;
  }

  .home_publications__ncQSH .home_articlesDiv__1qHfs {
    margin-top: 15px;
  }

  .home_titleMobile__2Bxzo h1 {
    display: contents;
    font-size: 9vw;
    padding-top: 50px;
  }

  div .home_headline__vlslr {
    top: 15%;
  }
}

@media only screen and (max-width: 800px) {
  .home_number__2HO-I span {
    font-size: 12.5vw;
    letter-spacing: 8px;
  }

  .home_number__2HO-I p {
    font-size: 2.4vw;
  }

  .home_number__2HO-I {
    bottom: 33%;
    grid-template-columns: 28% 30% 45%;
    width: 85%;
  }

  .home_subscribeBtn__8RPBr {
    font-size: 16pt;
    width: 70%;
    right: 15%;
    bottom: 38%;
  }
}

@media only screen and (max-width: 700px) {
  .home_slideComponent__1HBwy {
    height: 670px;
  }

  .home_carousel__tXJtR {
    padding: 40px 0;
  }

  .home_carousel__tXJtR .home_buttonBack__1uRdU, .home_homeLeftArrow__1lYbg {
    margin-left: 25px; 
  }

  .home_carousel__tXJtR .home_buttonNext__1A-Kb, .home_homeRightArrow__B_NSk {
    margin-right: 25px;
  }

  .home_carousel__tXJtR .home_buttonBack__1uRdU:disabled, .home_homeLeftArrow__1lYbg:disabled {
    margin-left: 25px;
  }

  .home_carousel__tXJtR .home_buttonNext__1A-Kb:disabled, .home_homeRightArrow__B_NSk:disabled {
    margin-right: 25px;
  }
}

@media only screen and (max-width: 550px) {
  .home_slideComponent__1HBwy {
    height: 600px;
  }

  .home_carousel__tXJtR .home_buttonBack__1uRdU {
    margin-left: 20px;
  }

  .home_carousel__tXJtR .home_buttonNext__1A-Kb, .home_homeRightArrow__B_NSk {
    margin-right: 20px;
  }

  .home_carousel__tXJtR .home_buttonBack__1uRdU:disabled {
    margin-left: 20px;
  }

  .home_carousel__tXJtR .home_buttonNext__1A-Kb:disabled {
    margin-right: 20px;
  }
}


@media only screen and (max-width: 500px) {
  .home_eventsDescription__1o2jv {
    max-width: 80%;
  }

  .home_number__2HO-I {
    bottom: 40%;
  }
}

@media only screen and (max-width: 400px) {
  .home_subscribeBtn__8RPBr {
    font-size: 14pt;
    width: 80%;
    right: 10%;
  }

  div .home_headline__vlslr {
    top: 20%;
  }
}

/*-------------------------------Publications structure------------------------------*/
/* Subheading */
.publications_publicationsBody__2PqsG h1 {
  font-family: "Montserrat", sans-serif;
}

/*Body block*/
.publications_publicationsBody__2PqsG {
  max-width: 70%;
  margin: auto;
  padding-top: 100px;
}

.publications_row__2Enr9 {
  margin-top: 20px;
  margin-bottom: 100px;
  height: auto;
}

/*Right side block*/
.publications_article__1mU8J {
  display: inline-block;
  width: 212px;
  height: 300px;
  text-align: center;
  margin-right: 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
}

/*-------------------------------Publications Article------------------------------*/

.publications_imageContainer__2gFiA {
  position: absolute;
  z-index: 0;
}

.publications_articleImg__3msPr {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: relative;
}

.publications_article__1mU8J .publications_dark__1oeez {
  width: 100%;
  background-color: black;
  opacity: 0;
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 1;
  transition: opacity 0.5s ease;
}

.publications_textContainer__3Ophf {
  opacity: 0;
  transition: opacity 0.5s ease;
}

.publications_article__1mU8J:hover > .publications_textContainer__3Ophf {
  opacity: 1;
}

.publications_article__1mU8J:hover .publications_dark__1oeez {
  opacity: 0.7;
}

.publications_textContainer__3Ophf {
  position: absolute;
  left: 50%;
  z-index: 2;
  top: 70px;
}

.publications_article__1mU8J h2 {
  left: -50%;
  text-transform: capitalize;
  text-align: center;

  font-size: 21px;
  display: block;
  position: relative;
  padding: 5px;
}

.publications_article__1mU8J p {
  font-size: 15px;
  color: white;
  font-family: "Montserrat", sans-serif;
  display: block;
  text-align: center;
  float: center;
  left: -50%;
  position: relative;
}

/*read only button, change for functionality*/
.publications_button__1yQ3h {
  background-color: #e85f5c;
  height: 30px;
  width: 120px;
  left: -50%;
  position: relative;
  border-radius: 60px;
}

.publications_button__1yQ3h a {
  font-size: 10px;
  font-weight: 500px;
  color: white;
  text-transform: uppercase;
  white-space: nowrap;
  float: center;
  line-height: 30px;
  letter-spacing: 0.1vw;
}

.publications_button__1yQ3h a, .publications_button__1yQ3h:hover a {
  text-decoration: none;
  color: white;
}

.publications_button__1yQ3h:hover a {
  text-decoration: underline;
}
/*-------------------------------Loading Sign-----------------------------*/
#publications_pubLoading__2dh-g {
  color: var(--yellow);
  margin-bottom: 100px;
}

#publications_pubLoadingContainer__2oC-j {
  display: flex;
  justify-content: center;
}

/* ------------ Responsive mobile design ------------ */

@media only screen and (max-width: 850px) {
  .publications_article__1mU8J {
    margin: 15px 15px;
    width: 178px;
    height: 250px;
  }

  .publications_article__1mU8J .publications_dark__1oeez {
    margin: 0px 0px;
    width: 178px;
    height: 250px;
  }

  .publications_textContainer__3Ophf {
    top: 45px;
  }

  .publications_publicationsBody__2PqsG h1 {
    padding-left: 10px;
  }
}

@media screen and (max-width: 500px) {
  .publications_publicationsBody__2PqsG h1 {
    padding-left: 12px;
  }
}
/*-------------------------------Newsletter-----------------------------*/
/*Background image*/
.Newsletter_background__2IGi9 {
  height: 300px;
  width: 100%;
  object-fit: cover;
}

#Newsletter_container__2Yo89 {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Newsletter_subscribeBtn__1L4s9 {
  position: absolute;
  right: 20%;
  text-align: center;
  width: 60%;
  bottom: 37%;
  color: #ffffff;
  background-color: #e85f5c;
  padding: 12px 10px;
  border: none;
  font-size: 2vw;
  text-transform: uppercase;
  border: none;
  letter-spacing: 3px;
  transition: 0.3s;
}

.Newsletter_subscribeBtn__1L4s9:hover {
  background-color: #bd3f3d;
}

.Newsletter_signUpForm__UPAeG span {
  color: #e85f5c;
  font-family: "Montserrat", sans-serif;
}

.Newsletter_signUpForm__UPAeG {
  display: flex;
  align-items: center;
  justify-content: center;
}

/*-------------------------------Newsletter section Mobile Design-----------------------------*/
@media only screen and (max-width: 1200px) {
  .Newsletter_subscribeBtn__1L4s9 {
    font-size: 20pt;
    width: 70%;
    right: 15%;
  }
}

@media only screen and (max-width: 800px) {
  .Newsletter_subscribeBtn__1L4s9 {
    font-size: 16pt;
    width: 70%;
    right: 15%;
    bottom: 38%;
  }
}

@media only screen and (max-width: 400px) {
  .Newsletter_subscribeBtn__1L4s9 {
    font-size: 14pt;
    width: 80%;
    right: 10%;
  }
}

/*-------------------------------Newsletter sign up form-----------------------------*/
form input {
  margin: 15px auto;
  display: block;
  font-size: 1.2vw;
  width: 100%;

  border-style: none;
  border-bottom: solid;
  border-color: var(--general-text);
  border-width: 2px;
  padding: 5px;
  font-family: "Montserrat", sans-serif;
  width: 50%;
}

form button {
  background-color: #e85f5c;
  color: #ffffff;
  font-size: 1.3vw;
  width: 100%;
  margin: 20px auto;
  border: none;
  display: block;
  padding: 10px;
  letter-spacing: 3px;
  text-transform: uppercase;
  width: 50%;
}

.Newsletter_newsletterImg__3UcWB {
  width: 40%;
  object-fit: contain;
}

.Newsletter_paper__3fy0J {
  background-color: white;
  border-radius: 2;
  padding: 1vw 2vw 2vw 2vw;
  width: 100%;
}

.Newsletter_formTitle__1IfTr {
  color: var(--yellow);
  font-size: 2.5vw;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 3px;
  text-transform: uppercase;
  text-align: center;
}

.Newsletter_formDescription__2DyOU {
  color: #313638;
  font-family: "Montserrat", sans-serif;
  font-size: 12pt;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.Newsletter_msg-alert__1Wqfv p {
  font-size: 1.3vw;
}

.Newsletter_closeBtn__3x2Wu {
  float: right;
  margin: 12px;
}

.Newsletter_closeSymbol__2bh6B {
  color: #313638;
  text-align: right;
}

.Newsletter_subscribeError__iDLhu {
  color: #e85f5c;
  font-weight: 500;
}

/*--- Dark mode ---*/
@media (prefers-color-scheme:dark) {
  form input {
    background-color: #323235;
    border-color: white;
    color: white;
  }
  
  form button {
    background-color: var(--red);
    color: white;
  }
  
  .Newsletter_paper__3fy0J {
    background-color: var(--dark-grey);
  }
  
  .Newsletter_formDescription__2DyOU {
    color: white;
  }
  
  .Newsletter_closeSymbol__2bh6B {
    color: white;
  }
}

/*-------------------------------Newsletter Form Mobile Design-----------------------------*/
@media only screen and (max-width: 1000px) {
  form button {
    font-size: 12pt;
    width: 75%;
  }

  form input {
    width: 75%;
  }

  .Newsletter_formTitle__1IfTr {
    font-size: 14pt;
  }

  form input,
  .Newsletter_msg-alert__1Wqfv p {
    font-size: 12pt;
  }
}

@media only screen and (max-width: 600px) {
  form input,
  .Newsletter_msg-alert__1Wqfv p {
    font-size: 11pt;
  }

  form input,
  .Newsletter_formDescription__2DyOU,
  form button {
    width: 80%;
  }
}

/*---------------Quotes-------------------*/
.QuotesSlideshow_quoteBackground__8FzvI {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.QuotesSlideshow_quoteBlockCareers__2CfoK,
.QuotesSlideshow_quoteBlockCompetitions__TIbfr,  
.QuotesSlideshow_quoteBlockExternals__AiOXe, 
.QuotesSlideshow_quoteBlockHR__1jKzW, 
.QuotesSlideshow_quoteBlockIT__uhfBQ,
.QuotesSlideshow_quoteBlockMarketing__30P9h,
.QuotesSlideshow_quoteBlockMedia__2B3a-,
.QuotesSlideshow_quoteBlockPublications__3TSuN {
  height: 380px;
  margin-left: auto;
  margin-right: auto;
  width: 65%;
}

.QuotesSlideshow_quoteBlockDefault__3KX5O {
  height: 400px;
  margin-left: auto;
  margin-right: auto;
  width: 65%;
  background: #ffe9c0;
}

.QuotesSlideshow_quoteBlockCareers__2CfoK {
  background: #ffdfb4;
}

.QuotesSlideshow_quoteBlockCompetitions__TIbfr {
  background: #a1dbcb;
}

.QuotesSlideshow_quoteBlockExternals__AiOXe {
  background: #ffbfaf;
}

.QuotesSlideshow_quoteBlockHR__1jKzW {
  background: #b7ecbc;
}

.QuotesSlideshow_quoteBlockIT__uhfBQ {
  background: #B4C9E8;
}

.QuotesSlideshow_quoteBlockMarketing__30P9h {
  background: #dcbff7;
}

.QuotesSlideshow_quoteBlockMedia__2B3a- {
  background: #c0f5ff;
}

.QuotesSlideshow_quoteBlockPublications__3TSuN {
  background: #FFC2E4;
}

.QuotesSlideshow_authorBlock__2D2PA {
  display: flex;
  flex-flow: row wrap;
  background: #4F4F4F;
  padding: 20px;
  justify-content: center;
}

.QuotesSlideshow_authorDetails__28dib {
  text-align: left;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 30px;
}

.QuotesSlideshow_authorName__3FP0U {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 13px;
  color: #FFFFFF;
}

.QuotesSlideshow_authorRoles__1zq8V {
  width: 100%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
}

.QuotesSlideshow_defaultRoleColour__39mrb {
  color: #ffe9c0;
}

.QuotesSlideshow_careersRoleColour__1KF_q {
  color: #ffdfb4;
}

.QuotesSlideshow_competitionsRoleColour__1smCW {
  color: #a1dbcb;
}

.QuotesSlideshow_externalsRoleColour__gYJTp {
  color: #ffbfaf;
}

.QuotesSlideshow_hrRoleColour__1Ef7q {
  color: #b7ecbc;
}

.QuotesSlideshow_itRoleColour__ofbp1 {
  color: #B4C9E8;
}

.QuotesSlideshow_marketingRoleColour__9yjYc {
  color: #dcbff7;
}

.QuotesSlideshow_mediaRoleColour__2XWH5 {
  color: #c0f5ff;
}

.QuotesSlideshow_publicationsRoleColour__21Pma {
  color: #FFC2E4;
}

.QuotesSlideshow_authorImage__1D3L4 {
  width: 90px;
  height: 90px;
  object-fit: cover;
  border-radius: 50%;
  color: white;
  text-align: center;
}

.QuotesSlideshow_beginQuote__2652G, .QuotesSlideshow_endQuote__2woaN {
  margin: 0;
  color: #515151;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 180px;
}

.QuotesSlideshow_beginQuote__2652G {
  position: relative;
  right: 50%;
  top: 10px;
}

.QuotesSlideshow_endQuote__2woaN {
  position: relative;
  left: 50%;
  top: 80px;
}

.QuotesSlideshow_quoteContainer__3gaee {
  height: 65%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.QuotesSlideshow_quoteText__284MN {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: justify;
  margin: 0 auto;
  width: 90%;
}

.QuotesSlideshow_horizontalLine__2Wtqy {
  width: 90%;
  margin: 20px auto 0 auto;

  border: 0.5px solid #000000;
  -webkit-transform: matrix(1, 0, 0, -1, 0, 0);
          transform: matrix(1, 0, 0, -1, 0, 0);
}

/*---------------Carousel-------------------*/
.QuotesSlideshow_slideContainer__1FSCI {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.QuotesSlideshow_buttonBack__2mI3l {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 0;
  width: 0;
  height: 0;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  border-right: 30px solid #313638;
  border-left: 0;
  padding: 0;
  background-color: transparent;
  outline: 0;
}

.QuotesSlideshow_buttonNext__3VSeZ {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 0;
  width: 0;
  height: 0;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  border-left: 30px solid #313638;
  border-right: 0;
  padding: 0;
  background-color: transparent;
  outline: 0;
}

.QuotesSlideshow_buttonBack__2mI3l:disabled {
  border-right: 30px solid #c4c4c4;
}

.QuotesSlideshow_buttonNext__3VSeZ:disabled {
  border-left: 30px solid #c4c4c4;
}

/*---Dark mode---*/
@media (prefers-color-scheme:dark) {
  .QuotesSlideshow_buttonBack__2mI3l {
    border-right: 30px solid var(--light-grey);
  }
  
  .QuotesSlideshow_buttonNext__3VSeZ {
    border-left: 30px solid var(--light-grey);
  }
  
  .QuotesSlideshow_buttonBack__2mI3l:disabled {
    border-right: 30px solid var(--grey);
  }
  
  .QuotesSlideshow_buttonNext__3VSeZ:disabled {
    border-left: 30px solid var(--grey);
  }

  .QuotesSlideshow_beginQuote__2652G, .QuotesSlideshow_endQuote__2woaN {
    color: var(--light-grey);
  }
}

/*---------------Screen Responsiveness-------------------*/
@media only screen and (max-width: 1800px) {
  .QuotesSlideshow_slideComponent__zOGMV, .QuotesSlideshow_slideContainer__1FSCI {
    height: 450px;
  }
}

@media only screen and (max-width: 1100px) {
  .QuotesSlideshow_beginQuote__2652G {
    top: 15px;
  }

  .QuotesSlideshow_endQuote__2woaN {
    top: 75px;
  }

  .QuotesSlideshow_quoteBlockEducation__1Jxah, 
  .QuotesSlideshow_quoteBlockEvents__8g5m8, 
  .QuotesSlideshow_quoteBlockExternals__AiOXe, 
  .QuotesSlideshow_quoteBlockHR__1jKzW, 
  .QuotesSlideshow_quoteBlockIT__uhfBQ,
  .QuotesSlideshow_quoteBlockMarketing__30P9h,
  .QuotesSlideshow_quoteBlockDefault__3KX5O {
    width: 75%;
  }

  .QuotesSlideshow_slideComponent__zOGMV, .QuotesSlideshow_slideContainer__1FSCI {
    height: 400px;
  }
}

@media only screen and (max-width: 850px) {
  .QuotesSlideshow_authorBlock__2D2PA {
    flex-direction: column;
    align-items: center;
  }

  .QuotesSlideshow_authorDetails__28dib {
    text-align: center;
    margin-top: 5px;
    margin-left: 0px;
  }

  .QuotesSlideshow_beginQuote__2652G, .QuotesSlideshow_endQuote__2woaN, .QuotesSlideshow_horizontalLine__2Wtqy {
    display: none;
  }

  .QuotesSlideshow_quoteContainer__3gaee {
    height: 55%;
  }
}

@media only screen and (max-width: 800px) {
  .QuotesSlideshow_quoteBlockDefault__3KX5O {
    width: 70%;
  }

  .QuotesSlideshow_quoteBlock__ocDZ3 {
    height: 280px;
  }
  
  .QuotesSlideshow_quoteContainer__3gaee {
    height: 55%;
  }

  .QuotesSlideshow_authorName__3FP0U {
    font-size: 10px;
    line-height: 10px;
  }

  .QuotesSlideshow_authorRoles__1zq8V {
    font-size: 10px;
    line-height: 10px;
  }

  .QuotesSlideshow_authorImage__1D3L4 {
    width: 50px;
    height: 50px;
  }

  .QuotesSlideshow_quoteText__284MN {
    width: 80%;
    font-size: 13px;
  }

  .QuotesSlideshow_slideComponent__zOGMV, .QuotesSlideshow_slideContainer__1FSCI {
    height: 280px;
  }
}

@media only screen and (max-width: 550px) {
  .QuotesSlideshow_quoteBlock__ocDZ3 {
    height: 300px;
  }
  
  .QuotesSlideshow_quoteContainer__3gaee {
    height: 60%;
  }

  .QuotesSlideshow_slideComponent__zOGMV, .QuotesSlideshow_slideContainer__1FSCI {
    height: 300px;
  }
}

@media only screen and (max-width: 500px) {
  .QuotesSlideshow_quoteBlock__ocDZ3 {
    width: 70%;
    height: 350px;
  }

  .QuotesSlideshow_quoteBlockDefault__3KX5O {
    width: 60%;
  }

  .QuotesSlideshow_slideComponent__zOGMV, .QuotesSlideshow_slideContainer__1FSCI {
    height: 350px;
  }
    
  .QuotesSlideshow_quoteContainer__3gaee {
    height: 62%;
  }
}

@media only screen and (max-width: 450px) {
  .QuotesSlideshow_quoteBlock__ocDZ3 {
    height: 400px;
  }

  .QuotesSlideshow_quoteContainer__3gaee {
    height: 68%;
  }

  .QuotesSlideshow_quoteText__284MN {
    text-align: center;
  }

  .QuotesSlideshow_slideComponent__zOGMV, .QuotesSlideshow_slideContainer__1FSCI {
    height: 400px;
  }
}

@media only screen and (max-width: 350px) {
  .QuotesSlideshow_quoteBlock__ocDZ3 {
    height: 440px;
  }

  .QuotesSlideshow_slideComponent__zOGMV, .QuotesSlideshow_slideContainer__1FSCI {
    height: 440px;
  }

  .QuotesSlideshow_quoteContainer__3gaee {
    height: 69%;
  }
}

@media only screen and (max-width: 330px) {
  .QuotesSlideshow_quoteText__284MN {
    font-size: 11px;
  }
}
/*-------------------------------Join us socials------------------------------*/
/*Body block*/
.joinUs_joinUsBody__2dcoq {
  max-width: 80%;
  text-align: center;
  margin: auto;
  padding-bottom: 150px;
  margin-bottom: 200px;
}

.joinUs_gmapColour__s9S_H {
  -webkit-filter: none;
          filter: none;
}

/*Google Map screenshot image*/
.joinUs_googleMap__1b7N3 {
  width: 50%;
  height: 350px;
  padding: 0px;
  float: left;
  object-fit: cover;
}

/*Right side block*/
.joinUs_joinUsRight__3j9xg {
  width: 50%;
  padding: 0px 0% 5% 0%;
  float: right;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/*Top Heading*/
.joinUs_header__qUX4O {
  font-family: "Montserrat", sans-serif;
  font-size: 30pt;
  margin-top: 50px;
  text-transform: uppercase;
  text-align: center;
}

/*Heading of sections*/
.joinUs_section__3XsCl {
  font-family: "Montserrat", sans-serif;
  font-size: 33pt;
  padding-top: 100px;
  text-transform: uppercase;
  text-align: center;
}

/*Container for buttons*/
.joinUs_joinButtons__1i77p {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 1rem;
  margin-top: 45px;
}

/*Social links*/
.joinUs_joinLink__KDElN {
  justify-self: center;
  width: 60px;
  height: 60px;
  padding: 4px;
}

.joinUs_joinLink__KDElN img {
  width: 100%;
  height: 100%;
}

/* Loading sign */
#joinUs_joinLoading__2cMb4 {
  color: var(--yellow);
  margin: 20% auto auto auto;
}

/*-------------------------------How to section------------------------------*/

/* Headings in how to join */
.joinUs_descriptionHowto__3YPny {
  font-family: "Montserrat", sans-serif;
  font-size: 13pt;
  text-align: justify;
  color: var(--general-text);
  width: 80%;
  margin: 0 auto;
}

/* Body in how to join */
.joinUs_descriptionHowto__3YPny p {
  color: var(--general-text);
}

/* Links in how to join */
.joinUs_link__3DJSy,
.joinUs_link__3DJSy:hover {
  color: var(--red);
  text-decoration: underline;
}

.joinUs_link__3DJSy:visited {
  color: var(--dark-red);
  text-decoration: underline;
}

/*---------------Slideshow-------------------*/

.joinUs_carousel__lK3-A {
  text-align: center;
  margin: 50px auto;
  width: 90%;
  position: relative;
}

/* Portfolios grid */
.joinUs_whatTo__33apt {
  max-width: 75%;
  margin: auto;
  margin-top: 50px;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 2rem;
  color: var(--general-text);
}

.joinUs_portfolioBox__2AWb0 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
}

.joinUs_portfolioBox__2AWb0:before {
  content: "";
  padding-top: 100%;
}

#joinUs_careers__H09Kw {
  background-color: #ffdfb4;
}

#joinUs_competitions__1jvs1 {
  background-color: #a1dbcb;
}

/* #events {
  background-color: #b4c9e8;
} */

#joinUs_externals__2z-Zl {
  background-color: #ffbfaf;
}

#joinUs_hr__3N8Iq {
  background-color: #b7ecbc;
}

#joinUs_it__20nxK {
  background-color: #B4C9E8;
}

#joinUs_marketing__2nbd5 {
  background-color: #dcbff7;
}

#joinUs_media__17Dv6 {
  background-color: #c0f5ff;
}

#joinUs_publications__2Ra0Z {
  background-color: #FFC2E4;
}

.joinUs_desc__3oLsp {
  font-size: 12pt;
  margin-right: 5%;
}

/*-------------------------------FAQ Section------------------------------*/
.joinUs_accordion__1f40p {
  max-width: 80%;
  margin: auto;
  padding-bottom: 100px;
  /* background-color: #aad9c2; */
}

.joinUs_accordionHeading__3HPlJ {
  font-size: 14pt;
  font-family: "Montserrat", sans-serif;
  color: var(--general-text);
}

.joinUs_accordionDetails__23KDf {
  font-family: "Montserrat", sans-serif;
  font-size: 13pt;
  color: var(--black);
}

/*-------------------------------Join us ipad------------------------------*/
/*- Ipad Pro 12.9 Horizontal -*/
@media only screen and (max-width: 1366px) {
  .joinUs_whatTo__33apt {
    max-width: 75%;
  }

  .joinUs_desc__3oLsp {
    font-size: 12pt;
    max-height: 190px;
    max-width: 400px;
  }
}

@media only screen and (max-width: 1200px) {
  .joinUs_centre__2mJ_j {
    font-size: 40px;
    margin: 0px;
  }

  .joinUs_whatTo__33apt {
    max-width: 85%;
  }

  .joinUs_desc__3oLsp {
    font-size: 11pt;
  }
}

/* Ipad pro horizontal -*/
@media only screen and (max-width: 1112px) {
  .joinUs_whatTo__33apt {
    max-width: 75%;
  }
}

/*- Ipad horizontal -*/
@media only screen and (max-width: 1024px) {
  .joinUs_desc__3oLsp {
    font-size: 9pt;
    max-height: 190px;
    max-width: 400px;
  }
}

/*-------------------------------Join us mobile------------------------------*/
@media only screen and (max-width: 900px) {
  .joinUs_joinUsRight__3j9xg {
    float: none;
    width: 100%;
    height: 180px;
    margin-top: 0px;
    margin-bottom: 30px;
    padding: 0px;
  }

  .joinUs_googleMap__1b7N3 {
    float: none;
    width: 100%;
    margin-bottom: 0px;
    padding-left: 0px;
  }

  .joinUs_joinUsBody__2dcoq {
    max-width: 100%;
    padding-bottom: 0px;
    padding-top: 0px;
    margin-bottom: 0px;
  }

  .joinUs_joinButtons__1i77p {
    margin: 0 auto;
  }

  .joinUs_joinLink__KDElN {
    margin: auto;
    height: 40px;
    width: 40px;
  }

  .joinUs_centre__2mJ_j {
    font-size: 22px;
  }

  a.joinUs_lastBtn__j5tKv {
    margin-right: 0px;
  }

  .joinUs_descriptionHowto__3YPny {
    padding-top: 0px;
    font-size: 11pt;
    width: 80%;
    margin: 0 auto;
  }

  .joinUs_descriptionHowto__3YPny p {
    font-size: 10pt;
  }

  .joinUs_whatTo__33apt {
    margin-top: 40px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }

  .joinUs_desc__3oLsp {
    font-size: 11pt;
    max-height: 300px;
    max-width: 1000px;
  }

  .joinUs_accordion__1f40p {
    padding-top: 0px;
  }

  .joinUs_accordionHeading__3HPlJ {
    font-size: 11pt;
  }

  .joinUs_accordionDetails__23KDf {
    font-size: 10pt;
  }
}

/*- Ipad pro vertical -*/
@media only screen and (max-width: 834px) {
  .joinUs_desc__3oLsp {
    font-size: 11pt;
    max-height: 190px;
    max-width: 400px;
  }
}

@media only screen and (max-width: 700px) {
  .joinUs_whatTo__33apt {
    max-width: 60%;
    grid-template-columns: 1fr;
    grid-template-columns: repeat(auto-fill, 1fr);
  }
}

/*- ipad half screen horizontal -*/
@media only screen and (max-width: 550px) {
  .joinUs_desc__3oLsp {
    font-size: 9pt;
    max-height: 190px;
    max-width: 400px;
  }
  
  .joinUs_quoteContainer__212KZ {
    height: 57%;
  }
}

@media only screen and (max-width: 450px) {
  .joinUs_whatTo__33apt {
    max-width: 80%;
  }

  .joinUs_slide__1k9AI {
    height: 320px;
  }

  .joinUs_quoteContainer__212KZ {
    height: 55%;
  }
}

/*-------------------------------Join us very small screen------------------------------*/
@media only screen and (max-width: 355px) {
  .joinUs_joinLink__KDElN {
    display: block;
  }

  .joinUs_joinUsRight__3j9xg {
    height: 200px;
  }

  .joinUs_header__qUX4O {
    font-size: 22pt;
  }

  .joinUs_section__3XsCl {
    font-size: 24pt;
  }

  .joinUs_googleMap__1b7N3 {
    padding-left: 0px;
  }
}

/*- Iphone SE vertical -*/
@media only screen and (max-width: 320px) {
  .joinUs_section__3XsCl {
    font-size: 24pt;
  }

  .joinUs_desc__3oLsp {
    font-size: 9pt;
    max-height: 190px;
    max-width: 400px;
  }
}

/* Dark mode */
@media (prefers-color-scheme:dark) {
  .joinUs_gmapColour__s9S_H {
    -webkit-filter: invert(100%) hue-rotate(180deg) brightness(95%) contrast(90%);
            filter: invert(100%) hue-rotate(180deg) brightness(95%) contrast(90%);
  }

  .joinUs_descriptionHowto__3YPny {
    color: white; 
  }

  .joinUs_descriptionHowto__3YPny p {
    color: white;
  }

  .joinUs_accordionHeading__3HPlJ {
    color: white; 
  }

  .joinUs_accordionDetails__23KDf {
    color: white; 
  }
}
/* ---------------------------Sponsor page header-----------------------------*/
/*Subheading "Thank you message" & contact*/
p.sponsors_subheader__6mrDc {
  font-family: "Montserrat", sans-serif;
  font-size: 12pt;
  color: var(--general-text);
  text-align: center;
  margin-top: 50px;
}

/*----------------------------Sponsor page sponsors---------------------------*/
/*Sponsor tier names*/
h2.sponsors_subsponsor__EOGiU {
  font-family: "Montserrat", sans-serif;
  font-size: 33pt;
  text-transform: uppercase;
  margin-top: 100px;
  margin-bottom: 45px;
  text-align: center;
}

/*Sponsor logos*/
#sponsors_majorContainer__1-PxK {
  margin-bottom: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

#sponsors_majorContainer__1-PxK img {
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

#sponsors_majorContainer__1-PxK img:hover { /* IE 9 */
  -webkit-transform: scale(1.5); /* Safari 3-8 */
  transform: scale(1.09);
}

/*Sponsor contact*/
a.sponsors_subheader__6mrDc {
  font-family: "Montserrat", sans-serif;
  color: var(--general-text);
  font-weight: 500;
  text-decoration: underline;
}

#sponsors_sponsorsBody__15BJL {
  max-width: 80%;
  margin: 0 auto;
  margin-bottom: 10%;
  margin-top: 5%;
}

.sponsors_logo__1p6XI{
  width: 100%;
  width: 220px;
  max-height: 160px;
  padding: 15px;
  object-fit: contain;
}

/*--- Dark mode ---*/
@media (prefers-color-scheme:dark) {
  p.sponsors_subheader__6mrDc {
    color: white;
  }

  a.sponsors_subheader__6mrDc {
    color: white;
  }
}

/*--- Mobile responsiveness ---*/
@media only screen and (max-width: 950px) {
  .sponsors_logo__1p6XI {
    width: 250px;
  }
}

@media only screen and (max-width: 715px) {
  .sponsors_logo__1p6XI {
    max-width: 220px;
    max-height: 160px;
  }
}

@media only screen and (max-width: 630px) {
  #sponsors_sponsorsBody__15BJL {
    max-width: 85%;
  }
}

@media only screen and (max-width: 400px) {
  h2.sponsors_subsponsor__EOGiU {
    font-size: 20pt;
  }

  p.sponsors_subheader__6mrDc {
    font-size: 13pt;
  }

  a.sponsors_subheader__6mrDc {
    font-size: 11pt;
  }
}

/*----------------------------Sponsor page modal---------------------------*/

.sponsors_sponsorType__1Lq0w {
  color: var(--yellow);
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  text-align: left;
  margin-top: 60px;
  margin-bottom: 20px;
}

.sponsors_sponsorDescription__2HPml {
  color: #313638;
  font-family: "Montserrat", sans-serif;
  font-size: 12pt;
  margin-top: 2%;
  text-align: justify;
  margin-bottom: 100px;
}

.sponsors_paper__2XITl {
  background-color: white;
  border-radius: 2;
  padding: 1vw 2vw 2vw 2vw;
  width: 100%;
  height: auto;
  vertical-align: middle;
  padding-bottom: 50px;
  max-height: 80%;
  overflow-y: scroll;
}

.sponsors_sponsorImage__2T0Va {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-height: 120px;
  max-width: 300px;
  text-align: center;
}

.sponsors_link__1IlPC,
.sponsors_sponsorLink__3TAar, 
.sponsors_link__1IlPC:hover, 
.sponsors_sponsorLink__3TAar:hover  {
  text-decoration: underline;
  color: var(--red);
}

.sponsors_link__1IlPC:visited, .sponsors_sponsorLink__3TAar:visited {
  color: var(--dark-red);
}

.sponsors_sponsorLink__3TAar {
  display: inline;
  font-family: "Montserrat", sans-serif;
  font-size: 12pt;
  margin: 0;
  margin-bottom: 0px;
  text-align: justify;
}

.sponsors_sponsorWebsite__2te_V {
  color: #313638;
  font-family: "Montserrat", sans-serif;
  font-size: 12pt;
  font-weight: bold;
  margin-right: 1%;
  margin-bottom: 0px;
  text-align: justify;
  display: inline-block;
}

.sponsors_inLine__xotVv p {
  display: inline-block;
}

.sponsors_modalContainer__1mju9 {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sponsors_modal__1BQrW {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sponsors_textBlock__RoN3S {
  padding: 3rem;
  overflow-wrap: break-word;
}

.sponsors_closeBtn__3ko4E {
  float: right;
  overflow: hidden;
  position: fixed;
}

.sponsors_closeSymbol__2of5C {
  color: #313638;
  text-align: right;
}

/*--- Dark mode ---*/
@media (prefers-color-scheme:dark) {
  .sponsors_sponsorDescription__2HPml {
    color: white;
  }

  .sponsors_paper__2XITl {
    background-color:var(--dark-grey);
  }

  .sponsors_closeSymbol__2of5C {
    color: white;
  }

  .sponsors_sponsorWebsite__2te_V {
    color: white;
  }
}

/*--- Mobile responsiveness ---*/ 
@media only screen and (max-width: 400px) {
  .sponsors_sponsorImage__2T0Va {
    max-width: 200px;
  }
}

@media only screen and (max-width: 280px) {
  .sponsors_sponsorImage__2T0Va {
    max-width: 220px;
  }
}

/*-------------------------------Opportunities Preview-----------------------------*/

.opportunities_head__3XPSW {
  font-family: "Montserrat", sans-serif;
  font-size: 11pt;
  font-weight: 400;
  color: var(--general-text);
  text-align: justify;
}

.opportunities_link__2Bqz3 {
  color: var(--general-text);
  font-weight: 500;
}

.opportunities_link__2Bqz3:hover {
  text-decoration: underline;
}

.opportunities_oppBody__1uND0 {
  max-width: 70%;
  margin: 0 auto;
  margin-bottom: 10%;
  margin-top: 4%;
}

.opportunities_oppLookout__Iu5zC {
  text-align: center;
  font-family: "Montserrat", sans-serif;
  margin: 0 auto;
  padding: 100px 0;
  max-width: 70%;
}

.opportunities_lookoutSize__2-s0T {
  font-size: 12pt;
}

.opportunities_oppGridContainer__cPTaJ {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  grid-gap: 50px;
  margin-top: 50px;
}

.opportunities_oppGridItems__29BEd {
  display: block;
  position: relative;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.opportunities_oppDesc__2myrA {
  margin: 50px 30px;
  padding: 0;
}

.opportunities_oppTypeAndLocation__2EDgZ {
  color: var(--yellow);
  font-family: "Montserrat", sans-serif;
  letter-spacing: 1pt;
  margin: 0;
  padding: 0;
  padding-bottom: 5px;
  text-align: center;
  font-weight: 500;
  font-size: 10pt;
  font-weight: 600;
}

.opportunities_oppSummary__3Szkj {
  color: var(--general-text);
  letter-spacing: 0.05em;
  font-size: 9pt;
  font-family: "Montserrat", sans-serif;
  padding: 0;
  padding-bottom: 5px;
  margin-top: 10px;
  text-align: justify;
}

.opportunities_oppImg__1jalL {
  object-fit: scale-down;
  display: block;
  margin: 0 auto;
  width: 250px;
  height: 150px;
  -webkit-transform: translate(0%, 20%);
          transform: translate(0%, 20%);
  background-color: white;
  box-shadow: 5px 0px 5px 0 rgba(0, 0, 0, 0.3);
  padding: 10px;
}

.opportunities_imageContainer__3srZ9 {
  background-color: black;
}

.opportunities_jobPosition__1225F {
  color: #313638;
  font-size: 14pt;
  font-family: "Playfair Display", serif;
  font-weight: 500;
  text-align: center;
  margin-bottom: 10px;
}

/*--- Hover animations ---*/
.opportunities_darkOverlay__a7z1c {
  width: 100%;
  background-color: black;
  opacity: 0;
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 1;
  transition: opacity 0.5s ease;
}

.opportunities_oppGridContainer__cPTaJ .opportunities_darkOverlay__a7z1c:hover {
  opacity: 0.04;
}

.opportunities_oppGridContainer__cPTaJ a:hover .opportunities_oppImg__1jalL { /* IE 9 */
  -webkit-transform: scale(1.05); /* Safari 3-8 */
  transform: scale(1.05) translate(0%, 20%);
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;  
}

/*--- Dark mode ---*/
@media (prefers-color-scheme:dark) {
  .opportunities_jobPosition__1225F,
  .opportunities_oppBody__1uND0, .opportunities_oppSummary__3Szkj {
    color: white;
  }
  .opportunities_oppGridItems__29BEd {
    background-color: var(--dark-slate-grey);
  }
  .opportunities_link__2Bqz3:hover {
    color: white;
  }
  .opportunities_darkOverlay__a7z1c {
    background-color: white;
  }

}

/*---------------------------Loading Sign--------------------------*/
#opportunities_oppLoading__3YiWZ {
  color: var(--yellow);
  margin-top: 5%;
  margin-bottom: 5%;
}

#opportunities_oppLoadingContainer__ADRPd {
  display: flex;
  justify-content: center;
}

/*-------------------------------Responsive mobile design------------------------------*/
@media only screen and (max-width: 750px) {
  .opportunities_head__3XPSW,
  .opportunities_lookoutSize__2-s0T {
    font-size: 10pt;
  }

  .opportunities_oppLookout__Iu5zC {
    padding: 50px 0;
  }
}

@media only screen and (max-width: 640px) {
  .opportunities_oppBody__1uND0,
  .opportunities_oppLookout__Iu5zC {
    max-width: 90%;
  }

  .opportunities_head__3XPSW {
    max-width: 80%;
    margin: 0 auto;
  }

  .opportunities_oppGridContainer__cPTaJ {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
}

@media only screen and (max-width: 400px) {
  .opportunities_oppBody__1uND0 {
    max-width: 100%;
  }

  .opportunities_oppGridContainer__cPTaJ {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }

  .opportunities_lookoutSize__2-s0T {
    font-size: 9pt;
  }
}

@media only screen and (max-width: 350px) {
  .opportunities_oppImg__1jalL {
    width: 250px;
    height: 150px;
  }
}

/*------------------------------Contact us body-----------------------------*/

/*Form*/
.contactUs_form__3V_ox {
  text-align: center;
  margin: 50px auto 0 auto;
  max-width: 80%;
}

.contactUs_inquiry__2PVLk {
  padding-bottom: 50px;
}

/*Form left*/
.contactUs_formLeft__3czTs {
  display: inline-block;
  width: 40%;
  height: 100%;
}

.contactUs_formLeft__3czTs form {
  float: left;
  text-align: left;
  width: 100%;
}

.contactUs_formLeft__3czTs textarea {
  border-style: none;
  border-bottom: solid;
  border-color: var(--grey);
  border-width: 2px;
  width: 100%;
}

.contactUs_formInput__1rD58 {
  padding: 5px;
  padding-left: 0px;

  border-style: none;
  border-bottom: solid;
  border-color: var(--general-text);
  border-width: 2px;

  border-radius: 0px;
  width: 100%;
  margin-bottom: 15px;
  padding-bottom: 10px;
  font-size: 12pt;
  font-family: "Montserrat", sans-serif;
}

.contactUs_submitButton__1ZjXQ {
  color: #ffffff;
  background-color: #e85f5c;
  text-align: center;
  margin: 0px 0px 30px 0px;
  padding: 12px 10px;
  width: 120px;
  border: none;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  float: left;
  font-weight: 500;
  font-size: 12pt;
  transition: 0.3s;
}

.contactUs_submitButton__1ZjXQ:hover {
  background-color: var(--dark-red);
}

form input.contactUs_button__SHEOw:hover {
  background-color: var(--yellow);
  color: var(--white);
}

/*Form image*/
.contactUs_formRight__2DBbH {
  width: 50%;
  overflow: hidden;
  float: right;
}

.contactUs_centre__10-7G {
  object-fit: contain;
}

/*Style for the Emails is below*/
.contactUs_contactTeam__pyX67 {
  margin-top: 100px;
}

.contactUs_newEmails__31Rny div.contactUs_dot__1J2G8 {
  height: 50px;
  width: 50px;
  background-color: var(--red);
  margin-bottom: 25px;
}

.contactUs_newEmails__31Rny img {
  height: 50px;
  width: 50px;
  margin-bottom: 25px;
}

.contactUs_newEmails__31Rny h3 {
  color: var(--yellow);
  font-family: "Playfair Display", serif;
  font-size: 20pt;
  font-weight: normal;
  letter-spacing: 2.5px;
}

.contactUs_newEmails__31Rny a {
  color: var(--general-text);
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 10pt;
  padding-bottom: 5px;
}

.contactUs_newEmails__31Rny a:hover {
  text-decoration: underline;
}

.contactUs_newEmails__31Rny div.contactUs_col2__2POIS {
  width: 57%;
  margin-left: 26%;
  margin-right: auto;
}

.contactUs_newEmails__31Rny div.contactUs_profile2__4zBte {
  display: inline-block;
  width: 50%;
  padding: 30px;
  float: none;
  overflow: none;
}

.contactUs_newEmails__31Rny div.contactUs_col3__3nTtu {
  width: 80%;
  height: auto;
  margin-left: 12%;
  margin-right: auto;
  margin-bottom: 100px;
}

.contactUs_newEmails__31Rny div.contactUs_profile3__G9v6b {
  display: inline-block;
  width: 33%;
  padding: 30px;
  float: none;
  height: 230px;
  overflow: none;
}

.contactUs_lineBreak__tssNz {
  visibility: hidden;
}

/*--- Dark mode ---*/
@media (prefers-color-scheme:dark) {
  .contactUs_formInput__1rD58 {
    background-color: #323235;
    border-color: white;
    color: white;
  }
  
  .contactUs_formLeft__3czTs textarea {
    border-color: white;
    color: white;
  }
  
  .contactUs_formLeft__3czTs {
    border-color: white;
  }

  .contactUs_newEmails__31Rny div.contactUs_profile2__4zBte {
    color: white;
  }
  .contactUs_newEmails__31Rny div.contactUs_profile3__G9v6b {
    color: white;
  }

}

/*-------------------------------Join us: different screen sizes ------------------------------*/
@media only screen and (max-width: 1200px) {
  .contactUs_newEmails__31Rny div.contactUs_profile2__4zBte {
    padding: 25px;
  }

  .contactUs_newEmails__31Rny div.contactUs_profile3__G9v6b {
    padding: 25px;
  }
}

@media only screen and (max-width: 1000px) {
  .contactUs_formRight__2DBbH {
    display: inline;
    display: initial;
    float: none;
  }

  .contactUs_centre__10-7G {
    height: 100%;
  }

  .contactUs_formLeft__3czTs {
    display: inline;
    display: initial;
    float: none;
  }

  .contactUs_formLeft__3czTs form {
    margin-bottom: 50px;
  }

  .contactUs_newEmails__31Rny div.contactUs_col2__2POIS {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .contactUs_newEmails__31Rny div.contactUs_col3__3nTtu {
    width: 90%;
    margin-left: auto;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .contactUs_newEmails__31Rny div.contactUs_profile3__G9v6b {
    display: inline-block;
    width: 50%;
    padding: 30px;
    float: none;
    overflow-wrap: break-word;
  }

  .contactUs_newEmails__31Rny div.contactUs_profile2__4zBte {
    overflow-wrap: break-word;
  }

  hr.contactUs_solid__q2dC4 {
    border-top: 2px solid #bbb;
  }

  .contactUs_lineBreak__tssNz {
    visibility: visible;
  }

  .contactUs_newEmails__31Rny img {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .contactUs_newEmails__31Rny h3,
  a {
    text-align: center;
  }
}

@media only screen and (max-width: 910px) {
  .contactUs_centre__10-7G {
    width: 100%;
    height: 400px;
  }
}

@media only screen and (max-width: 650px) {
  .contactUs_centre__10-7G {
    height: 300px;
  }

  .contactUs_newEmails__31Rny div.contactUs_profile3__G9v6b {
    width: 100%;
  }

  .contactUs_newEmails__31Rny div.contactUs_profile2__4zBte {
    width: 100%;
  }

  .contactUs_newEmails__31Rny div.contactUs_col2__2POIS {
    width: 90%;
  }

  .contactUs_newEmails__31Rny a {
    font-size: 16px;
  }

  .contactUs_form__3V_ox h2,
  .contactUs_contactTeam__pyX67 {
    font-size: 30px;
  }

  .contactUs_form__3V_ox h3 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 300px) {
  .contactUs_centre__10-7G {
    height: 200px;
  }

  .contactUs_form__3V_ox {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
}

/*---------------------------Search filtering and loading--------------------------*/
#content_timeline__2-vBT span {
  font-size: 11pt;
}

#content_contentTimeLine__2VpL4 {
  margin-top: 7%;
  width: 40%;
}

/*-------------------------------Size bar and image grid-----------------------------*/
#content_parent__3213p {
  width: 100%;
  height: 100%;
  display: table;
}

/*-------------------------------Categories-----------------------------*/
.content_initiatives__1DfcL {
  text-align: center;
  margin-top: 50px;
}

.content_initiatives__1DfcL * {
  display: block;
}

#content_contentLoading__Xnvp5 {
  color: var(--yellow);
  margin-top: 10%;
  margin-bottom: 10%;
}

#content_contentLoadingContainer__hKpGc {
  display: flex;
  justify-content: center;
}

/*-------------------------------Image grid gallery-----------------------------*/
ol {
  padding: 0px;
}

.content_grid__py0jI {
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
  justify-content: center;
  width: 100%;
  padding: 30px 10px 0px 10px;
}

.content_grid__py0jI li {
  margin: 10px;
}

a.content_darken__1DAj3 {
  width: 21.9vw;
  height: 21.9vw;
  display: inline-block;
  position: relative;
  text-align: center;
  transition: opacity 0.5s ease;
}

a.content_darken__1DAj3:hover:after {
  opacity: 1;
}

a.content_darken__1DAj3:after {
  content: "\A";
  width: 100%;
  height: 100%;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  z-index: 1;
}

a.content_darken__1DAj3 img {
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

a.content_darken__1DAj3 div {
  font-family: "Montserrat";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: rgba(255, 255, 255, 0);
  z-index: 2;
  transition: opacity 0.5s ease;
}

a.content_darken__1DAj3:hover div {
  color: rgba(255, 255, 255, 1);
}

@media only screen and (max-width: 520px) {
  .content_side__2RKzc {
    font-size: 16pt;
  }

  a.content_darken__1DAj3 {
    width: 24.8vw;
    height: 24.8vw;
  }

  a.content_darken__1DAj3 img {
    width: 25vw;
  }
}

#content_emptyMessage__3Xnds {
  padding-top: 40px;
}

@media only screen and (max-width: 450px) {
  .content_initiatives__1DfcL label {
    width: 80%;
  }
}
@media only screen and (max-width: 300px) {
  a.content_darken__1DAj3 {
    width: 36.9vw;
    height: 36.9vw;
  }

  a.content_darken__1DAj3 img {
    width: 37vw;
  }

  .content_initiatives__1DfcL label {
    width: 100%;
  }
}

#Timeline_teams__1cVWK {
  width: 60%;
}

#Timeline_events__1BYnT {
  width: 85%;
}

#Timeline_marketingArchive__6KCv6 {
  width: 60%;
}

#Timeline_teams__1cVWK,
#Timeline_events__1BYnT,
#Timeline_marketingArchive__6KCv6 {
  margin: 0 auto;
  margin-bottom: 2%;
}

@media screen and (max-width: 650px) {
  #Timeline_events__1BYnT {
    width: 85%;
  }
}

/*---------------------------General--------------------------*/
#team_pageContainer__Uj0Ia {
  margin-bottom: 100px;
}

/*---------------------------Search filtering and loading--------------------------*/

#team_timeline__35OWY span {
  font-size: 11pt;
}

#team_teamLoading__126vv {
  color: var(--yellow);
  margin-top: 10%;
  margin-bottom: 5%;
}

#team_teamLoadingContainer__5WU0d {
  display: flex;
  justify-content: center;
}

#team_teamTimeLine__3gQDO {
  margin-top: 7%;
  width: 60%;
}

/*---------------------------Executive team profiles--------------------------*/
/*Executive team section*/
.team_allExecsSection__1KVP7 {
  align-content: center;
  justify-content: center;
}

.team_execRow__14EsZ {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}

.team_execCol__3yYIn {
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  flex: 1 1;
}

/*Subtitle*/
h2.team_teamHeading__b9NJL {
  margin-top: 100px;
}

h2#team_topheading__1NN6j {
  margin-top: 50px;
}

/*Executive profile images*/
.team_profileCrop__ilNmF {
  border-radius: 50%;
  height: 250px;
  width: 250px;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  object-fit: contain;
  overflow: hidden;
  position: relative;
}

.team_profileImg__2-2gq {
  background-size: cover;
  display: block;
  height: 100%;
  margin: 0 auto;
  width: auto;
}

/*Transform executive profile images*/
.team_exec2021Img__1jJh0 {
  -webkit-transform: translate(-3%, -13%);
          transform: translate(-3%, -13%);
  width: 105%;
  height: auto;
}

.team_gabImg__ccA7H {
  -webkit-transform: translate(0%, 0);
          transform: translate(0%, 0);
}

.team_vivwImg__IiD4E {
  height: auto;
  -webkit-transform: translate(-5%, -10%);
          transform: translate(-5%, -10%);
  width: 120%;
}

.team_alisonImg__2vkaE {
  -webkit-transform: translate(-17%, 0);
          transform: translate(-17%, 0);
}

.team_eliImg__19jCP {
  -webkit-transform: translate(-7%, 0%);
          transform: translate(-7%, 0%);
}

.team_felImg__17Hnn {
  -webkit-transform: translate(-12%, 0);
          transform: translate(-12%, 0);
}

/*Executive profile block*/
.team_profile__1i3L1 {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  position: relative;
}

/*Executive profile details*/
.team_profileDetails__1TLU_ {
  align-content: center;
  justify-content: space-evenly;
  line-height: 13pt;
  margin: 25px 0px 70px 0px;
  text-align: center;
}

/*Executive social media links*/
.team_iconBar__3Jolf {
  align-content: center;
}
.team_icon__1Ipr1 {
  border: 0;
  height: 25px;
  width: 25px;
  margin: 0 auto;
  margin-left: 10px;
  margin-right: 10px;
  align-self: center;
}

/*Executive names*/
.team_profileTextName__3RPKj {
  align-content: center;
  color: var(--yellow);
  font-family: "Playfair Display", serif;
  font-size: 2vw;
  font-weight: 500;
  letter-spacing: 0.1vw;
  position: relative;
  text-align: center;
}

/*Executive positions*/
.team_profileTextPosition__2PSFv {
  align-content: center;
  font-family: "Montserrat", sans-serif;
  font-size: 1.3vw;
  font-weight: 500;
  padding-top: 10pt;
  position: relative;
  text-align: center;
}

/*Executive degrees*/
.team_profileDegree__1-Sad {
  font-family: "Montserrat", sans-serif;
  font-size: 1.3vw;
  position: relative;
  white-space: normal;
  white-space: initial;
  word-wrap: break-word;
  text-align: center;
}

/*---------------------------Subcommittee team profiles--------------------------*/
.team_subcomSection__2rJx3 {
  align-content: center;
  justify-content: center;
  display: grid;
}

/*Subcommittee team section*/
.team_subcomType__2Ob9Y {
  font-family: "Montserrat", sans-serif;
  color: var(--grey);
  text-align: center;
  margin-top: 6vw;
  margin-bottom: 2vw;
  font-size: 30px;
  font-weight: 500;
}

/*Subcommittee names*/
.team_subcomProfileName__3PzqN {
  font-family: "Montserrat", sans-serif;
  color: var(--general-text);
  font-size: 12pt;
  position: relative;
  font-weight: 500;
  margin: 0;
  padding: 0;
  text-align: left;
  flex-shrink: 2;
}

/*Subcommittee degrees*/
.team_subcomProfileDegree__3eWke {
  font-family: "Montserrat", sans-serif;
  font-size: 12pt;
  position: relative;
  word-wrap: break-word;
  text-align: left;
  margin: 0;
  padding: 0;
}

.team_subcomRow__xInpH {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  width: 100%;
}

.team_subcomCol__1Qacc {
  align-items: flex-start;
  margin-right: 5%;
  flex: 1 1;
}

.team_subcomLongerCol__1Wr7e {
  align-items: flex-start;
  flex: 2 1;
}

.team_profile__1i3L1 {
  margin: 15px;
  padding: 0;
}

/*--------------------------Dark mode-----------------------------*/ 
@media (prefers-color-scheme: dark) {
  .team_profileTextPosition__2PSFv {
    color: white;
  }

  .team_profileDegree__1-Sad {
    color: white;
  }

  .team_subcomProfileName__3PzqN {
    color:white;
  }

  .team_subcomProfileDegree__3eWke {
    color:white;
  }

  .team_subcomType__2Ob9Y {
    color: white;
  }
}

/*---------------------------Mobile resizing--------------------------*/
@media screen and (max-width: 800px) {
  .team_teamHeading__b9NJL {
    font-size: 24px;
  }

  .team_subcomType__2Ob9Y,
  .team_profileTextName__3RPKj {
    font-size: 20px;
  }

  .team_profileTextPosition__2PSFv {
    font-size: 18px;
  }

  .team_profileDegree__1-Sad {
    font-size: 16px;
  }

  .team_execRow__14EsZ {
    flex-direction: column;
  }

  .team_teamHeading__b9NJL,
  .team_subcomSection__2rJx3,
  .team_subcomType__2Ob9Y {
    width: 90%;
    margin: 0 auto;
  }

  .team_subcomRow__xInpH {
    display: inline-grid;
    width: 100%;
    grid-template-columns: 160px auto;
    grid-gap: 15px;
    align-content: start;
  }

  .team_subcomProfileDegree__3eWke {
    font-size: 11pt;
    overflow-wrap: break-word;
    word-break: break-word;
    -webkit-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
  }

  .team_subcomProfileName__3PzqN {
    width: 140%;
    font-size: 11pt;
    overflow-wrap: break-word;
    word-break: break-word;
    -webkit-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
  }

  .team_profileCrop__ilNmF {
    width: 200px;
    height: 200px;
  }
}

@media screen and (max-width: 600px) {
  .team_subcomRow__xInpH {
    display: inline-grid;
    width: 100%;
    grid-template-columns: 90px auto;
    grid-gap: 15px;
    align-content: start;
  }
}

/*----------------------------------Scroll Up Button Container------------------------------------*/
.ScrollUpBtn_scrollUp__2eNHD {
  background-color: var(--red);
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 6em;
  right: -50px;
  border-radius: 100%;
  border: none;
  color: white;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}

/*----------------------------------Scroll Up Button Animations------------------------------------*/
.ScrollUpBtn_showBtn__2eF3M {
  -webkit-animation: ScrollUpBtn_showSlide__2QbbL 0.5s forwards;
  animation: ScrollUpBtn_showSlide__2QbbL 0.5s forwards;
}

.ScrollUpBtn_hideBtn__2mMVd {
  -webkit-animation: ScrollUpBtn_hideSlide__2zvc7 1s forwards;
  animation: ScrollUpBtn_hideSlide__2zvc7 1s forwards;
}

@media (hover: hover) {
  .ScrollUpBtn_scrollUp__2eNHD:hover {
    background-color: var(--dark-red);
  }
}

@-webkit-keyframes ScrollUpBtn_showSlide__2QbbL {
  100% {
    right: 1.9em;
  }
}

@keyframes ScrollUpBtn_showSlide__2QbbL {
  100% {
    right: 1.9em;
  }
}

@-webkit-keyframes ScrollUpBtn_hideSlide__2zvc7 {
  100% {
    right: -80px;
  }
}

@keyframes ScrollUpBtn_hideSlide__2zvc7 {
  100% {
    right: -80px;
  }
}

/*----------------------------------Mobile responsiveness------------------------------------*/
@media only screen and (max-width: 450px) {
  .ScrollUpBtn_scrollUp__2eNHD {
    bottom: 6em;
  }
}

/*-------------------------------Blog post Categories-----------------------------*/
.blog_blogCategories__1axnc {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  width: 95%;
  margin: 0 auto;
}

.blog_tooltipTitle__3RfVP {
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
  margin: 10px 0;
  text-transform: uppercase;
}

.blog_tooltipDescription__3pyeZ {
  font-size: 11px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

.blog_blogGallery__3FsG5 {
  margin-top: 50px;
}

/*---------------------------Loading Sign--------------------------*/

#blog_blogsLoading__kg89m {
  color: var(--yellow);
  margin-top: 10%;
  margin-bottom: 10%;
}

#blog_blogLoadingContainer__1xLsL {
  display: flex;
  justify-content: center;
}

/*-------------------------------Blog post Preview-----------------------------*/
/*Preview Image and Hover*/
.blog_blogPreview__3LueS {
  padding: 30px;
}

.blog_previewContainer__2sUb1 {
  width: 35.807vw;
  overflow: hidden;
}

.blog_previewPic__3KXSh {
  width: 35.807vw;
  height: 22.786vw;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

.blog_blogPost__10rjx table:hover .blog_previewPic__3KXSh{ /* IE 9 */
  -webkit-transform: scale(1.05); /* Safari 3-8 */
  transform: scale(1.05);
}

.blog_blogPost__10rjx {
  margin: 5vw 0;
  transition: 0.2s;
}

.blog_darkOverlay__3a0Fu {
  width: 100%;
  background-color: black;
  opacity: 0;
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 1;
  transition: opacity 0.5s ease;
}

.blog_blogPost__10rjx .blog_darkOverlay__3a0Fu:hover {
  opacity: 0.04;
}

/*Author circle crop*/
.blog_authorPic__1mcj3 {
  border-radius: 50%;
  max-height: 70px;
  overflow: hidden;
  max-width: 70px;
  margin-right: 1vw;
  margin-left: 0px;
}

/*List of authors*/
.blog_authorRow__iPJ18 {
  display: grid;
  grid-template-columns: auto auto;
}

/*Author image*/
.blog_execAuthor__1va9L {
  height: auto;
  width: 120%;
  -webkit-transform: translate(-5%, -10%);
          transform: translate(-5%, -10%);
}

.blog_anonAuthor__12Wqo {
  height: auto;
  width: 100%;
}

/*Post preview*/
.blog_blogDetails___1Z3i {
  margin-bottom: 10px;
}

.blog_heading__qephT {
  color: var(--yellow);
  letter-spacing: 0.1vw;
  font-size: 11pt;
  font-family: "Montserrat", sans-serif;
  text-decoration: none;
  margin-bottom: 10px;
  font-weight: 500;
}

.blog_auth__1mNiH {
  color: var(--yellow);
  font-size: 10pt;
  font-family: "Montserrat", sans-serif;
  text-decoration: none;
  display: inline-block;
  font-weight: 500;
}

.blog_heading__qephT:hover {
  text-decoration: none;
}

.blog_subheading__2a0m_ {
  color: var(--general-text);
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  padding: 0;
  margin-bottom: 10px;
}

.blog_date__2hohd {
  color: var(--general-text);
  letter-spacing: 0.1vw;
  font-size: 7pt;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  padding: 0;
  margin: 0;
}

.blog_blogPost__10rjx table {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin: 0 auto;
  width: 70vw;
  height: 100%;
  position: relative;
}

#blog_previewRow__2tnDd {
  display: flex;
  align-items: center;
}

.blog_authorSection__2eILK {
  padding-top: 0.2vw;
  padding-bottom: 0.2vw;
  padding-right: 0.5vw;
  display: flex;
  align-items: center;
}

.blog_searchBar__3p2X0 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 3%;
}

.blog_inputSearchBar__Jr5mu {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px transparent;
  border-left: 1px transparent;
  border-right: 1px transparent;
  border-bottom: 2px solid black;
  width: 71%;
  font-family: "Montserrat", sans-serif;
  background: url("/icons/search_icon.png") center left no-repeat;
  background-position-x: 0.5%;
  background-size: auto 85%;
  padding: 0.5%;
  height: 32px;
  padding-left: 3%;
}

#blog_emptyMessage__Y85T1 {
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

/*--- Dark mode ---*/
@media (prefers-color-scheme:dark) {
  .blog_blogDetails___1Z3i {
    color: white;
  }

  .blog_inputSearchBar__Jr5mu {
    color: white;
    border-color: white;
  }

  .blog_blogPost__10rjx table {
    background-color: var(--dark-slate-grey);
  }

  .blog_darkOverlay__3a0Fu {
    background-color: white;
  }
}

/*-------------------------------Blog post Recommendation-----------------------------*/

#blog_recommendation__1GoOi {
  margin-top: 0;
  margin-bottom: 100px;
}

/*-------------------------------Blog post Responsiveness-----------------------------*/

@media screen and (max-width: 1200px) {
  .blog_blogPost__10rjx table {
    width: 80%;
  }
  
  .blog_inputSearchBar__Jr5mu {
    width: 80%;
  }

  .blog_authorName__3Tzzi {
    width: auto;
    padding-left: 5px;
  }

  .blog_authorPic__1mcj3 {
    width: 50px;
    height: 50px;
    margin: 0 1.5vw;
    object-fit: contain;
  }
}

@media screen and (max-width: 950px) {
  .blog_inputSearchBar__Jr5mu {
    padding-left: 5%;
    width: 85%;
  }

  .blog_blogPost__10rjx table {
    width: 85vw;
  }
}

@media screen and (max-width: 800px) {
  .blog_blogPreview__3LueS {
    padding: 20px;
  }

  tr {
    flex-wrap: wrap;
    justify-items: flex-start;
    align-items: flex-start;
    width: 100%;
  }

  .blog_blogPost__10rjx table,
  .blog_inputSearchBar__Jr5mu {
    width: 90%;
  }

  .blog_previewPic__3KXSh, .blog_previewContainer__2sUb1 {
    width: 100%;
    height: auto;
  }

  .blog_authorSection__2eILK {
    padding-left: 10px;
  }

  .blog_blogDetails___1Z3i .blog_date__2hohd {
    font-size: 12px;
  }
}

@media screen and (max-width: 500px) {
  .blog_authorRow__iPJ18 {
    display: grid;
    grid-template-columns: auto;
    grid-row-gap: 2vw;
    row-gap: 2vw;
  }

  .blog_searchBar__3p2X0 {
    padding-top: 8%;
  }

  .blog_inputSearchBar__Jr5mu {
    width: 90%;
    padding-left: 7%;
  }
  
  .blog_blogPost__10rjx table {
    width: 90vw;
  }
}

@media screen and (max-width: 380px) {
  .blog_authorSection__2eILK {
    display: flex;
    align-items: center;
    padding-left: 0px;
    padding-bottom: 10px;
  }

  .blog_inputSearchBar__Jr5mu {
    padding-left: 9%;
  }
}

/*------------------------------Upcoming Events--------------------------------*/
.events_eventsBody__2pBDx {
  max-width: 80%;
  margin: 0 auto;
  margin-bottom: 10%;
}

.events_eventsBody__2pBDx h2 {
  margin-top: 100px;
}

.events_upcomingEventsContainer__ipMvG {
  display: -ms-grid;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 20px;
}

.events_previewContainer__2WrRx {
  position: relative;  
}

.events_darkOverlay__A2Tlj {
  width: 100%;
  background-color: black;
  opacity: 0;
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 1;
  transition: opacity 0.5s ease;
}

.events_previewContainer__2WrRx .events_darkOverlay__A2Tlj:hover {
  opacity: 0.04;
}

.events_previewContainer__2WrRx .events_upcomingEventCover__3kQx9 {
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

.events_previewContainer__2WrRx:hover .events_upcomingEventCover__3kQx9 { /* IE 9 */
  -webkit-transform: scale(1.05); /* Safari 3-8 */
  transform: scale(1.05);
}

.events_previewContainer__2WrRx:hover {
  background-color: none;
}

a:hover {
  color: #313638;
}

.events_regButton__GLLm5 {
  padding: 8px 10px;
  border: none;
  background-color: #e85f5c;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11pt;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #ffffff;
  transition: 0.3s;
}

.events_regButton__GLLm5:hover {
  background-color: #bd3f3d;
}

.events_lookout__2UkyW {
  margin-top: 30px;
  text-align: center;
}

/*------------------------------Past Events--------------------------------*/
.events_pastEventsContainer__1fX4x {
  margin: 0% 2.5%;
}

.events_pastEventsContainer__1fX4x h3 {
  margin-top: 30px;
}

.events_gridContainer__254V- {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 20px;
}

.events_pastEvent__DBZzS {
  position: relative;
  width: 100%;
  height: 100%;
}

.events_darkOverlay__A2Tlj {
  width: 100%;
  height: 100%;
  background-color: black;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.events_pastEvent__DBZzS:hover .events_darkOverlay__A2Tlj {
  opacity: 0.15;
}

.events_eventImages__3mI99 {
  max-width: 100%;
  object-fit: cover;
  display: flex;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

/*--- Dark mode ---*/
@media (prefers-color-scheme:dark) {
  h3 {
    color: white;
  }
  .events_darkOverlay__A2Tlj {
    background-color: #ffffff;
    color: #ffffff;
  }

  .events_lookout__2UkyW {
    color: #ffffff;
  }
}

/*---------------------------Loading Sign--------------------------*/
#events_eventsLoading__3K6rz {
  color: var(--yellow);
  margin-top: 5%;
  margin-bottom: 3%;
}

.events_eventsLoadingContainer__1Cf_0 {
  display: flex;
  justify-content: center;
}

/*------------------------------Responsive Mobile Design--------------------------------*/
@media only screen and (max-width: 1200px) {
  .events_upcomingEventsContainer__ipMvG {
    grid-template-columns: 50vw 50vw;
    justify-content: center;
  }
}

@media only screen and (max-width: 750px) {
  .events_upcomingEventsContainer__ipMvG {
    grid-template-columns: 100vw;
  }

  .events_regButton__GLLm5 {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .events_eventsBody__2pBDx {
    max-width: 90%;
  }
}

@media only screen and (max-width: 350px) {
  .events_eventsBody__2pBDx {
    max-width: 100%;
  }
}

.AddToCal_componentStyles__32ku2 {
  text-align: center;
}

.AddToCal_link__Yg1vx {
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
  letter-spacing: 0.1vw;
  font-size: 9pt;
  text-decoration: none;
  display: block;
  text-align: centre;
  padding: 6px;
  border-bottom: 1px #ffffff solid;
}

.AddToCal_link__Yg1vx:last-child {
  border-bottom: none;
}

.AddToCal_button__3Z1Ve {
  background-color: #ffffff;
  border: 2px solid var(--yellow);
  color: var(--yellow);
  padding: 6px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.05em;
  transition: 0.3s;
}

.AddToCal_button__3Z1Ve:hover {
  background: #fff7eb;
}

.AddToCal_dropItems__3C_TS {
  padding: 6px;
  border-top: none;
  width: 143.5px;
  background-color: var(--yellow);
  margin: 0 auto;
  z-index: 2;
  position: absolute;
}

/*--- Dark mode ---*/
@media (prefers-color-scheme:dark) {
  .AddToCal_button__3Z1Ve {
    background-color: var(--dark-slate-grey);
  }
  .AddToCal_button__3Z1Ve:hover {
    background-color:#61605b;
  }
}
/* Date and facebookLink button */
.event-recap_eventDetails__1Us6- {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.5%;
}

.event-recap_date__2tKXF {
  font-size: 11pt;
}

.event-recap_buttonSpan__10-Gi {
  display: flex;
  align-items: center;
}

.event-recap_facebookLinkButton__39L9A {
  border-radius: 0;
  color: #e85f5c;
  font-family: "Montserrat", sans-serif;
  font-size: 11pt;
  font-weight: 500;
  padding: 7px;
  background-color: #ffffff;
  border: solid #dd380d 1px;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  letter-spacing: 2px;
}

.event-recap_facebookLinkButton__39L9A:hover {
  background: #fff7f7;
  border: solid #e85f5c 1px;
  border-radius: 0;
  text-decoration: none;
}

/* Embedded YouTube Video */
.event-recap_embeddedVideo__Q2eBO {
  align-content: center;
}

.event-recap_responsiveIframe__HgG1i {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
}
.event-recap_responsiveIframe__HgG1i iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.event-recap_iframeWrapper__2zfO- {
  width: 90%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3vw;
}

/* Description */
.event-recap_description__2MtuC {
  text-align: justify;
  margin-top: 1em;
}

/* Cover image */
.event-recap_eventCoverImage__1HHts {
  width: 90%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3vw;
  margin-bottom: 3vw;
  display: block;
}

/* Event Image Gallery Wrapper */
.event-recap_imageGalleryWrapper__hjdTj {
  width: 90%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3vw;
  display: block;
}

/* ------ Dark Mode --------- */
@media (prefers-color-scheme:dark) {
  .event-recap_description__2MtuC {
    color: white;
  }
  .event-recap_date__2tKXF {
    color: var(--yellow);
  }

  .event-recap_facebookLinkButton__39L9A {
    background-color: transparent;
  }
  .event-recap_facebookLinkButton__39L9A:hover {
    background-color:#6b4e4d;
  }

}

/* react-awesome-slider (Image Gallery) style override */
.event-recap_awssld__content__3kyd0 > img,
.event-recap_awssld__content__3kyd0 > video {
  object-fit: scale-down !important;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000000;
}

.event-recap_awssld__bullets__3Ovtw {
  position: relative;
  bottom: -16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.event-recap_awssld__bullets__3Ovtw .event-recap_awssld__bullets--active__32BR2 {
  -webkit-transform: scale(1.25);
          transform: scale(1.25);
  background: var(--control-bullet-active-color);
}

.event-recap_awssld__bullets__3Ovtw button {
  padding: 0;
  display: block;
  width: 16px;
  height: 16px;
  margin: 5px;
  border-radius: 50%;
  background: var(--control-bullet-color);
  text-indent: -9999px;
  overflow: hidden;
  cursor: pointer;
  border: none;
  transition: background-color 0.175s ease-out,
    -webkit-transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45);
  transition: transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45),
    background-color 0.175s ease-out;
  transition: transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45),
    background-color 0.175s ease-out,
    -webkit-transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45);
}

.event-recap_awssld__3QyHb {
  --organic-arrow-thickness: 4px;
  --organic-arrow-height: 40px;
  --slider-height-percentage: 60%;
  --loader-bar-color: var(--yellow);
  --loader-bar-height: 6px;
  --control-button-width: 10%;
  --control-button-height: 25%;
  --control-button-opacity: 0.5;
  --control-button-hover-opacity: 0.75;
  --control-button-background: transparent;
  --transition-bezier: cubic-bezier(0.5, 0.075, 0.25, 0.95);
  --slider-transition-duration: 200ms;
  --organic-arrow-color: #6a6a6a;
  --organic-arrow-border-radius: 0;
  --control-bullet-color: #6a6a6a;
  --control-bullet-active-color: #6a6a6a;
  --content-background-color: #2f2f2f;
}

/* Event Resources Accordion */
.event-recap_accordionWrapper__Ln20r {
  margin-top: 36pt;
  margin-bottom: 5vw;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 2px 5px 0 rgba(0, 0, 0, 0.19);
}

#event-recap_eventResources__28trF {
  text-transform: uppercase;
  color: #e85f5c;
  flex-basis: 33.33%;
  flex-shrink: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

#event-recap_resourcesDescription__1Xejp {
  font-family: "Montserrat", sans-serif;
  color: #4f4f4f;
}

/*-------------------------------Event Recap Page-----------------------------*/
#event-recap_eventContainer__tYzlK {
  width: 70%;
  margin: auto;
  white-space: pre-wrap;
  margin-bottom: 100px;
}

#event-recap_eventLoadingContainer__A-ZnE {
  display: flex;
  justify-content: center;
}

#event-recap_eventTitle__MJu_g {
  padding-top: 50px;
}

#event-recap_eventLoading__LlRaL {
  color: var(--yellow);
  margin-top: 10%;
  margin-bottom: 10%;
}

/*-------------------------------Mobile Responsiveness-----------------------------*/

@media only screen and (max-width: 880px) {
  #event-recap_eventResources__28trF {
    flex-basis: 100%;
  }

  #event-recap_resourcesDescription__1Xejp {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  #event-recap_eventContainer__tYzlK {
    width: 80%;
  }

  #event-recap_eventContainer__tYzlK h2 {
    font-size: 16.5pt;
  }

  #event-recap_heading__3E2j1 {
    font-size: 40px;
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 700px) {
  #event-recap_heading__3E2j1 {
    font-size: 25pt;
  }

  #event-recap_introDescription__1Krpu {
    width: 55%;
  }
}

@media only screen and (max-width: 500px) {
  .event-recap_eventDetails__1Us6- {
    display: block;
    text-align: center;
  }

  .event-recap_eventDetails__1Us6- span {
    display: block;
    padding: 5px;
  }

  #event-recap_introDescription__1Krpu {
    width: 95%;
  }

  #event-recap_introDescription__1Krpu p {
    text-align: justify;
  }

  #event-recap_introDescription__1Krpu h2 {
    text-align: center;
  }
}

@media only screen and (max-width: 450px) {
  #event-recap_overview__3qO4E {
    text-align: justify;
    white-space: pre-wrap;
  }
}

/*-------------------------------Podcast Previews-----------------------------*/
.Podcast_logoContainer__35gSg {
  background-color: #fff7ef;
  border-radius: 50%;
  height: 280px;
  width: 280px;
  margin: 50px 50px 50px 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.Podcast_podcastLogo__34D4t {
  height: 280px;
  width: 280px;
  object-fit: contain;
  padding-bottom: 35px;
}

#Podcast_podcastIntroduction__2LHJI {
  margin: 0 auto;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#Podcast_introDescription__FV6Ao {
  width: 40%;
  display: inline-block;
}

#Podcast_introDescription__FV6Ao h2 {
  text-align: left;
}

#Podcast_episodes__3S-9H {
  width: 80%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  padding: 0;
  padding: initial;
  margin-right: auto;
  margin-left: auto;
  margin-top: 0;
  margin-bottom: 50px;
}

#Podcast_heading__o64lZ {
  text-transform: none;
  text-transform: initial;
  letter-spacing: 1.5px;
  font-size: 50px;
  font-weight: 500;
}

.Podcast_previewContainer__29UKY .Podcast_episodeCover__3Syyt {
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

.Podcast_previewContainer__29UKY:hover .Podcast_episodeCover__3Syyt { /* IE 9 */
  -webkit-transform: scale(1.05); /* Safari 3-8 */
  transform: scale(1.05);
}

.Podcast_previewContainer__29UKY:hover {
  background-color: none;
}

a:hover {
  color: #313638;
}

/*-------------------------------Podcast Episode Page-----------------------------*/
.Podcast_episodesContainer__pB8sM {
  width: 110%;
}

.Podcast_previews__lR1Xj {
  width: 113%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 320px));
  padding: 0;
  padding: initial;
  margin-right: auto;
  margin-left: auto;
  margin-top: 0;
  margin-bottom: 50px;
  margin-left: -17px;
}

.Podcast_subHeading__3tl_n {
  font-weight: 500;
  font-size: 30px;
  color: #676767;
  margin: 30px 0 0 0;
}

.Podcast_shareButtons__1XRwV {
  margin-top: 15px;
}

#Podcast_episodeContainer__hzndr {
  width: 70%;
  margin: auto;
  padding: 25px;
}

#Podcast_episodeDate__35_z_ {
  text-align: center;
}

#Podcast_podcastLoadingContainer__2Fbun {
  display: flex;
  justify-content: center;
}

#Podcast_podcastLoading__1c-XZ {
  color: var(--yellow);
  margin-top: 10%;
  margin-bottom: 10%;
}

.Podcast_sourceLogos__pnsEz {
  margin-left: 1vw;
}

.Podcast_platformLogos__1uWl6 {
  margin-right: 1vw;
}

.Podcast_platforms__kNcCp {
  margin-top: 30px;
  text-align: right;
}

/* Dark mode */
@media (prefers-color-scheme:dark) {
  #Podcast_introDescription__FV6Ao p {
    color: #fff;
  }

  a:hover {
    color: #E85F5C;
    ;
  }

  .Podcast_subHeading__3tl_n {
    color: white;
  }
  
  #Podcast_episodeDate__35_z_ {
    color: white;
  }

  #Podcast_overview__3dxqT {
    color: white;
  }
}

/*-------------------------------Mobile Responsiveness-----------------------------*/
@media only screen and (max-width: 1285px) {
  .Podcast_previews__lR1Xj {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    width: 105%;
    margin-left: -13px;
  }

  .Podcast_subHeading__3tl_n {
    text-align: center;
  }

  .Podcast_shareButtons__1XRwV {
    text-align: center;
  }
}

@media only screen and (max-width: 1200px) {
  #Podcast_episodes__3S-9H {
    grid-template-columns: repeat(auto-fill, minmax(320px, 350px));
  }
}

@media only screen and (max-width: 1000px) {
  .Podcast_previews__lR1Xj {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
}

@media only screen and (max-width: 880px) {
  #Podcast_episodes__3S-9H {
    width: 100%;
    margin-bottom: 30px;
  }

  .Podcast_logoContainer__35gSg {
    margin: 50px 50px 50px 0px;
  }

  #Podcast_introDescription__FV6Ao {
    width: 48%;
  }
}

@media only screen and (max-width: 800px) {
  #Podcast_episodeContainer__hzndr {
    width: 80%;
  }

  #Podcast_episodeContainer__hzndr h2 {
    font-size: 16.5pt;
  }

  #Podcast_heading__o64lZ {
    font-size: 40px;
    margin-bottom: 0px;
  }

  .Podcast_subHeading__3tl_n {
    font-size: 16.5pt;
  }

  .Podcast_logoContainer__35gSg {
    height: 250px;
    width: 250px;
  }

  .Podcast_podcastLogo__34D4t {
    height: 250px;
    width: 250px;
  }
}

@media only screen and (max-width: 700px) {
  #Podcast_episodes__3S-9H {
    display: flex;
    width: 65%;
    flex-flow: row wrap;
    margin-bottom: 20px;
  }

  .Podcast_previewContainer__29UKY .Podcast_episodeCover__3Syyt {
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  .Podcast_logoContainer__35gSg {
    height: 210px;
    width: 210px;
  }

  .Podcast_podcastLogo__34D4t {
    height: 210px;
    width: 210px;
  }

  #Podcast_heading__o64lZ {
    font-size: 25pt;
  }

  #Podcast_introDescription__FV6Ao {
    width: 55%;
  }
}

@media only screen and (max-width: 600px) {
  .Podcast_logoContainer__35gSg {
    height: 180px;
    width: 180px;
  }

  .Podcast_podcastLogo__34D4t {
    height: 180px;
    width: 180px;
  }

  #Podcast_podcastIntroduction__2LHJI {
    width: 90%;
  }

  .Podcast_platformLogos__1uWl6 {
    margin-right: 2vw;
  }

  #Podcast_episodes__3S-9H {
    width: 95%;
  }
}

@media only screen and (max-width: 500px) {
  #Podcast_podcastIntroduction__2LHJI {
    flex-direction: column;
    margin-bottom: 20px;
  }

  #Podcast_introDescription__FV6Ao {
    width: 95%;
  }

  #Podcast_introDescription__FV6Ao p {
    text-align: justify;
  }

  #Podcast_introDescription__FV6Ao h2 {
    text-align: center;
  }

  .Podcast_logoContainer__35gSg {
    margin-right: 0px;
    height: 200px;
    width: 200px;
  }

  #Podcast_platformContainer__27wp8 {
    text-align: center;
    display: flex;
    justify-content: space-around;
  }

  .Podcast_podcastLogo__34D4t {
    height: 200px;
    width: 200px;
  }

  .Podcast_platformLogos__1uWl6 {
    margin-right: 0;
    width: 35px;
    height: 35px;
  }
}

@media only screen and (max-width: 450px) {
  .Podcast_previews__lR1Xj {
    width: 119%;
    margin-left: -25px;
  }
  .Podcast_platforms__kNcCp {
    text-align: center;
    display: flex;
    justify-content: space-around;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .Podcast_platforms__kNcCp img {
    margin-left: 0vw;
  }

  #Podcast_overview__3dxqT {
    text-align: justify;
  }
}

@media only screen and (max-width: 350px) {
  #Podcast_episodeContainer__hzndr {
    padding: 25px 0px;
  }
}
.ShareBtn_shareBtn__E9PM_ {
  margin: 0px 5px;
}

#NotFound_container__fZijB {
  height: auto;
  overflow: visible;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  caret-color: transparent;
  padding-top: 5vh;
  padding-bottom: 15vh;
}

#NotFound_image404__3f28K {
  margin: 0 auto;
  object-fit: contain;
  width: 30vw;
}

#NotFound_homeBtn__3JOgX {
  background-color: var(--red);
  color: var(--white);
  text-transform: uppercase;
  padding: 20px 25px;
  font-size: 18pt;
  letter-spacing: 1.5px;
  margin-top: 20px;
  text-decoration: none;
}

#NotFound_homeBtn__3JOgX:hover {
  background-color: var(--dark-red);
}

@media only screen and (max-width: 500px) {
  #NotFound_container__fZijB {
    width: 80%;
    margin: 0 auto;
    padding-top: 20vh;
    padding-bottom: 30vh;
  }

  h2 {
    font-size: 22pt;
  }

  p {
    font-size: 12pt;
  }

  #NotFound_image404__3f28K {
    margin: 0 auto;
    object-fit: contain;
    width: 55vw;
  }

  #NotFound_homeBtn__3JOgX {
    font-size: 16pt;
  }
}

/*--- General ---*/
#OurStory_container__243v7 {
  margin-bottom: 100px;
}

/*--- Our Story ---*/
h2.OurStory_oppSubheading__1iuMU {
  font-family: "Montserrat", sans-serif;
  font-size: 33pt;
  text-transform: uppercase;
  margin-top: 100px;
  text-align: center;
}

h2#OurStory_topHeading__2y0aw {
  margin-top: 50px;
}

/*--- Our Mission ---*/
.OurStory_ourMissionSection__2UCeg {
  display: flex;
  width: 80%;
  margin: 0 auto;
  flex-direction: row-reverse;
}

.OurStory_ourMissionContent__1p-p1 {
  font-size: 12pt;
  color: var(--black);
}

.OurStory_ourMissionImg__1-eSa {
  width: 800px;
  padding-left: 25px;
}

.OurStory_ourMissionImg__1-eSa img {
  width: 100%;
}

/*--- Our Mascot ---*/
.OurStory_ourMascotSection__3UtWv {
  display: flex;
  width: 80%;
  margin: 0 auto;
}

.OurStory_ourMascotContent__1hXA7 {
  font-family: "Montserrat", sans-serif;
  font-size: 12pt;
  text-align: justify;
  color: var(--black);
}

.OurStory_ourMascotImg__11ycV {
  padding-top: 1px;
  padding-right: 25px;
}

.OurStory_ourMascotImg__11ycV img {
  width: 100%;
}

/*--- Link to see Willow's cute photoshoot ---*/
.OurStory_link__3huAR, .OurStory_link__3huAR:hover {
  text-decoration: underline;
  font-weight: 500;
  color: var(--red);
}

.OurStory_link__3huAR:visited {
  text-decoration: underline;
  color: var(--dark-red);
}

/*--- Our History ---*/
.OurStory_ourHistorySection__226JW {
  display: flex;
  width: 80%;
  margin: 0 auto;
}

.OurStory_ourHistoryContent__13xys {
  padding-bottom: 25px;
  font-size: 12pt;
  color: var(--black);
}

.OurStory_ourStory__e5I_9 {
  color: var(--black);
  font-family: "Montserrat", sans-serif;
  text-align: justify;
}

/*--- Dark mode ---*/
@media (prefers-color-scheme: dark) {
  .OurStory_ourMissionContent__1p-p1 {
    color: var(--white);
  }
  
  .OurStory_ourMascotContent__1hXA7 {
    color: var(--white);
  }

  .OurStory_ourHistoryContent__13xys {
    color: var(--white);
  }
}

/*--- Our Story big tablet version ---*/
@media only screen and (max-width: 1200px) {
  .OurStory_ourMissionContent__1p-p1 {
    font-size: 11pt;
  }

  .OurStory_ourMissionImg__1-eSa {
    width: 820px;
  }

  .OurStory_ourMascotContent__1hXA7 {
    font-size: 11pt;
  }

  .OurStory_ourHistoryContent__13xys {
    font-size: 11pt;
  }
}

/*--- Our Story tablet version ---*/
@media only screen and (max-width: 1000px) {
  .OurStory_ourStory__e5I_9 {
    padding-top: 20px;
  }

  .OurStory_ourMissionSection__2UCeg {
    flex-direction: column;
    width: 85%;
  }
  .OurStory_ourMascotSection__3UtWv {
    flex-direction: column;
    width: 85%;
  }

  .OurStory_ourMissionImg__1-eSa {
    display: flex;
    margin: 0 auto;
    padding-right: 20px;
    width: 250px;
    padding-top: 15px;
    flex-direction: column-reverse;
  }

  .OurStory_ourMascotImg__11ycV {
    display: flex;
    margin: 0 auto;
    padding-left: 3px;
    padding-right: 2px;
    width: 350px;
    padding-top: 15px;
  }
}

/*--- Our Story mobile version ---*/
@media only screen and (max-width: 540px) {
  h2.OurStory_oppSubheading__1iuMU {
    margin-top: 10%;
    font-size: 23pt;
    text-transform: uppercase;
  }

  .OurStory_ourMissionContent__1p-p1 {
    font-size: 10pt;
  }

  .OurStory_ourMascotContent__1hXA7 {
    font-size: 10pt;
  }

  .OurStory_ourHistoryContent__13xys {
    font-size: 10pt;
  }

  .OurStory_ourMissionImg__1-eSa {
    display: flex;
    margin: 0 auto;
    width: 200px;
    flex-direction: column-reverse;
  }

  .OurStory_ourMascotImg__11ycV {
    display: flex;
    margin: 0 auto;
    width: 270px;
  }
}

@media only screen and (max-width: 414px) {
  h2.OurStory_oppSubheading__1iuMU {
    margin-top: 10%;
    font-size: 23pt;
    text-transform: uppercase;
  }

  .OurStory_ourMissionContent__1p-p1 {
    font-size: 10pt;
  }

  .OurStory_ourMascotContent__1hXA7 {
    font-size: 10pt;
  }

  .OurStory_ourHistoryContent__13xys {
    font-size: 10pt;
  }

  .OurStory_ourMissionImg__1-eSa {
    display: flex;
    margin: 0 auto;
    width: 180px;
    padding-top: 15px;
    flex-direction: column-reverse;
  }

  .OurStory_ourMascotImg__11ycV {
    display: flex;
    margin: 0 auto;
    width: 280px;
    padding-top: 15px;
  }
}

@media only screen and (max-width: 375px) {
  .OurStory_ourMissionImg__1-eSa {
    display: flex;
    margin: 0 auto;
    width: 180px;
    padding-top: 15px;
    flex-direction: column-reverse;
  }

  .OurStory_ourMascotImg__11ycV {
    display: flex;
    margin: 0 auto;
    width: 250px;
    padding-top: 15px;
  }
}

@media only screen and (max-width: 360px) {
  .OurStory_ourMissionImg__1-eSa {
    display: flex;
    margin: 0 auto;
    width: 200px;
  }

  .OurStory_ourMascotImg__11ycV {
    display: flex;
    margin: 0 auto;
    width: 250px;
  }
}

/*--- Our Story small mobile version ---*/
@media only screen and (max-width: 320px) {
  .OurStory_ourMissionImg__1-eSa {
    display: flex;
    margin: 0 auto;
    width: 170px;
  }

  .OurStory_ourMascotImg__11ycV {
    display: flex;
    margin: 0 auto;
    width: 200px;
  }
}

/*--- Timeline Content ---*/
.OurStoryTimeline_timelineContent__168Dv {
  font-family: "Montserrat", sans-serif;
  font-size: 12pt;
  text-align: justify;
  color: var(--general-text);
  padding-top: 20px;
  margin: auto 0;
}

.OurStoryTimeline_year__2s83X {
  font-weight: 600;
}

.OurStoryTimeline_yearContent__2i9nj {
  font-family: "Montserrat", sans-serif;
  font-size: 12pt;
  text-align: justify;
  color: var(--yellow);
  margin: 0 auto;
}

.OurStoryTimeline_timelineSeparator__1xGPj {
  color: var(--light-grey);
}

/*--- Our Story dark mode ---*/
@media (prefers-color-scheme: dark) {
  .OurStoryTimeline_yearContent__2i9nj {
    color: var(--white);
  }

  .OurStoryTimeline_timelineContent__168Dv {
    color: var(--white);
  }
}

/*--- Our Story big tablet version ---*/
@media only screen and (max-width: 1200px) {
  .OurStoryTimeline_timelineContent__168Dv {
    font-size: 11pt;
    padding-top: 10px;
  }

  .OurStoryTimeline_yearContent__2i9nj {
    font-size: 11pt;
    padding-left: 47px;
  }
}

/*--- Our Story tablet version ---*/
@media only screen and (max-width: 1005px) {
  .OurStoryTimeline_timelineContent__168Dv {
    padding-top: 40px;
  }
}

/*--- Our Story mobile version ---*/
@media only screen and (max-width: 540px) {
  .OurStoryTimeline_yearContent__2i9nj {
    padding-left: 50px;
  }
}

@media only screen and (max-width: 414px) {
  .OurStoryTimeline_yearContent__2i9nj {
    font-size: 10pt;
    padding-left: 0px;
  }

  .OurStoryTimeline_timelineContent__168Dv {
    font-size: 10pt;
  }
}

/*--- Loading Sign ---*/

#videos_videoLoadingContainer___6-XS {
  display: flex;
  justify-content: center;
}

#videos_videoLoading__15j0t {
  color: var(--yellow);
  margin-top: 10%;
  margin-bottom: 10%;
}

/*--- Videos ---*/
.videos_videosBody__3Ktti {
  max-width: 85%;
  margin: 0 auto;
  margin-bottom: 100px;
}

h1.videos_vidSubheading__1iHLK {
  font-family: "Montserrat", sans-serif;
  font-size: 30pt;
  text-transform: uppercase;
  margin-top: 5%;
  margin-bottom: 0.25%;
  text-align: center;
}

/*--- Youtube Logo ---*/
.videos_youtubeImg__Hod8a {
  object-fit: scale-down;
  display: block;
  margin: 0 auto;
  margin-top: 2%;
  margin-bottom: 2%;
  width: 250px;
  height: 150px;
}

.videos_youtubeImg__Hod8a img {
  object-fit: cover;
}

.videos_desc__34gBE {
  color: var(--general-text);
  font-family: "Montserrat", sans-serif;
  max-width: 50%;
  font-size: 16px;
  margin: 0 auto;
  text-align: center;
}

.videos_subscribeText__12nu_ {
  margin: 0 auto;
  margin-top: 4%;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 30px;
  color: var(--yellow);
}

.videos_subscribe__1Bezg {
  margin: 0 auto;
  margin-top: 1%;
  margin-bottom: 5%;
  text-align: center;
}

/*--- Youtube Video ---*/

.videos_videoContainer__1I2p- {
  max-width: 80%;
  margin-left: 9%;
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(355px, 1fr));
  grid-gap: 1em;
}

.videos_iframeWrapper__1NuRs {
  width: 80%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3vw;
  border-bottom: 1px solid rgb(151, 151, 151);
  position: relative;
}

.videos_responsiveIframe__1qSoP {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
}

.videos_responsiveIframe__1qSoP iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.videos_embeddedVideo__3xXQf {
  align-content: center;
}

.videos_videoName__1NM7c {
  color: #313638;
  font-family: "Playfair Display", serif;
  font-size: 25px;
  padding: 0;
  margin: 0 auto;
  margin-top: 3%;
  margin-bottom: 1%;
}

.videos_videoDate__3yFiK {
  color: var(--yellow);
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 400;
  padding: 0;
  margin: 0 auto;
  padding-bottom: 3%;
}

/*--- More From WIT ---*/
.videos_subHeading__2r_rF {
  font-weight: 500;
  font-size: 30px;
  color: #676767;
  margin: 30px 0 15px 10%;
}

.videos_videoDescription__UQI2t {
  height: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  position: relative;
}

img.videos_videoImages__3Uash {
  max-width: 100%;
  object-fit: cover;
}

.videos_moreName__3bStX {
  color: #313638;
  font-family: "Playfair Display", serif;
  font-size: 16px;
  text-align: center;
  padding: 0;
  margin-top: 3%;
  margin-bottom: 1%;
}

.videos_moreDate__1g68P {
  color: var(--yellow);
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  padding: 0;
  margin: 0 auto;
  padding-bottom: 3%;
}


/*--- Hover animations ---*/
.videos_darkOverlay__1_EnZ {
  width: 100%;
  background-color: black;
  opacity: 0;
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 1;
  transition: opacity 0.5s ease;
}

.videos_videoContainer__1I2p- .videos_darkOverlay__1_EnZ:hover {
  opacity: 0.04;
}

#videos_videoContainer__1I2p- .videos_darkOverlay__1_EnZ:hover {
  opacity: 0.04;
}

.videos_previewContainer__1cPHz {
  overflow: hidden;
}

.videos_videoContainer__1I2p- .videos_videoDescription__UQI2t:hover .videos_videoImages__3Uash { /* IE 9 */
  -webkit-transform: scale(1.05); /* Safari 3-8 */
  transform: scale(1.05);
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}


/*------------------------------Responsive Mobile Design--------------------------------*/
@media only screen and (max-width: 1300px) {
  .videos_youtubeImg__Hod8a {
    margin-top: 0.5%;
    width: 150px;
  }

  .videos_desc__34gBE {
    max-width: 80%;
  }

  .videos_videoContainer__1I2p- {
    margin: 0 auto;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  }
}

@media only screen and (max-width: 970px) {
  .videos_subscribeText__12nu_ {
    margin-top: 4%;
    font-size: 25px;
  }

  .videos_subHeading__2r_rF {
    font-size: 25px;
    text-align: center;
    margin: 30px 0 15px 0;
  }

  .videos_videoContainer__1I2p- {
    margin: 0 auto;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  }
}

@media only screen and (max-width: 650px) {
  h1.videos_vidSubheading__1iHLK {
    font-size: 25px;
    margin-bottom: 0.1%;
  }

  .videos_youtubeImg__Hod8a {
    margin-top: 0%;
    width: 100px;
  }

  .videos_desc__34gBE {
    max-width: 80%;
    font-size: 14px;
  }

  .videos_subscribeText__12nu_ {
    margin-top: 10%;
    margin-bottom: 10%;
    font-size: 20px;
  }

  .videos_subscribe__1Bezg {
    margin-bottom: 10%;
  }

  .videos_iframeWrapper__1NuRs {
    margin: 0 auto;
    width: 100%;
  }

  .videos_videoName__1NM7c {
    font-size: 18px;
  }

  .videos_videoDate__3yFiK {
    font-size: 14px;
  }

  .videos_videoContainer__1I2p- {
    margin: 0 auto;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    justify-items: center;
    align-items: center;
  }

  .videos_subHeading__2r_rF {
    font-size: 20px;
  }
}

@media only screen and (max-width: 415px) {
  .videos_videoContainer__1I2p- {
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  }

  .videos_videoName__1NM7c {
    font-size: 16px;
  }
}

@media only screen and (max-width: 360px) {
  .videos_videoContainer__1I2p- {
    max-width: 100%;
  }
}

/* Dark mode */
@media (prefers-color-scheme:dark) {
  .videos_desc__34gBE {
    color: #fff;
  }

  .videos_videoName__1NM7c {
    color: #fff;
  }

  .videos_moreName__3bStX {
    color: #fff;
  }

  .videos_subHeading__2r_rF {
    color: #FEB14B;
  }

  .videos_videoDescription__UQI2t {
    background: var(--dark-slate-grey);
  }
  
  .videos_darkOverlay__1_EnZ {
    background-color: white;
  }

}
/*-------------------------------Blog post content (text) -----------------------------*/
.blog-post_blogContent__30JcP {
  width: 100%;
  max-width: 950px;
  padding: 25px;
  text-align: center;
  margin: auto;
  margin-bottom: 6vw;
  word-wrap: break-word;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}

/*Blog title*/
.blog-post_blogTitle__1FVD8 {
  font-size: 34pt;
  letter-spacing: 4px;
  margin-top: 8vw;
}

/*Blog subtitle*/
.blog-post_blogSubtitle__35fcZ {
  font-size: 12pt;
  color: var(--general-text);
  text-align: center;
  margin-bottom: 2vw;
  font-style: italic;
}

/* Date and share buttons */
.blog-post_blogDetails__3CUQp {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 3vw;
}

/*Blog content*/
.blog-post_post__kOxr5 {
  margin-bottom: 8vw;
  margin: auto;
  color: var(--general-text);
  font-family: "Montserrat", sans-serif;
  text-align: justify;
}

p,
th,
td,
div {
  font-size: 12pt;
}

ol {
  -webkit-padding-start: 40px;
          padding-inline-start: 40px;
}

b {
  color: #000000;
  font-weight: bolder;
}

.blog-post_blogDetails__3CUQp .blog-post_date__2xuc1 {
  color: var(--general-text);
  font-size: 11pt;
  font-weight: 500;
}

.blog-post_postContent__3K5Of {
  color: var(--general-text);
  font-weight: 400;
}

/*Heading in post content*/
.blog-post_postHeading__102ZB {
  color: var(--general-text);
  font-size: 24pt;
}

/*Subheading in post content*/
.blog-post_postSubheading__1qEWL {
  text-decoration: underline;
}

/*Interview questions*/
.blog-post_question__pHpov, .blog-post_list__2OvAD .blog-post_question__pHpov {
  font-weight: 600;
  font-size: 12pt;
  color: var(--general-text);
}

/*Makes dot points and numbered points larger*/
.blog-post_list__2OvAD {
  color: var(--general-text);
  font-family: "Montserrat", sans-serif;
  font-size: 12pt;
  text-align: justify;
  font-weight: 400;
}

/*To exaggerate words to be bigger and bolder*/
.blog-post_superEmph__3anEw {
  font-size: 20pt;
  font-weight: bolder;
}

.blog-post_sourceLink__2BRS8 {
  text-align: center;
  width: 100%;
}

/* Link to another page*/
.blog-post_link__1y4AA {
  color: var(--red);
  text-decoration: underline;
}

.blog-post_link__1y4AA:hover {
  color: var(--yellow);
}

#blog-post_titleWooliesX__X3KcG {
  color: #28b454;
  display: inline;
}

#blog-post_wooliesX__2LeMi {
  color: #28b454;
}

#blog-post_center__1VNlX {
  text-align: center;
}

/*-------------------------------Blog post content (images and videos)-----------------------------*/
/*container for embedding youtube videos*/
.blog-post_videoWrapper__vMAvg {
  display: flex;
  justify-content: center;
}

/*for any image attachment that is centre aligned*/
.blog-post_pic__yNyg1 {
  margin: 0 auto;
  max-width: 40%;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

/*for any image attachments aligned to the right*/
img.blog-post_floatRight__UFa-1 {
  float: right;
  padding: 30px;
}

.blog-post_postContent__3K5Of a,
.blog-post_outerBox__1O4Gj a,
.blog-post_pic__yNyg1 a, .blog-post_postContent__3K5Of a:hover,
.blog-post_outerBox__1O4Gj a:hover,
.blog-post_pic__yNyg1 a:hover {
  text-decoration: underline;
  font-weight: 400;
  color: var(--red);
}

.blog-post_postContent__3K5Of a:visited,
.blog-post_outerBox__1O4Gj a:visited,
.blog-post_pic__yNyg1 a:visited {
  color: var(--dark-red);
}

.blog-post_blogContent__30JcP img {
  max-width: 100%;
  object-fit: contain;
  padding: 15px;
}

/*Shows multiple images in 2 columns*/
/* Example: blog-post-22 */
.blog-post_outerBox__1O4Gj {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  margin: 0 auto;
}

.blog-post_smallContainer__2PGR4 {
  width: 70%;
}

.blog-post_innerBox__12hZc {
  max-width: 45%;
  flex: 50% 1;
  text-align: center;
  padding: 15px;
}

.blog-post_innerBox__12hZc img {
  height: auto;
  padding: 0px;
  padding-bottom: 15px;
}

.blog-post_post__kOxr5 table {
  border-collapse: collapse;
  width: 100%;
}

.blog-post_tableContainer__2Fqj1 {
  overflow-x: auto;
}

.blog-post_post__kOxr5 th,
.blog-post_post__kOxr5 td {
  padding: 10px;
  text-align: left;
}

.blog-post_post__kOxr5 th {
  background-color: var(--yellow);
  color: white;
  font-weight: 500;
}

/*--- Dark mode ---*/
@media (prefers-color-scheme:dark) {
  .blog-post_blogDetails__3CUQp .blog-post_date__2xuc1, 
  .blog-post_postContent__3K5Of {
    color: white;
  }

  .blog-post_question__pHpov {
    color: white;
  }

  .blog-post_list__2OvAD {
    color: white;
  }

  .blog-post_superEmph__3anEw {
    color:white;
  }

  b {
    color: white;
  }

}

/*-------------------------------Author card-----------------------------*/
.blog-post_author__1PL-Z {
  margin: 0 auto;
  margin-bottom: 4vw;
  /*margin-bottom: 6vw;*/
  display: flex;
  align-items: center;
  width: 450px;
}

/*Author icon*/
.blog-post_authorLeft__1EXek {
  display: inline-block;
  width: 150px;
  height: 150px;
}

.blog-post_authorLeft__1EXek div {
  border-radius: 50%;
  height: 100%;
  overflow: hidden;
  width: 100%;
}

/*Profile pictures*/
.blog-post_authorProfile__3SPZM {
  height: auto;
  -webkit-transform: translate(-5%, -10%);
          transform: translate(-5%, -10%);
  width: 120%;
}

.blog-post_anonProfile__3pL_D {
  height: auto;
  width: 100%;
}

/*Author details*/
.blog-post_authorRight__22Pbd {
  display: inline-block;
  padding: 0px 0px 0px 30px;
  letter-spacing: 2px;
  color: var(--yellow);
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  text-align: left;
}

.blog-post_authorName__1xxpc {
  font-family: "Playfair Display", serif;
  font-size: 20pt;
  margin: 0;
  padding: 0;
}

.blog-post_position__3BvHY {
  margin-top: 5px;
  font-size: 14pt;
  color: var(--red);
}

/*--- Dark mode ---*/
@media (prefers-color-scheme:dark) {
  .blog-post_position__3BvHY {
    color: white;
  }
}


/*-------------------------------Blog mobile version-----------------------------*/
@media only screen and (max-width: 800px) {
  .blog-post_videoWrapper__vMAvg {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
  }

  .blog-post_videoWrapper__vMAvg iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 700px) {
  .blog-post_outerBox__1O4Gj {
    flex-direction: column;
  }

  .blog-post_smallContainer__2PGR4 {
    width: 100%;
    flex-direction: row;
  }
}

@media only screen and (max-width: 600px) {
  .blog-post_blogTitle__1FVD8 {
    font-size: 20pt;
  }

  .blog-post_postHeading__102ZB {
    font-size: 18pt;
  }

  .blog-post_blogSubtitle__35fcZ {
    font-size: 12pt;
  }

  p {
    font-size: 12pt;
  }

  .blog-post_author__1PL-Z {
    flex-flow: column;
    width: 100%;
  }

  .blog-post_authorRight__22Pbd {
    text-align: center;
    padding: 5% 0% 0% 0%;
  }

  .blog-post_authorName__1xxpc {
    font-size: 16.5pt;
  }

  .blog-post_position__3BvHY {
    font-size: 12pt;
  }

  .blog-post_blogContent__30JcP img {
    height: auto;
    padding: 5px;
  }
}

@media only screen and (max-width: 400px) {
  .blog-post_blogDetails__3CUQp span {
    display: block;
  }

  .blog-post_blogDetails__3CUQp span:last-child {
    padding: 10px 0px;
  }

  .blog-post_blogDetails__3CUQp {
    display: block;
  }

  .blog-post_smallContainer__2PGR4 {
    flex-direction: column;
  }

  .blog-post_blogTitle__1FVD8 {
    font-size: 16.5pt;
  }

  .blog-post_postHeading__102ZB {
    font-size: 16.5pt;
  }

  .blog-post_pic__yNyg1,
  .blog-post_innerBox__12hZc {
    max-width: 100%;
  }

  img.blog-post_floatRight__UFa-1 {
    float: none;
  }

  .blog-post_blogContent__30JcP p:not(.blog-post_date__2xuc1),
  .blog-post_blogContent__30JcP li {
    text-align: justify;
  }
}

