@import "../style.css";

/*-------------------------------Newsletter-----------------------------*/
/*Background image*/
.background {
  height: 300px;
  width: 100%;
  object-fit: cover;
}

#container {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subscribeBtn {
  position: absolute;
  right: 20%;
  text-align: center;
  width: 60%;
  bottom: 37%;
  color: #ffffff;
  background-color: #e85f5c;
  padding: 12px 10px;
  border: none;
  font-size: 2vw;
  text-transform: uppercase;
  border: none;
  letter-spacing: 3px;
  transition: 0.3s;
}

.subscribeBtn:hover {
  background-color: #bd3f3d;
}

.signUpForm span {
  color: #e85f5c;
  font-family: "Montserrat", sans-serif;
}

.signUpForm {
  display: flex;
  align-items: center;
  justify-content: center;
}

/*-------------------------------Newsletter section Mobile Design-----------------------------*/
@media only screen and (max-width: 1200px) {
  .subscribeBtn {
    font-size: 20pt;
    width: 70%;
    right: 15%;
  }
}

@media only screen and (max-width: 800px) {
  .subscribeBtn {
    font-size: 16pt;
    width: 70%;
    right: 15%;
    bottom: 38%;
  }
}

@media only screen and (max-width: 400px) {
  .subscribeBtn {
    font-size: 14pt;
    width: 80%;
    right: 10%;
  }
}

/*-------------------------------Newsletter sign up form-----------------------------*/
form input {
  margin: 15px auto;
  display: block;
  font-size: 1.2vw;
  width: 100%;

  border-style: none;
  border-bottom: solid;
  border-color: var(--general-text);
  border-width: 2px;
  padding: 5px;
  font-family: "Montserrat", sans-serif;
  width: 50%;
}

form button {
  background-color: #e85f5c;
  color: #ffffff;
  font-size: 1.3vw;
  width: 100%;
  margin: 20px auto;
  border: none;
  display: block;
  padding: 10px;
  letter-spacing: 3px;
  text-transform: uppercase;
  width: 50%;
}

.newsletterImg {
  width: 40%;
  object-fit: contain;
}

.paper {
  background-color: white;
  border-radius: 2;
  padding: 1vw 2vw 2vw 2vw;
  width: 100%;
}

.formTitle {
  color: var(--yellow);
  font-size: 2.5vw;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 3px;
  text-transform: uppercase;
  text-align: center;
}

.formDescription {
  color: #313638;
  font-family: "Montserrat", sans-serif;
  font-size: 12pt;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.msg-alert p {
  font-size: 1.3vw;
}

.closeBtn {
  float: right;
  margin: 12px;
}

.closeSymbol {
  color: #313638;
  text-align: right;
}

.subscribeError {
  color: #e85f5c;
  font-weight: 500;
}

/*--- Dark mode ---*/
@media (prefers-color-scheme:dark) {
  form input {
    background-color: #323235;
    border-color: white;
    color: white;
  }
  
  form button {
    background-color: var(--red);
    color: white;
  }
  
  .paper {
    background-color: var(--dark-grey);
  }
  
  .formDescription {
    color: white;
  }
  
  .closeSymbol {
    color: white;
  }
}

/*-------------------------------Newsletter Form Mobile Design-----------------------------*/
@media only screen and (max-width: 1000px) {
  form button {
    font-size: 12pt;
    width: 75%;
  }

  form input {
    width: 75%;
  }

  .formTitle {
    font-size: 14pt;
  }

  form input,
  .msg-alert p {
    font-size: 12pt;
  }
}

@media only screen and (max-width: 600px) {
  form input,
  .msg-alert p {
    font-size: 11pt;
  }

  form input,
  .formDescription,
  form button {
    width: 80%;
  }
}
