@import "../style.css";

/*-------------------------------Publications structure------------------------------*/
/* Subheading */
.publicationsBody h1 {
  font-family: "Montserrat", sans-serif;
}

/*Body block*/
.publicationsBody {
  max-width: 70%;
  margin: auto;
  padding-top: 100px;
}

.row {
  margin-top: 20px;
  margin-bottom: 100px;
  height: auto;
}

/*Right side block*/
.article {
  display: inline-block;
  width: 212px;
  height: 300px;
  text-align: center;
  margin-right: 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
}

/*-------------------------------Publications Article------------------------------*/

.imageContainer {
  position: absolute;
  z-index: 0;
}

.articleImg {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: relative;
}

.article .dark {
  width: 100%;
  background-color: black;
  opacity: 0;
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 1;
  transition: opacity 0.5s ease;
}

.textContainer {
  opacity: 0;
  transition: opacity 0.5s ease;
}

.article:hover > .textContainer {
  opacity: 1;
}

.article:hover .dark {
  opacity: 0.7;
}

.textContainer {
  position: absolute;
  left: 50%;
  z-index: 2;
  top: 70px;
}

.article h2 {
  left: -50%;
  text-transform: capitalize;
  text-align: center;

  font-size: 21px;
  display: block;
  position: relative;
  padding: 5px;
}

.article p {
  font-size: 15px;
  color: white;
  font-family: "Montserrat", sans-serif;
  display: block;
  text-align: center;
  float: center;
  left: -50%;
  position: relative;
}

/*read only button, change for functionality*/
.button {
  background-color: #e85f5c;
  height: 30px;
  width: 120px;
  left: -50%;
  position: relative;
  border-radius: 60px;
}

.button a {
  font-size: 10px;
  font-weight: 500px;
  color: white;
  text-transform: uppercase;
  white-space: nowrap;
  float: center;
  line-height: 30px;
  letter-spacing: 0.1vw;
}

.button a, .button:hover a {
  text-decoration: none;
  color: white;
}

.button:hover a {
  text-decoration: underline;
}
/*-------------------------------Loading Sign-----------------------------*/
#pubLoading {
  color: var(--yellow);
  margin-bottom: 100px;
}

#pubLoadingContainer {
  display: flex;
  justify-content: center;
}

/* ------------ Responsive mobile design ------------ */

@media only screen and (max-width: 850px) {
  .article {
    margin: 15px 15px;
    width: 178px;
    height: 250px;
  }

  .article .dark {
    margin: 0px 0px;
    width: 178px;
    height: 250px;
  }

  .textContainer {
    top: 45px;
  }

  .publicationsBody h1 {
    padding-left: 10px;
  }
}

@media screen and (max-width: 500px) {
  .publicationsBody h1 {
    padding-left: 12px;
  }
}