@import "../style.css";

/*-------------------------------Join us socials------------------------------*/
/*Body block*/
.joinUsBody {
  max-width: 80%;
  text-align: center;
  margin: auto;
  padding-bottom: 150px;
  margin-bottom: 200px;
}

.gmapColour {
  filter: none;
}

/*Google Map screenshot image*/
.googleMap {
  width: 50%;
  height: 350px;
  padding: 0px;
  float: left;
  object-fit: cover;
}

/*Right side block*/
.joinUsRight {
  width: 50%;
  padding: 0px 0% 5% 0%;
  float: right;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/*Top Heading*/
.header {
  font-family: "Montserrat", sans-serif;
  font-size: 30pt;
  margin-top: 50px;
  text-transform: uppercase;
  text-align: center;
}

/*Heading of sections*/
.section {
  font-family: "Montserrat", sans-serif;
  font-size: 33pt;
  padding-top: 100px;
  text-transform: uppercase;
  text-align: center;
}

/*Container for buttons*/
.joinButtons {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 1rem;
  margin-top: 45px;
}

/*Social links*/
.joinLink {
  justify-self: center;
  width: 60px;
  height: 60px;
  padding: 4px;
}

.joinLink img {
  width: 100%;
  height: 100%;
}

/* Loading sign */
#joinLoading {
  color: var(--yellow);
  margin: 20% auto auto auto;
}

/*-------------------------------How to section------------------------------*/

/* Headings in how to join */
.descriptionHowto {
  font-family: "Montserrat", sans-serif;
  font-size: 13pt;
  text-align: justify;
  color: var(--general-text);
  width: 80%;
  margin: 0 auto;
}

/* Body in how to join */
.descriptionHowto p {
  color: var(--general-text);
}

/* Links in how to join */
.link,
.link:hover {
  color: var(--red);
  text-decoration: underline;
}

.link:visited {
  color: var(--dark-red);
  text-decoration: underline;
}

/*---------------Slideshow-------------------*/

.carousel {
  text-align: center;
  margin: 50px auto;
  width: 90%;
  position: relative;
}

/* Portfolios grid */
.whatTo {
  max-width: 75%;
  margin: auto;
  margin-top: 50px;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 2rem;
  color: var(--general-text);
}

.portfolioBox {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
}

.portfolioBox:before {
  content: "";
  padding-top: 100%;
}

#careers {
  background-color: #ffdfb4;
}

#competitions {
  background-color: #a1dbcb;
}

/* #events {
  background-color: #b4c9e8;
} */

#externals {
  background-color: #ffbfaf;
}

#hr {
  background-color: #b7ecbc;
}

#it {
  background-color: #B4C9E8;
}

#marketing {
  background-color: #dcbff7;
}

#media {
  background-color: #c0f5ff;
}

#publications {
  background-color: #FFC2E4;
}

.desc {
  font-size: 12pt;
  margin-right: 5%;
}

/*-------------------------------FAQ Section------------------------------*/
.accordion {
  max-width: 80%;
  margin: auto;
  padding-bottom: 100px;
  /* background-color: #aad9c2; */
}

.accordionHeading {
  font-size: 14pt;
  font-family: "Montserrat", sans-serif;
  color: var(--general-text);
}

.accordionDetails {
  font-family: "Montserrat", sans-serif;
  font-size: 13pt;
  color: var(--black);
}

/*-------------------------------Join us ipad------------------------------*/
/*- Ipad Pro 12.9 Horizontal -*/
@media only screen and (max-width: 1366px) {
  .whatTo {
    max-width: 75%;
  }

  .desc {
    font-size: 12pt;
    max-height: 190px;
    max-width: 400px;
  }
}

@media only screen and (max-width: 1200px) {
  .centre {
    font-size: 40px;
    margin: 0px;
  }

  .whatTo {
    max-width: 85%;
  }

  .desc {
    font-size: 11pt;
  }
}

/* Ipad pro horizontal -*/
@media only screen and (max-width: 1112px) {
  .whatTo {
    max-width: 75%;
  }
}

/*- Ipad horizontal -*/
@media only screen and (max-width: 1024px) {
  .desc {
    font-size: 9pt;
    max-height: 190px;
    max-width: 400px;
  }
}

/*-------------------------------Join us mobile------------------------------*/
@media only screen and (max-width: 900px) {
  .joinUsRight {
    float: none;
    width: 100%;
    height: 180px;
    margin-top: 0px;
    margin-bottom: 30px;
    padding: 0px;
  }

  .googleMap {
    float: none;
    width: 100%;
    margin-bottom: 0px;
    padding-left: 0px;
  }

  .joinUsBody {
    max-width: 100%;
    padding-bottom: 0px;
    padding-top: 0px;
    margin-bottom: 0px;
  }

  .joinButtons {
    margin: 0 auto;
  }

  .joinLink {
    margin: auto;
    height: 40px;
    width: 40px;
  }

  .centre {
    font-size: 22px;
  }

  a.lastBtn {
    margin-right: 0px;
  }

  .descriptionHowto {
    padding-top: 0px;
    font-size: 11pt;
    width: 80%;
    margin: 0 auto;
  }

  .descriptionHowto p {
    font-size: 10pt;
  }

  .whatTo {
    margin-top: 40px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }

  .desc {
    font-size: 11pt;
    max-height: 300px;
    max-width: 1000px;
  }

  .accordion {
    padding-top: 0px;
  }

  .accordionHeading {
    font-size: 11pt;
  }

  .accordionDetails {
    font-size: 10pt;
  }
}

/*- Ipad pro vertical -*/
@media only screen and (max-width: 834px) {
  .desc {
    font-size: 11pt;
    max-height: 190px;
    max-width: 400px;
  }
}

@media only screen and (max-width: 700px) {
  .whatTo {
    max-width: 60%;
    grid-template-columns: 1fr;
    grid-template-columns: repeat(auto-fill, 1fr);
  }
}

/*- ipad half screen horizontal -*/
@media only screen and (max-width: 550px) {
  .desc {
    font-size: 9pt;
    max-height: 190px;
    max-width: 400px;
  }
  
  .quoteContainer {
    height: 57%;
  }
}

@media only screen and (max-width: 450px) {
  .whatTo {
    max-width: 80%;
  }

  .slide {
    height: 320px;
  }

  .quoteContainer {
    height: 55%;
  }
}

/*-------------------------------Join us very small screen------------------------------*/
@media only screen and (max-width: 355px) {
  .joinLink {
    display: block;
  }

  .joinUsRight {
    height: 200px;
  }

  .header {
    font-size: 22pt;
  }

  .section {
    font-size: 24pt;
  }

  .googleMap {
    padding-left: 0px;
  }
}

/*- Iphone SE vertical -*/
@media only screen and (max-width: 320px) {
  .section {
    font-size: 24pt;
  }

  .desc {
    font-size: 9pt;
    max-height: 190px;
    max-width: 400px;
  }
}

/* Dark mode */
@media (prefers-color-scheme:dark) {
  .gmapColour {
    filter: invert(100%) hue-rotate(180deg) brightness(95%) contrast(90%);
  }

  .descriptionHowto {
    color: white; 
  }

  .descriptionHowto p {
    color: white;
  }

  .accordionHeading {
    color: white; 
  }

  .accordionDetails {
    color: white; 
  }
}