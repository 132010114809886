@import "../style.css";

/*--- Timeline Content ---*/
.timelineContent {
  font-family: "Montserrat", sans-serif;
  font-size: 12pt;
  text-align: justify;
  color: var(--general-text);
  padding-top: 20px;
  margin: auto 0;
}

.year {
  font-weight: 600;
}

.yearContent {
  font-family: "Montserrat", sans-serif;
  font-size: 12pt;
  text-align: justify;
  color: var(--yellow);
  margin: 0 auto;
}

.timelineSeparator {
  color: var(--light-grey);
}

/*--- Our Story dark mode ---*/
@media (prefers-color-scheme: dark) {
  .yearContent {
    color: var(--white);
  }

  .timelineContent {
    color: var(--white);
  }
}

/*--- Our Story big tablet version ---*/
@media only screen and (max-width: 1200px) {
  .timelineContent {
    font-size: 11pt;
    padding-top: 10px;
  }

  .yearContent {
    font-size: 11pt;
    padding-left: 47px;
  }
}

/*--- Our Story tablet version ---*/
@media only screen and (max-width: 1005px) {
  .timelineContent {
    padding-top: 40px;
  }
}

/*--- Our Story mobile version ---*/
@media only screen and (max-width: 540px) {
  .yearContent {
    padding-left: 50px;
  }
}

@media only screen and (max-width: 414px) {
  .yearContent {
    font-size: 10pt;
    padding-left: 0px;
  }

  .timelineContent {
    font-size: 10pt;
  }
}
