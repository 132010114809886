@import "../style.css";

/*-------------------------------Blog post Categories-----------------------------*/
.blogCategories {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  width: 95%;
  margin: 0 auto;
}

.tooltipTitle {
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
  margin: 10px 0;
  text-transform: uppercase;
}

.tooltipDescription {
  font-size: 11px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

.blogGallery {
  margin-top: 50px;
}

/*---------------------------Loading Sign--------------------------*/

#blogsLoading {
  color: var(--yellow);
  margin-top: 10%;
  margin-bottom: 10%;
}

#blogLoadingContainer {
  display: flex;
  justify-content: center;
}

/*-------------------------------Blog post Preview-----------------------------*/
/*Preview Image and Hover*/
.blogPreview {
  padding: 30px;
}

.previewContainer {
  width: 35.807vw;
  overflow: hidden;
}

.previewPic {
  width: 35.807vw;
  height: 22.786vw;
  transition: transform 0.5s ease;
}

.blogPost table:hover .previewPic{
  -ms-transform: scale(1.05); /* IE 9 */
  -webkit-transform: scale(1.05); /* Safari 3-8 */
  transform: scale(1.05);
}

.blogPost {
  margin: 5vw 0;
  transition: 0.2s;
}

.darkOverlay {
  width: 100%;
  background-color: black;
  opacity: 0;
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 1;
  transition: opacity 0.5s ease;
}

.blogPost .darkOverlay:hover {
  opacity: 0.04;
}

/*Author circle crop*/
.authorPic {
  border-radius: 50%;
  max-height: 70px;
  overflow: hidden;
  max-width: 70px;
  margin-right: 1vw;
  margin-left: 0px;
}

/*List of authors*/
.authorRow {
  display: grid;
  grid-template-columns: auto auto;
}

/*Author image*/
.execAuthor {
  height: auto;
  width: 120%;
  transform: translate(-5%, -10%);
}

.anonAuthor {
  height: auto;
  width: 100%;
}

/*Post preview*/
.blogDetails {
  margin-bottom: 10px;
}

.heading {
  color: var(--yellow);
  letter-spacing: 0.1vw;
  font-size: 11pt;
  font-family: "Montserrat", sans-serif;
  text-decoration: none;
  margin-bottom: 10px;
  font-weight: 500;
}

.auth {
  color: var(--yellow);
  font-size: 10pt;
  font-family: "Montserrat", sans-serif;
  text-decoration: none;
  display: inline-block;
  font-weight: 500;
}

.heading:hover {
  text-decoration: none;
}

.subheading {
  color: var(--general-text);
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  padding: 0;
  margin-bottom: 10px;
}

.date {
  color: var(--general-text);
  letter-spacing: 0.1vw;
  font-size: 7pt;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  padding: 0;
  margin: 0;
}

.blogPost table {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin: 0 auto;
  width: 70vw;
  height: 100%;
  position: relative;
}

#previewRow {
  display: flex;
  align-items: center;
}

.authorSection {
  padding-top: 0.2vw;
  padding-bottom: 0.2vw;
  padding-right: 0.5vw;
  display: flex;
  align-items: center;
}

.searchBar {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 3%;
}

.inputSearchBar {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px transparent;
  border-left: 1px transparent;
  border-right: 1px transparent;
  border-bottom: 2px solid black;
  width: 71%;
  font-family: "Montserrat", sans-serif;
  background: url("/icons/search_icon.png") center left no-repeat;
  background-position-x: 0.5%;
  background-size: auto 85%;
  padding: 0.5%;
  height: 32px;
  padding-left: 3%;
}

#emptyMessage {
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

/*--- Dark mode ---*/
@media (prefers-color-scheme:dark) {
  .blogDetails {
    color: white;
  }

  .inputSearchBar {
    color: white;
    border-color: white;
  }

  .blogPost table {
    background-color: var(--dark-slate-grey);
  }

  .darkOverlay {
    background-color: white;
  }
}

/*-------------------------------Blog post Recommendation-----------------------------*/

#recommendation {
  margin-top: 0;
  margin-bottom: 100px;
}

/*-------------------------------Blog post Responsiveness-----------------------------*/

@media screen and (max-width: 1200px) {
  .blogPost table {
    width: 80%;
  }
  
  .inputSearchBar {
    width: 80%;
  }

  .authorName {
    width: auto;
    padding-left: 5px;
  }

  .authorPic {
    width: 50px;
    height: 50px;
    margin: 0 1.5vw;
    object-fit: contain;
  }
}

@media screen and (max-width: 950px) {
  .inputSearchBar {
    padding-left: 5%;
    width: 85%;
  }

  .blogPost table {
    width: 85vw;
  }
}

@media screen and (max-width: 800px) {
  .blogPreview {
    padding: 20px;
  }

  tr {
    flex-wrap: wrap;
    justify-items: flex-start;
    align-items: flex-start;
    width: 100%;
  }

  .blogPost table,
  .inputSearchBar {
    width: 90%;
  }

  .previewPic, .previewContainer {
    width: 100%;
    height: auto;
  }

  .authorSection {
    padding-left: 10px;
  }

  .blogDetails .date {
    font-size: 12px;
  }
}

@media screen and (max-width: 500px) {
  .authorRow {
    display: grid;
    grid-template-columns: auto;
    row-gap: 2vw;
  }

  .searchBar {
    padding-top: 8%;
  }

  .inputSearchBar {
    width: 90%;
    padding-left: 7%;
  }
  
  .blogPost table {
    width: 90vw;
  }
}

@media screen and (max-width: 380px) {
  .authorSection {
    display: flex;
    align-items: center;
    padding-left: 0px;
    padding-bottom: 10px;
  }

  .inputSearchBar {
    padding-left: 9%;
  }
}
