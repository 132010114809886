@import "../style.css";

/*-------------------------------Home page header-----------------------------*/
/*Header image*/
.contain {
  background-image: url("/headers/header-1.jpg");
  background-position: center bottom 40%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  max-width: 100%;
  background-color: var(--general-text);
}

/*Header title*/
div .headline {
  text-align: left;
  position: absolute;
  top: 20%;
  left: 54vw;
}

.headline h1 {
  color: var(--yellow);
  font-family: "Playfair Display", serif;
  font-size: 5.5vw;
  text-transform: uppercase;
  letter-spacing: 3px;
}

/*Header subtitle*/
.headline p {
  color: var(--white);
  font-family: "Montserrat", sans-serif;
  font-size: 1.17vw;
  letter-spacing: 2.5px;
}

.headline button {
  border: none;
  background-color: var(--red);
  transition: 0.3s;

  text-align: center;
  display: inline-block;
  position: relative;
  padding: 13px 0px;
  width: 120px;
  overflow: hidden;
}

.headline button:before {
  content: "➜";
  font-size: 11pt;
  color: var(--white);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  top: 0;
  opacity: 0;
  height: 100%;
  width: 40px;
  transition: all 0.2s linear 0s;
}

.headline button:hover:before {
  opacity: 1;
  text-indent: 0px;
}

.headline button:hover {
  background-color: var(--dark-red);
  text-indent: -20px;
}

.headline a {
  color: var(--white);
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1em;
}

.link {
  text-decoration: none;
}

.headline a:hover {
  color: var(--white);
}

/*-----------------------------Home page description--------------------------*/
/*Description wrapper*/
.description {
  margin: auto;
  max-width: 85%;
  display: grid;
  padding: 100px 0px;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
}

/*Description left block*/
.descriptionLeft {
  grid-area: auto / span 1;
}

/*Left block text*/
.descriptionLeft h1 {
  color: var(--yellow);
  font-family: "Montserrat", sans-serif;
  font-size: 4.5vw;

  font-weight: 600;
  letter-spacing: 4px;
  line-height: 100px;
  text-align: right;
}

/*Description right block*/
.descriptionRight {
  line-height: 22px;
  padding-top: 20px;
  grid-area: auto / span 1;
}

/*Right block text*/
.descriptionRight p {
  color: var(--general-text);
  font-family: "Montserrat", sans-serif;
  font-size: 11pt;
  text-align: justify;
}

/*Description center block*/
.descriptionMiddle {
  grid-area: auto / span 1;
}

/*Dark mode*/
@media (prefers-color-scheme:dark) {
  .descriptionRight p {
    color:white;
  }
}

/*---------------------------Home page statistics-----------------------------*/

/*Background image*/
.statsBackground {
  height: 300px;
  width: 100%;
  object-fit: cover;
}

.stats {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.number {
  display: inline-grid;
  grid-template-columns: 30% 30% 45%;
  left: 0;
  margin: 0 auto;
  place-items: center center;
  position: absolute;
  right: 0;
  text-align: center;
  width: 60%;
  bottom: 30%;
}

.number span {
  color: var(--yellow);
  font-family: "Montserrat", sans-serif;
  font-size: 75pt;
  font-weight: 500;
  letter-spacing: 15px;
}

.number p {
  color: var(--white);
  font-family: "Montserrat", sans-serif;
  font-size: 14pt;
}

/*-------------------------------Home page events-----------------------------*/
/*Events block*/
.events {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 100px auto;
  grid-column-gap: 50px;
  padding: 100px 0px;
  max-width: 85%;
  margin: 0 auto;
}

/*Events description grid area*/
.eventsDescription {
  width: 100%;
  height: 100%;
  padding-bottom: 80px;
}

/*Events description text*/
.eventsDescription p {
  color: var(--general-text);
  font-family: "Montserrat", sans-serif;
  font-size: 11pt;
  text-align: right;
}

.eventsDescription p#about {
  padding: 20px;
  padding-bottom: 0;
}

.eventsDescription a {
  font-family: "Montserrat", sans-serif;
  text-align: right;
  color: var(--red);
  float: left;
}

/*Events title grid area*/
.eventsTitle {
  text-align: left;
  grid-area: 1 / 2;
}

.eventsTitle h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 55px;
  text-align: left;
}

/*Big event image grid area*/
.event1 {
  height: 100;
}

/*Big event image size*/
.event1 img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease;
  overflow: hidden;
}

.event1 img:hover {
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Safari 3-8 */
  transform: scale(1.03);
}

.eventsDescription button {
  border: 1.5px solid var(--red);
  box-sizing: border-box;
  background-color: var(--white);
  padding: 6px 6px;
  transition: 0.3s;
}

.eventsDescription button:hover {
  border: 1.5px solid var(--red);
  background-color: #ffeeee;
}

.eventsDescription a {
  font-weight: 500;
}

.eventsDescription a:hover {
  color: var(--red);
}

/*Dark mode*/
@media (prefers-color-scheme:dark) {
  .eventsDescription p {
    color: #fff;
  }

  .eventsDescription button {
    background-color:#202124;
  }

  .eventsDescription button:hover {
    background-color: var(--light-grey);
  }
}

/*---------------Slideshow-------------------*/

/*quotes section background*/
.quotes {
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
  background: var(--section);
}
  
/*Center block text*/
.quotes h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 55px;
  text-align: center;
  line-height: normal;
  margin: 0px;
}

.quoteCarousel {
  margin-top: 20px;
}

.carousel {
  text-align: center;
  padding: 100px 50px;
  position: relative;
}

.slideComponent {
  height: 100%;
}

.slideContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.quotes .carousel {
  padding-top: 0px;
  padding-bottom: 0px;
}

.carousel h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 55px;
  text-align: left;
}

.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: var(--white);
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

.eventsDescription .left {
  width: 50%;
  height: 100%;
  float: left;
  padding: 21px 15px 10px 80px;
}

.eventsDescription .left p#about {
  padding: 20px;
  padding-bottom: 0;
  max-width: 100%;
  max-height: 100%;
}

.eventsDescription .right {
  width: 50%;
  height: 100%;
  float: right;
  padding: 10px 55px 10px 15px;
}

.right img {
  object-fit: contain;
  max-height: 100%;
  max-width: 100%;
  transition: transform 0.5s ease;
  overflow: hidden;
}

.right a {
  font-family: "Montserrat", sans-serif;
  text-align: right;
  color: var(--red);
  float: left;
  height: 100%;
}

.right img:hover {
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Safari 3-8 */
  transform: scale(1.03);
}

.carousel .buttonBack {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 0;
  height: 0;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  border-right: 30px solid #313638;
  border-left: 0;
  padding: 0;
  background-color: transparent;
  outline: 0;
}

.carousel .buttonNext {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 0;
  height: 0;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  border-left: 30px solid #313638;
  border-right: 0;
  padding: 0;
  background-color: transparent;
  outline: 0;
}

.buttonBack:disabled {
  border-right: 30px solid var(--light-grey);
}

.buttonNext:disabled {
  border-left: 30px solid var(--light-grey);
}

.titleMobile h1 {
  display: none;
  text-align: center;
  grid-area: 1 / 2;
  font-family: "Montserrat", sans-serif;
  font-size: 55px;
}

/* .home_publications__ncQSH img { */
/* margin-top: 50px; */
/* width: 50%; */
/* max-width: 500px; */
/* text-align: right; */
/* float: right; */

/*---Dark mode---*/
@media (prefers-color-scheme:dark) {
  .carousel .buttonBack {
    border-right: 30px solid var(--light-grey);
  }
  
  .carousel .buttonNext {
    border-left: 30px solid var(--light-grey);
  }

  .buttonBack:disabled {
    border-right: 30px solid var(--grey);
  }
  
  .buttonNext:disabled {
    border-left: 30px solid var(--grey);
  }

  .quotes {
    background: #323235;
  }
}

/*------------------------------Home page sponsors----------------------------*/
/*Sponsors image position*/
.sponsors {
  text-align: center;
  margin: auto;
  padding-top: 100px;
  padding-bottom: 100px;
  width: 85%;
}

.sponsors h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 55px;
  letter-spacing: 3px;
  text-align: center;
  margin: 0px;
  line-height: normal;
}

.sponsors img {
  margin-top: 50px;
  width: 100%;
  max-width: 900px;
}

.darkmodeBanner {
  display:none;
}

.lightmodeBanner {
  display:unset;
}

@media (prefers-color-scheme:dark) {
  .lightmodeBanner {
    display:none;
  }

  .darkmodeBanner {
    display: unset;
  }
}

/*--------------Publications-------------------*/
#publicationsLoading {
  color: var(--yellow);
  margin-top: 7%;
  margin-bottom: 8%;
}

.publications {
  text-align: center;
  margin: auto;
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: var(--section);
}

.publications h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 55px;
  letter-spacing: 3px;
  line-height: 100px;
  text-align: center;
  margin: 0px;
  line-height: normal;
}

.publications img {
  width: 100%;
  max-width: 900px;
}

.publications .articlesDiv {
  margin: 50px auto 40px  auto;
  width: 85%;
}

.pubBtn {
  border: 1.5px solid var(--red);
  box-sizing: border-box;
  background-color: var(--section);
  padding: 6px 6px;
  transition: 0.3s;
}

.pubBtn a {
  color: var(--red);
  font-family: "Montserrat", sans-serif;
  font-size: 11pt;
  font-weight: 500;
}

.pubBtn:hover a {
  color: var(--red);
}

.pubBtn:hover {
  border: 1.5px solid var(--red);
  background-color: #ffeeee;
}

.articles {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  width: 85%;
  margin: 0 auto;
}

.homeArticles div {
  margin: 0;
}

/*---Dark mode---*/
@media (prefers-color-scheme:dark){
  .publications {
    background-color: #323235;
  }

  .pubBtn {
    border: 1.5px solid var(--red);
    background-color: #323235;
  }
  
  .pubBtn:hover {
    background-color:var(--sand);
  }
}

/*-----------------------------Responsive Mobile Design--------------------------*/
@media only screen and (max-width: 1200px) {
  .number {
    width: 70%;
  }
}

@media screen and (max-width: 1065px) {
  .quoteCarousel {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 1065px) {
  .publications .homeArticles {
    padding: 15px 15px 15px 15px;
    display: inline-block;
  }
}

@media only screen and (max-width: 1000px) {
  .number {
    grid-template-columns: 25% 30% 45%;
    position: absolute;
    width: 85%;
  }

  .number span {
    letter-spacing: 15px;
  }
  .number p {
    font-size: 2vw;
  }
}

@media only screen and (max-width: 990px) {
  .sponsors {
    padding: 100px 0;
  }

  .carousel {
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
  }

  .eventsDescription {
    order: 2;
    padding: 0px;
    height: auto;
    max-width: 90%;
    margin: auto;
    padding: 5px;
  }

  .eventsDescription .left {
    padding: 0px 5px 0px 5px;
    width: 85%;
    height: auto;
    float: none;
    margin: auto;
  }

  .left p {
    margin: 20px 0;
    text-align: justify;
  }

  .eventsDescription .right {
    padding: 0px 5px 0px 5px;
    width: 85%;
    height: auto;
    float: none;
    margin: auto;
  }

  .eventsDescription .right h1 {
    font-size: 5vw;
    text-align: center;
    display: none;
  }

  .right img {
    object-fit: contain;
  }

  .right a {
    height: auto;
    text-align: center;
  }

  .titleMobile h1 {
    display: contents;
    font-size: 55px;
    padding-top: 50px;
  }

  .dot {
    display: contents;
    height: 25px;
    width: 25px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
  }

  .slide_component {
    height: 840px;
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: 946px) {
  .eventsDescription .left p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1065px) {
  .publications .homeArticles {
    padding: 15px 15px 15px 15px;
    display: inline-block;
  }
}

@media only screen and (max-width: 850px) {
  .events {
    padding: 50px 0px;
    max-width: 85%;
    margin: 0 auto;
    display: flex;
    flex-flow: column;
  }

  /* Use a Flexbox to switch positions of Title and Description. */
  /*Events title - flexbox*/
  .eventsTitle {
    margin: 0 auto;
    order: 1;
    padding-left: 0px;
  }

  .eventsTitle h1 {
    text-align: center;
    font-size: 9vw;
  }

  .quotes h1 {
    font-size: 9vw;
    text-align: center;
  }

  .eventsDescription a {
    text-align: justify;
  }

  /*Big event image area - flexbox*/
  .event1 {
    order: 3;
  }

  .contain {
    background-position: center bottom 40%;
    height: 66vh;
  }

  /*Header title*/
  div .headline {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto 15%;
    height: 70vh;
  }

  .headline button {
    padding: 8px 8px;
  }

  .headline h1 {
    font-size: 6vw;
    text-transform: uppercase;
    letter-spacing: 3px;
  }

  /*Header subtitle*/
  .headline p {
    font-size: 10pt;
    letter-spacing: 2.5px;
  }

  .headline a {
    font-size: 12px;
  }

  .description {
    max-width: 85%;
    display: block;
    padding: 0px;
  }

  .descriptionLeft {
    width: 100%;
    padding: 50px 0px 0px 0px;
    height: auto;
  }

  .descriptionLeft h1 {
    padding: 0px;
    font-size: 9vw;
    line-height: 90px;
  }

  .descriptionRight {
    width: 100%;
    height: auto;
    padding: 20px 0px 50px 0px;
  }

  .sponsors {
    padding: 50px 0;
  }

  .sponsors h1 {
    font-size: 9vw;
  }

  .sponsors img {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 990px) {
  .slideComponent {
    height: 840px;
    display: flex;
    flex-direction: column;
  }

  .sponsors {
    padding: 100px 0;
  }

  .carousel {
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
  }

  .eventsDescription {
    order: 2;
    padding: 0px;
    height: auto;
    max-width: 90%;
    margin: auto;
    padding: 5px;
  }

  .eventsDescription .left {
    padding: 0px 5px 0px 5px;
    width: 85%;
    height: auto;
    float: none;
    margin: auto;
  }

  .left p {
    margin: 20px 0;
    text-align: justify;
  }

  .eventsDescription .right {
    padding: 0px 5px 0px 5px;
    width: 85%;
    height: auto;
    float: none;
    margin: auto;
  }

  .eventsDescription .right h1 {
    font-size: 5vw;
    text-align: center;
    display: none;
  }

  .right img {
    object-fit: contain;
  }

  .right a {
    height: auto;
    text-align: center;
  }

  .titleMobile h1 {
    display: contents;
    font-size: 55px;
    padding-top: 50px;
  }

  .dot {
    display: contents;
    height: 25px;
    width: 25px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
  }
}

/* Adjusting font size of carousel description  */
@media only screen and (max-width: 946px) {
  .eventsDescription .left p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 850px) {
  .publications h1 {
    font-size: 9vw;
  }

  .publications .articlesDiv {
    margin-top: 15px;
  }

  .titleMobile h1 {
    display: contents;
    font-size: 9vw;
    padding-top: 50px;
  }

  div .headline {
    top: 15%;
  }
}

@media only screen and (max-width: 800px) {
  .number span {
    font-size: 12.5vw;
    letter-spacing: 8px;
  }

  .number p {
    font-size: 2.4vw;
  }

  .number {
    bottom: 33%;
    grid-template-columns: 28% 30% 45%;
    width: 85%;
  }

  .subscribeBtn {
    font-size: 16pt;
    width: 70%;
    right: 15%;
    bottom: 38%;
  }
}

@media only screen and (max-width: 700px) {
  .slideComponent {
    height: 670px;
  }

  .carousel {
    padding: 40px 0;
  }

  .carousel .buttonBack, .homeLeftArrow {
    margin-left: 25px; 
  }

  .carousel .buttonNext, .homeRightArrow {
    margin-right: 25px;
  }

  .carousel .buttonBack:disabled, .homeLeftArrow:disabled {
    margin-left: 25px;
  }

  .carousel .buttonNext:disabled, .homeRightArrow:disabled {
    margin-right: 25px;
  }
}

@media only screen and (max-width: 550px) {
  .slideComponent {
    height: 600px;
  }

  .carousel .buttonBack {
    margin-left: 20px;
  }

  .carousel .buttonNext, .homeRightArrow {
    margin-right: 20px;
  }

  .carousel .buttonBack:disabled {
    margin-left: 20px;
  }

  .carousel .buttonNext:disabled {
    margin-right: 20px;
  }
}


@media only screen and (max-width: 500px) {
  .eventsDescription {
    max-width: 80%;
  }

  .number {
    bottom: 40%;
  }
}

@media only screen and (max-width: 400px) {
  .subscribeBtn {
    font-size: 14pt;
    width: 80%;
    right: 10%;
  }

  div .headline {
    top: 20%;
  }
}
