@import "../style.css";

/*--- General ---*/
#container {
  margin-bottom: 100px;
}

/*--- Our Story ---*/
h2.oppSubheading {
  font-family: "Montserrat", sans-serif;
  font-size: 33pt;
  text-transform: uppercase;
  margin-top: 100px;
  text-align: center;
}

h2#topHeading {
  margin-top: 50px;
}

/*--- Our Mission ---*/
.ourMissionSection {
  display: flex;
  width: 80%;
  margin: 0 auto;
  flex-direction: row-reverse;
}

.ourMissionContent {
  font-size: 12pt;
  color: var(--black);
}

.ourMissionImg {
  width: 800px;
  padding-left: 25px;
}

.ourMissionImg img {
  width: 100%;
}

/*--- Our Mascot ---*/
.ourMascotSection {
  display: flex;
  width: 80%;
  margin: 0 auto;
}

.ourMascotContent {
  font-family: "Montserrat", sans-serif;
  font-size: 12pt;
  text-align: justify;
  color: var(--black);
}

.ourMascotImg {
  padding-top: 1px;
  padding-right: 25px;
}

.ourMascotImg img {
  width: 100%;
}

/*--- Link to see Willow's cute photoshoot ---*/
.link, .link:hover {
  text-decoration: underline;
  font-weight: 500;
  color: var(--red);
}

.link:visited {
  text-decoration: underline;
  color: var(--dark-red);
}

/*--- Our History ---*/
.ourHistorySection {
  display: flex;
  width: 80%;
  margin: 0 auto;
}

.ourHistoryContent {
  padding-bottom: 25px;
  font-size: 12pt;
  color: var(--black);
}

.ourStory {
  color: var(--black);
  font-family: "Montserrat", sans-serif;
  text-align: justify;
}

/*--- Dark mode ---*/
@media (prefers-color-scheme: dark) {
  .ourMissionContent {
    color: var(--white);
  }
  
  .ourMascotContent {
    color: var(--white);
  }

  .ourHistoryContent {
    color: var(--white);
  }
}

/*--- Our Story big tablet version ---*/
@media only screen and (max-width: 1200px) {
  .ourMissionContent {
    font-size: 11pt;
  }

  .ourMissionImg {
    width: 820px;
  }

  .ourMascotContent {
    font-size: 11pt;
  }

  .ourHistoryContent {
    font-size: 11pt;
  }
}

/*--- Our Story tablet version ---*/
@media only screen and (max-width: 1000px) {
  .ourStory {
    padding-top: 20px;
  }

  .ourMissionSection {
    flex-direction: column;
    width: 85%;
  }
  .ourMascotSection {
    flex-direction: column;
    width: 85%;
  }

  .ourMissionImg {
    display: flex;
    margin: 0 auto;
    padding-right: 20px;
    width: 250px;
    padding-top: 15px;
    flex-direction: column-reverse;
  }

  .ourMascotImg {
    display: flex;
    margin: 0 auto;
    padding-left: 3px;
    padding-right: 2px;
    width: 350px;
    padding-top: 15px;
  }
}

/*--- Our Story mobile version ---*/
@media only screen and (max-width: 540px) {
  h2.oppSubheading {
    margin-top: 10%;
    font-size: 23pt;
    text-transform: uppercase;
  }

  .ourMissionContent {
    font-size: 10pt;
  }

  .ourMascotContent {
    font-size: 10pt;
  }

  .ourHistoryContent {
    font-size: 10pt;
  }

  .ourMissionImg {
    display: flex;
    margin: 0 auto;
    width: 200px;
    flex-direction: column-reverse;
  }

  .ourMascotImg {
    display: flex;
    margin: 0 auto;
    width: 270px;
  }
}

@media only screen and (max-width: 414px) {
  h2.oppSubheading {
    margin-top: 10%;
    font-size: 23pt;
    text-transform: uppercase;
  }

  .ourMissionContent {
    font-size: 10pt;
  }

  .ourMascotContent {
    font-size: 10pt;
  }

  .ourHistoryContent {
    font-size: 10pt;
  }

  .ourMissionImg {
    display: flex;
    margin: 0 auto;
    width: 180px;
    padding-top: 15px;
    flex-direction: column-reverse;
  }

  .ourMascotImg {
    display: flex;
    margin: 0 auto;
    width: 280px;
    padding-top: 15px;
  }
}

@media only screen and (max-width: 375px) {
  .ourMissionImg {
    display: flex;
    margin: 0 auto;
    width: 180px;
    padding-top: 15px;
    flex-direction: column-reverse;
  }

  .ourMascotImg {
    display: flex;
    margin: 0 auto;
    width: 250px;
    padding-top: 15px;
  }
}

@media only screen and (max-width: 360px) {
  .ourMissionImg {
    display: flex;
    margin: 0 auto;
    width: 200px;
  }

  .ourMascotImg {
    display: flex;
    margin: 0 auto;
    width: 250px;
  }
}

/*--- Our Story small mobile version ---*/
@media only screen and (max-width: 320px) {
  .ourMissionImg {
    display: flex;
    margin: 0 auto;
    width: 170px;
  }

  .ourMascotImg {
    display: flex;
    margin: 0 auto;
    width: 200px;
  }
}
