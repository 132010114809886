@import "../style.css";

#container {
  height: auto;
  overflow: visible;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  caret-color: transparent;
  padding-top: 5vh;
  padding-bottom: 15vh;
}

#image404 {
  margin: 0 auto;
  object-fit: contain;
  width: 30vw;
}

#homeBtn {
  background-color: var(--red);
  color: var(--white);
  text-transform: uppercase;
  padding: 20px 25px;
  font-size: 18pt;
  letter-spacing: 1.5px;
  margin-top: 20px;
  text-decoration: none;
}

#homeBtn:hover {
  background-color: var(--dark-red);
}

@media only screen and (max-width: 500px) {
  #container {
    width: 80%;
    margin: 0 auto;
    padding-top: 20vh;
    padding-bottom: 30vh;
  }

  h2 {
    font-size: 22pt;
  }

  p {
    font-size: 12pt;
  }

  #image404 {
    margin: 0 auto;
    object-fit: contain;
    width: 55vw;
  }

  #homeBtn {
    font-size: 16pt;
  }
}
