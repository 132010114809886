@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&family=Playfair+Display&display=swap");

/* 
  Web fonts used:
  font-family: "Playfair Display", serif;
  font-family: "Montserrat", sans-serif;
*/

/*Web colours*/
:root {
  --white: #fff;
  --yellow: #feb14b; /* Headings and Highlighted text */
  --light-yellow: #fff7ef; /* Navigation */
  --sand:  #fff0e1c1; /* Selected navigation */
  --black: #313638; /* General text */
  --grey: #676767; /* Subheadings */
  --light-grey: #c4c4c4; /* disabled arrows */
  --section: #F4F4F4;
  --red: #e85f5c; /* Buttons and unvisited links */
  --dark-red: #bd3f3d; /* visited links */
  --dark-grey: #202124; /* dark mode card */
  --dark-slate-grey: #3b3b41; /* dark mode lighter grey card */
}

/*----------------------------------Dark mode----------------------------------*/
body {
  color: #222;
}

/*Changes background*/
@media (prefers-color-scheme: dark) {
  body {
    background:var(--dark-grey);
  }
}

/*----------------------------------General------------------------------------*/
h1 {
  color: var(--yellow);
  font-family: "Playfair Display", serif;
  font-size: 48pt;
}

h2 {
  color:var(--yellow);
  font-family: "Montserrat", sans-serif;
  font-size: 30pt;
  text-transform: uppercase;
  padding-bottom: 20px;
  text-align: center;
}

.header {
  padding-top: 50px;
  padding-bottom: 20px;
  text-align: center;
}

.subheader {
  font-family: "Montserrat", sans-serif;
  font-size: 13pt;
  color: var(--grey);
}

p {
  font-family: "Montserrat", sans-serif;
  font-size: 10pt;
  margin-top: 0;
}

.body_bold {
  font-family: "Montserrat", sans-serif;
  font-size: 10pt;
  font-weight: bold;
}

.centre {
  text-align: center;
}

.button {
  font-family: "Montserrat", sans-serif;
  background-color: #e85f5c;
  border-radius: 60px;
  border-style: none;
  color: white;
  padding: 10px 45px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  transition: 0.3s;
}

.button:hover {
  background-color: var(--dark-red);
  color: #ffffff;
}

/*----------------------------------Header------------------------------------*/
/*Heading title*/
.headerTitle h1 {
  font-family: "Playfair Display", serif;
  font-size: 5vw;
  letter-spacing: 8px;
  text-transform: uppercase;
  text-align: center;
}

.coverPhoto {
  height: 100vh;
  overflow: hidden;
}

.cover_image {
  height: 100%;
  overflow: hidden;
  width: 100%;
  object-fit: cover;
  position: absolute;
}

/*website overlay*/
.coverPhoto .dark {
  width: 100%;
  background-color: #313638;
  opacity: 0.6;
  position: absolute;
  top: 0;
  height: 100vh;
  z-index: 1;
}

.coverPhoto .headerTitle {
  position: absolute;
  top: 0;
  text-align: center;
  width: 100%;
  z-index: 2;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.coverPhoto .headerTitle h1 {
  margin: 0px;
  font-weight: 600;
}

@media only screen and (max-width: 700px) {
  .coverPhoto,
  .coverPhoto .dark,
  .coverPhoto .headerTitle,
  .cover_image {
    height: 40vh;
  }

  .coverPhoto .headerTitle h1 {
    font-size: 3.5vh;
  }

  .coverPhoto .headerTitle {
    top: 25px;
  }
}

@media only screen and (max-width: 400px) {
  .coverPhoto .headerTitle h1 {
    font-size: 3vh;
  }
}

@media only screen and (max-width: 340px) {
  .coverPhoto .headerTitle h1 {
    font-size: 2.5vh;
  }
}

@media only screen and (max-width: 318px) {
  .coverPhoto {
    position: relative;
  }
}

/*-----------------------------------Navbar-----------------------------------*/
.bg-custom {
  background: transparent;
  font-size: 10pt;
  padding: 0px;
  z-index: 99;
}

.nav {
  height: 70px;
  z-index: 99;
}

.navbar-nav {
  color: #313638;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  text-decoration: none;
  z-index: 99;
}

.nav-item {
  float: right;
  color: #313638;
  padding: 15px 5px;
  text-decoration: none;
  letter-spacing: 1.5px;
  z-index: 99;
  position: relative;
}

.nav-item-btn {
  color: #313638;
  padding-top: 22px;
  padding-right: 10px;
  display: none;
  z-index: 99;
  position: relative;
}

.nav-item a,
.dropdown-title {
  color: white;
  padding: 15px;
  text-decoration: none;
  z-index: 99;
}

.dropdown-menu {
  border-color: #fff7ef;
  letter-spacing: 1.5px;
  font-size: 14px;
  background-color: #fff7ef;
  margin-top: 15px;
  border-radius: 0px 0px 10px 10px;
  z-index: 99;
}

.transparent {
  margin-top: 25px;
  border-radius: 10px;
  z-index: 99;
}

.transparent::after {
  content: " ";
  border-style: solid;
  border-width: 0 1.8em 2em 1.8em;
  border-color: transparent;
  position: absolute;
  right: 48px;
  top: -1em;
  border-bottom-color: #fff7ef;
  z-index: 99;
}

.mediaDD::after {
  content: " ";
  border-style: solid;
  border-width: 0 1.8em 2em 1.8em;
  border-color: transparent;
  position: absolute;
  right: 81px;
  top: -1em;
  border-bottom-color: #fff7ef;
}

.dropdown-item {
  padding: 7px 10px 5px 10px;
}

.dropdown-media {
  padding: 7px 5px;
}

.dropdown-item a {
  color: #313638;
  transition: 0.2s;
}

.dropdown-item:hover {
  background-color: #fff7ef;
}

.affiliations-remove-gap {
  margin-left: 35px;
  padding-bottom: 0;
  margin-bottom: 0;
  max-height: 0;
}

.menuTitle {
  color: var(--general-text);
  padding: 10px 15px;
}

.menuTitle:hover {
  color: var(--red);
  cursor: pointer;
}

.nav-item a:hover {
  color:  var(--red);
  padding: 15px;
  text-decoration: none;
}

.nav-item::after {
  content: '';
  position: absolute;
  display: block;
  height: 2px;
  width: 0%;
  bottom: 0;
  left: 5%;
  opacity: 0;
  background-color: var(--red);
  transition: opacity 300ms, width 0.35s ease 0s;
}
  
.nav-item:hover:not(.highlight-nav):after  {
  width: 90%;
  opacity: 1;
}

.nav-item:hover:not(.highlight-nav-no-drop):after  {
  width: 90%;
  opacity: 1;
}

.nav-item:hover:not(.highlight-nav-white):after  {
  width: 90%;
  opacity: 1;
}

/*Making the navigation change colour on scroll*/
.activeNav {
  background-color: var(--light-yellow);
  transition: background-color 0.2s ease-in;
}

.dropdown-toggle:after {
  color: var(--white);
}

.active-nav-item a,
.active-nav-item span,
.active-nav-item:after {
  color: var(--black);
}

.logo-black {
  display: none;
}

/* logo on hamburger menu */
.logo {
  width: 35px;
  margin: 5px 0px 5px 15px;
}

.icon {
  transform: scale(0.9);
  margin-right: 10px;
  margin-left: -10px;
}

.icon-media {
  margin-left: -5px;
}

.highlight-nav, 
.highlight-nav-white,
.highlight-nav-no-drop,
.highlight-dropdown,
.highlight-no-nav {
  font-weight: 500;
}

/* clear background - dropdown */
.highlight-no-nav::after {
  content: '';
  position: absolute;
  display: block;
  height: 2px;
  width: 100%;
  bottom: 0;
  left: 0%;
  background-color: var(--white);
  top: 32px;
  z-index: 100;
}

/* yellow background - dropdown */
.highlight-nav::after {
  content: '';
  position: absolute;
  display: block;
  height: 2px;
  width: 100%;
  bottom: 0;
  left: 0%;
  background-color: var(--red);
  top: 32px;
  z-index: 100;
}

/* yellow background - no dropdown*/
.highlight-nav-no-drop::after {
  content: '';
  position: absolute;
  display: block;
  height: 2px;
  width: 90%;
  bottom: 0;
  left: 5%;
  background-color: var(--red);
  z-index: 100;
}

/* clear background - no dropdown */
.highlight-nav-white::after {
  content: '';
  position: absolute;
  display: block;
  height: 2px;
  width: 90%;
  bottom: 0;
  left: 5%;
  background-color: var(--white);
  z-index: 99;
}

.hiddenNav {
  display: none;
}

/*Dark mode version of nav bar*/
@media (prefers-color-scheme: dark) {
  .activeNav {
    background-color: #000000;
  }

  .active-nav-item a,
  .active-nav-item span,
  .active-nav-item:after {
    color: #ffffff;
  }

  .dropdown-item:hover {
    background-color: #000000;
  }

  .dropdown-menu {
    border-color: #000000;
    background-color: #000000;
  }

  .dropdown-item a {
    color: #fff;
  }

  .transparent::after {
    border-bottom-color: #000000;
  }

  .mediaDD::after {
    border-bottom-color: #000000;
  }
}

/* Mobile responsiveness of nav bar */
@media screen and (max-width: 950px) {
  .nav-item {
    display: none;
  }

  .bg-custom {
    background-color: var(--light-yellow);
  }

  .logo-black {
    display: block;
  }
    
  .nav-item-btn {
    color: white;
    display: block;
    z-index: 99;
  }
}

/* Mobile responsiveness of nav bar and dark mode */
@media screen and (max-width: 950px) and (prefers-color-scheme: dark) {
  .activeNav {
    background-color: #000;
  }

  .active-nav-item a,
  .active-nav-item span,
  .active-nav-item:after {
    color: #ffffff;
  }

  .bg-custom {
    background-color: #000;
  }

  .dropdown-item:hover {
    background-color: #000;
  }

  .dropdown-menu {
    border-color: #000000;
    background-color: #000000;
  }

  .dropdown-item a {
    color: #fff;
  }

  .transparent::after {
    border-bottom-color: #000000;
  }

  .mediaDD::after {
    border-bottom-color: #000000;
  }

  .nav-item-btn {
    color: white;
    display: block;
    z-index: 99;
  }

  .photo {
    display: block;
  }
}

/*----------------------------------Loading Screen------------------------------------*/
#loadingBackground {
  background: var(--light-yellow);
  height: 100vh;
  z-index: 100;
  position: absolute;
  width: 100vw;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#loadingWillow {
  height: 300px;
  width: 300px;
}

#loadingBackground p {
  overflow: hidden;
  font-size: 18pt;
  animation: typing 2.5s steps(34, end);
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 10em;
  }
}

@media (prefers-color-scheme: dark) {
  #loadingBackground {
    background: #323235;
  }

  #loadingBackground p {
    color: #fff; 
  }
}

@media screen and (max-width: 320px) {
  #loadingBackground {
    height: 100%;
    position: fixed;
    width: 100%;
  }
}

@media screen and (max-width: 300px) {
  #loadingWillow {
    height: 280px;
    width: 280px;
  }
}

/*-----------------------------------Menu-----------------------------------*/

.menu-container {
  position: fixed;
  width: 100%;
  background: var(--light-yellow);
  opacity: 1;
  color: #313638;
  z-index: 3;
  box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.2);
}

.menu-link {
  text-decoration: none;
}

.menu-item {
  color: #313638;
  padding: 5px 35px;
  text-decoration: none;
  letter-spacing: 1.5px;
  text-transform: capitalize;
  cursor: pointer;
  font-size: 16px;
}

.menu-box:hover {
  width: 92%;
  background-color: var(--sand);
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
}

.menu-box:hover .menu-item {
  color: var(--red);
}

.menu-bar {
  float: none;
}

.menu-list {
  padding-top: 4rem;
}

.menu-dropdown {
  padding: 5px 0px;
}

/* Dark mode and responsive menu dropdown*/
@media screen and (max-width: 950px) and (prefers-color-scheme: dark) {
  .menu-container {
    background: #000000;
    color: #fff;
  }

  .menu-item {
    color: #fff;
  }
  
  .menu-box:hover {
    background-color: var(--dark-grey);
  }
  
  .menu-box:hover .menu-item {
    color: var(--red);
  }
}

/*----------------------------------Footer------------------------------------*/
/* WIT logo */
.photo {
  width: 40px;
}

footer {
  margin: 0px;
  padding: 0px;
  background-color: var(--light-yellow);
  min-width: 100%;
}

.footer-all {
  list-style-type: none;
  margin: 0 auto 1.4vw auto;
  padding: 4vw 0vw 2vw 0vw;
}

.footer-item {
  font-family: "Montserrat", sans-serif;
  padding: 0.5vw;
  color: var(--black);
  font-size: 1.05vw;
}

.footer-header {
  font-weight: 750;
  padding-bottom: 1vw;
}

.bottom-footer-item {
  font-family: "Montserrat", sans-serif;
  padding: 0.3vw;
  color: var(--black);
  text-transform: uppercase;
}

.footer-logo {
  margin-top: 1%;
  padding-left: 5%;
}

.socials .footer-icons {
  padding: 0px 0.5vw;
}

.square-link {
  width: 80%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 1.75rem;
  padding: 0px;
}

.footer-icons-img {
  width: 100%;
  height: 100%;
}

/*Social Tooltip Styles*/
.tooltipTitle {
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
  margin: 10px 0;
}

.col-width {
  flex-basis: 40%;
}

.socials .footer-item {
  text-align: center;
  display: flex;
  justify-content: center;
}

.footer-copyright {
  font-family: "Montserrat", sans-serif;
  text-transform: none;
  padding-bottom: 1vw;
  font-size: 1.05vw;
}

.footer-copyright,
a {
  color: #313638;
  transition: 0.2s;
}

.footer-item a {
  transition: 0.2s;
}

.footer-item a:hover {
  color: var(--red);
  text-decoration: none;
}

.bottom-footer-item a:hover {
  color: var(--red);
  text-decoration: none;
}

.grid-footer-container {
  display: flex;
  width: 90%;
  margin: 0px auto;
}

/*---Dark mode version of footer---*/
.footer-logo-black {
  display:block;
  width: 50px;
}

.footer-logo-white {
  display:none;
}

@media (prefers-color-scheme: dark) {
  .footer-logo-black {
    display: none;
  }

  .footer-logo-white {
    display: block;
    width: 50px;
  }

  footer {
    color: var(--white);
    background: black;
  }

  .bottom-footer-item {
    color: var(--white);
  }

  .footer-item {
    color: var(--white);
  }

  .footer-copyright,
  a {
    color: var(--white);
  }
}

@media screen and (max-width: 900px) {
  .square-link {
    width: 100%;
    grid-gap: 1.25rem;
  }
}

@media screen and (max-width: 600px) {
  .grid-footer-container {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .footer-item {
    text-align: center;
    font-size: 16px;
  }

  .footer-copyright {
    font-size: 16px;
    padding-bottom: 5vw;
  }

  .footer-white-logo {
    margin-bottom: 2%; 
  }
  
  .footer-black-logo {
    margin-bottom: 2%;
  }

  .footer-icons {
    display: center;
    margin-left: 2.5vw;
    margin-right: 2.5vw;
  }

  .socials {
    margin-left: 0%;
    margin-top: 0%;
  }

  .footer-all {
    margin: 0vw auto 1vw auto;
  }

  .footer-item {
    padding-bottom: 2vw;
  }
  
  .bottom-footer-item {
    padding-bottom: 5vw;
  }

  .footer-header {
    margin-top: 7.5%;
  }

  .square-link {
    display: inline-block;
  }

  .footer-icons-img {
    height: 25px;
    width: 25px;
  }
}

@media screen and (max-width: 500px) {
  .footer-icons {
    margin-left: 1.5vw;
    margin-right: 1.5vw;
  }  
}

@media screen and (max-width: 355px) {
  .footer-icons {
    margin-left: 1vw;
    margin-right: 1vw;
  }  
}